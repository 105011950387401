import { FirebaseApp, initializeApp, getApps, getApp } from 'firebase/app';
import { Database, getDatabase } from "firebase/database";
import { Firestore, getFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyAyJjEmBjwNBj0Yiu0o27_ZyyFo8_-aGCo",
    authDomain: "dartgames-b6c6d.firebaseapp.com",
    databaseUrl:"https://dartgames-b6c6d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "dartgames-b6c6d",
    storageBucket: "dartgames-b6c6d.appspot.com",
    messagingSenderId: "731638482125",
    appId: "1:731638482125:web:54c23413b094269f975584"
}

class Firebase {
    private app: FirebaseApp;
    public db : Database;
    public store : Firestore

    constructor() {
        
        if (!getApps().length) 
            this.app = initializeApp(config);
        else 
            this.app = getApp();

        this.db = getDatabase(this.app,'https://dartgames-b6c6d-default-rtdb.europe-west1.firebasedatabase.app');
        this.store = getFirestore(this.app);
    }
    
    // five0onegames = () => ref(this.db,"/five0onegames").ref;
    // mickymousegames = () => ref(this.db,"/mickymouse").ref;
    // bermudagames = () => ref(this.db,"/bermuda").ref;

    
}

export default Firebase;
