import { Guid } from 'guid-typescript';
import { Status } from '../../../common/Status';
import { IPlayer } from '../model/Player';

export interface IFive0OneGameState {
    player1: IPlayer | null,
    player2: IPlayer | null,
    totalSets : number,
    activeSet : number,
    gameScore : 170|301|501|701|1001,
    activePlayerId: number,
    status : Status,
    tiebrakValue: number,
    tiebreakAllRounds : boolean,
    showSuggestions:boolean,
    roundsTypeBestOf : number, // 0 = BestOf, 1 = AllROunds
    roundOver:boolean,
    roundStartPlayerId:number,
    winner:number|null,
    sessionId:string|undefined,
}

export const initialFive0OneGameState: IFive0OneGameState = {
    player1:null,
    player2:null,
    totalSets:5,
    activeSet:1,
    gameScore:501,
    activePlayerId:1,
    status: Status.NotStarted,
    tiebrakValue:171,
    tiebreakAllRounds:false,
    showSuggestions:true,
    roundsTypeBestOf:0,
    roundOver:false,
    roundStartPlayerId:1,
    winner:null,
    sessionId:Guid.create().toString()
}