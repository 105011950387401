
export class ChekcoutScore  {
    value:number|"Bull" = 0;
    modifier : "T"|"D"|"" = "";

    constructor (value:number|"Bull", modifier:"T"|"D"|"") {
        this.value = value;
        this.modifier = modifier;

    }

    toString = () => {
        return `${this.modifier}${this.value}`;
    }
}

export class CheckoutValue  {
    value:number = 0;
    scores:ChekcoutScore[]|null = [];


    constructor (value:number, scores:ChekcoutScore[]|null) {
        this.value=value;
        this.scores = scores;
    }


    toString = () => {
        return this.scores?.map(item => {
            return `${item.toString()} `;
        }).reduce((total:string, current:string, index:number) => {
            return total + ' ' + current;
        },"");
    }
}

export class Checkout {
    checkouts:CheckoutValue[] = [];
    
    constructor() {
        this.initCheckouts();
    }

    tryCheckOut = (value:number) : boolean => {
        this.initCheckouts();

        const checkout = this.checkouts.filter(flt => {
            return flt.value === value;
        });
        return checkout.length > 0;
    }

    getCheckOut = (value:number) : string|null|undefined => {

        this.initCheckouts();

        const checkout = this.checkouts.filter(flt => {
            return flt.value === value;
        });

        try {
            if (checkout.length === 0) return null;
                return checkout[0].toString();
            } 
        catch (error) {
            return null;
        }

    }

    getCheckOutByNoOfDarts = (noOfDarts:number,value:number) : string|null|undefined => {

        this.initCheckouts();

        const checkout = this.checkouts.filter(flt => {
            return flt.value === value && flt.scores!.length===noOfDarts;
        });

        try {
            if (checkout.length === 0) return null;
                return checkout[0].toString();
            } 
        catch (error) {
            return null;
        }

    }

    private initCheckouts = () => {
        this.checkouts = [];

        this.checkouts.push(new CheckoutValue(170,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20, "T"), new ChekcoutScore("Bull", "D") ]));

        this.checkouts.push( new CheckoutValue(167,[ new ChekcoutScore(20,"T"),new ChekcoutScore(19,"T"), new ChekcoutScore("Bull","D") ]));
        this.checkouts.push( new CheckoutValue(164,[ new ChekcoutScore(20,"T"),new ChekcoutScore(18,"T"), new ChekcoutScore("Bull","D") ]));
        this.checkouts.push( new CheckoutValue(161,[ new ChekcoutScore(20,"T"),new ChekcoutScore(17,"T"), new ChekcoutScore("Bull","D") ]));
        this.checkouts.push( new CheckoutValue(160,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20,"T"), new ChekcoutScore(20,"D") ]));

        this.checkouts.push( new CheckoutValue(158,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20,"T"), new ChekcoutScore(19,"D") ]));
        this.checkouts.push( new CheckoutValue(157,[ new ChekcoutScore(20,"T"),new ChekcoutScore(19,"T"), new ChekcoutScore(20,"D") ]));
        this.checkouts.push( new CheckoutValue(156,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20,"T"), new ChekcoutScore(18,"D") ]));
        this.checkouts.push( new CheckoutValue(155,[ new ChekcoutScore(20,"T"),new ChekcoutScore(19,"T"), new ChekcoutScore(19,"D") ]));
        this.checkouts.push( new CheckoutValue(154,[ new ChekcoutScore(20,"T"),new ChekcoutScore(18,"T"), new ChekcoutScore(20,"D") ]));
        this.checkouts.push( new CheckoutValue(153,[ new ChekcoutScore(20,"T"),new ChekcoutScore(19,"T"), new ChekcoutScore(18,"D") ]));
        this.checkouts.push( new CheckoutValue(152,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20,"T"), new ChekcoutScore(16,"D") ]));
        this.checkouts.push( new CheckoutValue(151,[ new ChekcoutScore(20,"T"),new ChekcoutScore(17,"T"), new ChekcoutScore(20,"D") ]));
        this.checkouts.push( new CheckoutValue(150,[ new ChekcoutScore(20,"T"),new ChekcoutScore(18,"T"), new ChekcoutScore(18,"D") ]));

        this.checkouts.push(new CheckoutValue(149,[ new ChekcoutScore(20,"T"),new ChekcoutScore(19,"T"), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(148,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20,"T"), new ChekcoutScore(14,"D")]));
        this.checkouts.push(new CheckoutValue(147,[ new ChekcoutScore(20,"T"),new ChekcoutScore(17,"T"), new ChekcoutScore(18,"D")]));
        this.checkouts.push(new CheckoutValue(146,[ new ChekcoutScore(20,"T"),new ChekcoutScore(18,"T"), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(145,[ new ChekcoutScore(20,"T"),new ChekcoutScore(15,"T"), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(144,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20,"T"), new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(143,[ new ChekcoutScore(20,"T"),new ChekcoutScore(17,"T"), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(142,[ new ChekcoutScore(20,"T"),new ChekcoutScore(14,"T"), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(141,[ new ChekcoutScore(20,"T"),new ChekcoutScore(19,"T"), new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(140,[ new ChekcoutScore(20,"T"),new ChekcoutScore(16,"T"), new ChekcoutScore(16,"D")]));
        
        this.checkouts.push(new CheckoutValue(139,[ new ChekcoutScore(20,"T"),new ChekcoutScore(13,"T"), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(138,[ new ChekcoutScore(20,"T"),new ChekcoutScore(18,"T"), new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(137,[ new ChekcoutScore(20,"T"),new ChekcoutScore(19,"T"), new ChekcoutScore(10,"D")]));
        this.checkouts.push(new CheckoutValue(136,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20,"T"), new ChekcoutScore(8,"D")]));
        this.checkouts.push(new CheckoutValue(135,[ new ChekcoutScore(20,"T"),new ChekcoutScore(17,"T"), new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(134,[ new ChekcoutScore(20,"T"),new ChekcoutScore(14,"T"), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(133,[ new ChekcoutScore(20,"T"),new ChekcoutScore(19,"T"), new ChekcoutScore(8,"D")]));
        this.checkouts.push(new CheckoutValue(132,[ new ChekcoutScore(20,"T"),new ChekcoutScore(16,"T"), new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(131,[ new ChekcoutScore(20,"T"),new ChekcoutScore(13,"T"), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(130,[ new ChekcoutScore(20,"T"),new ChekcoutScore(18,"T"), new ChekcoutScore(8,"D")]));
        
        this.checkouts.push(new CheckoutValue(129,[ new ChekcoutScore(19,"T"),new ChekcoutScore(16,"T"), new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(128,[ new ChekcoutScore(18,"T"),new ChekcoutScore(14,"T"), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(127,[ new ChekcoutScore(20,"T"),new ChekcoutScore(17,"T"), new ChekcoutScore(8,"D")]));
        this.checkouts.push(new CheckoutValue(126,[ new ChekcoutScore(19,"T"),new ChekcoutScore(19,""), new ChekcoutScore("Bull","D")]));
        this.checkouts.push(new CheckoutValue(125,[ new ChekcoutScore("Bull",""),new ChekcoutScore(20,"T"), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(124,[ new ChekcoutScore(20,"T"),new ChekcoutScore(16,"T"), new ChekcoutScore(8,"D")]));
        this.checkouts.push(new CheckoutValue(123,[ new ChekcoutScore(19,"T"),new ChekcoutScore(14,"T"), new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(122,[ new ChekcoutScore(18,"T"),new ChekcoutScore(20,"T"), new ChekcoutScore(4,"D")]));
        this.checkouts.push(new CheckoutValue(121,[ new ChekcoutScore(17,"T"),new ChekcoutScore(18,"T"), new ChekcoutScore(8,"D")]));
        this.checkouts.push(new CheckoutValue(120,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20,""), new ChekcoutScore(20,"D")]));
        
        this.checkouts.push(new CheckoutValue(119,[ new ChekcoutScore(19,"T"),new ChekcoutScore(10,"T"), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(118,[ new ChekcoutScore(18,"T"),new ChekcoutScore(16,"T"), new ChekcoutScore(8,"D")]));
        this.checkouts.push(new CheckoutValue(117,[ new ChekcoutScore(20,"T"),new ChekcoutScore(17,""), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(116,[ new ChekcoutScore(20,"T"),new ChekcoutScore(16,""), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(115,[ new ChekcoutScore(19,"T"),new ChekcoutScore(18,""), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(114,[ new ChekcoutScore(20,"T"),new ChekcoutScore(14,""), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(113,[ new ChekcoutScore(19,"T"),new ChekcoutScore(16,""), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(112,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20,""), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(111,[ new ChekcoutScore(19,"T"),new ChekcoutScore(14,""), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(110,[ new ChekcoutScore(20,"T"),new ChekcoutScore(18,""), new ChekcoutScore(16,"D")]));

        this.checkouts.push(new CheckoutValue(109,[ new ChekcoutScore(19,"T"),new ChekcoutScore(12,""), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(108,[ new ChekcoutScore(17,"T"),new ChekcoutScore(17,""), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(107,[ new ChekcoutScore(19,"T"),new ChekcoutScore(18,""), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(106,[ new ChekcoutScore(20,"T"),new ChekcoutScore(10,""), new ChekcoutScore(18,"D")]));
        this.checkouts.push(new CheckoutValue(105,[ new ChekcoutScore(19,"T"),new ChekcoutScore(16,""), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(104,[ new ChekcoutScore(16,"T"),new ChekcoutScore(16,""), new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(103,[ new ChekcoutScore(19,"T"),new ChekcoutScore(10,""), new ChekcoutScore(18,"D")]));
        this.checkouts.push(new CheckoutValue(102,[ new ChekcoutScore(20,"T"),new ChekcoutScore(10,""), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(101,[ new ChekcoutScore(17,"T"),new ChekcoutScore(18,""), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(101,[ new ChekcoutScore(17,"T"),new ChekcoutScore(18,""), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(100,[ new ChekcoutScore(20,"T"),new ChekcoutScore(20,"D")]));
        
        this.checkouts.push(new CheckoutValue(99,[ new ChekcoutScore(19,"T"),new ChekcoutScore(10,""), new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(98,[ new ChekcoutScore(20,"T"),new ChekcoutScore(19,"D")]));
        this.checkouts.push(new CheckoutValue(97,[ new ChekcoutScore(19,"T"),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(96,[ new ChekcoutScore(20,"T"),new ChekcoutScore(18,"D")]));
        this.checkouts.push(new CheckoutValue(95,[ new ChekcoutScore(19,"T"),new ChekcoutScore(19,"D")]));
        this.checkouts.push(new CheckoutValue(94,[ new ChekcoutScore(18,"T"),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(93,[ new ChekcoutScore(19,"T"),new ChekcoutScore(18,"D")]));
        this.checkouts.push(new CheckoutValue(92,[ new ChekcoutScore(20,"T"),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(91,[ new ChekcoutScore(17,"T"),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(90,[ new ChekcoutScore(18,"T"),new ChekcoutScore(18,"D")]));

        this.checkouts.push(new CheckoutValue(89,[ new ChekcoutScore(19,"T"),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(88,[ new ChekcoutScore(20,"T"),new ChekcoutScore(14,"D")]));
        this.checkouts.push(new CheckoutValue(87,[ new ChekcoutScore(17,"T"),new ChekcoutScore(18,"D")]));
        this.checkouts.push(new CheckoutValue(86,[ new ChekcoutScore(18,"T"),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(85,[ new ChekcoutScore(19,"T"),new ChekcoutScore(14,"D")]));
        this.checkouts.push(new CheckoutValue(85,[ new ChekcoutScore(19,"T"),new ChekcoutScore(14,"D")]));
        this.checkouts.push(new CheckoutValue(84,[ new ChekcoutScore(20,"T"),new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(83,[ new ChekcoutScore(17,"T"),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(82,[ new ChekcoutScore("Bull","D"),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(81,[ new ChekcoutScore(19,"T"),new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(80,[ new ChekcoutScore(20,"T"),new ChekcoutScore(10,"D")]));

        this.checkouts.push(new CheckoutValue(79,[ new ChekcoutScore(19,"T"),new ChekcoutScore(11,"D")]));
        this.checkouts.push(new CheckoutValue(78,[ new ChekcoutScore(18,"T"),new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(77,[ new ChekcoutScore(19,"T"),new ChekcoutScore(10,"D")]));
        this.checkouts.push(new CheckoutValue(76,[ new ChekcoutScore(20,"T"),new ChekcoutScore(8,"D")]));
        this.checkouts.push(new CheckoutValue(75,[ new ChekcoutScore(17,"T"),new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(74,[ new ChekcoutScore(14,"T"),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(73,[ new ChekcoutScore(19,"T"),new ChekcoutScore(8,"D")]));
        this.checkouts.push(new CheckoutValue(72,[ new ChekcoutScore(16,"T"),new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(71,[ new ChekcoutScore(13,"T"),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(70,[ new ChekcoutScore(18,"T"),new ChekcoutScore(8,"D")]));

        this.checkouts.push(new CheckoutValue(69,[ new ChekcoutScore(19,"T"),new ChekcoutScore(6,"D")]));
        this.checkouts.push(new CheckoutValue(68,[ new ChekcoutScore(20,"T"),new ChekcoutScore(4,"D")]));
        this.checkouts.push(new CheckoutValue(67,[ new ChekcoutScore(17,"T"),new ChekcoutScore(8,"D")]));
        this.checkouts.push(new CheckoutValue(66,[ new ChekcoutScore(14,"T"),new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(65,[ new ChekcoutScore("Bull",""),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(64,[ new ChekcoutScore(16,"T"),new ChekcoutScore(8,"D")]));
        this.checkouts.push(new CheckoutValue(63,[ new ChekcoutScore(13,"T"),new ChekcoutScore(12,"D")]));
        this.checkouts.push(new CheckoutValue(62,[ new ChekcoutScore(10,"T"),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(61,[ new ChekcoutScore("Bull",""),new ChekcoutScore(18,"D")]));
        this.checkouts.push(new CheckoutValue(60,[ new ChekcoutScore(20,""),new ChekcoutScore(20,"D")]));

        this.checkouts.push(new CheckoutValue(59,[ new ChekcoutScore(19,""),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(58,[ new ChekcoutScore(18,""),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(57,[ new ChekcoutScore(17,""),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(56,[ new ChekcoutScore(16,""),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(55,[ new ChekcoutScore(15,""),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(54,[ new ChekcoutScore(14,""),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(53,[ new ChekcoutScore(13,""),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(52,[ new ChekcoutScore(12,""),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(51,[ new ChekcoutScore(19,""),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(50,[ new ChekcoutScore(18,""),new ChekcoutScore(16,"D")]));

        this.checkouts.push(new CheckoutValue(49,[ new ChekcoutScore(17,""),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(48,[ new ChekcoutScore(16,""),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(47,[ new ChekcoutScore(15,""),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(46,[ new ChekcoutScore(10,""),new ChekcoutScore(18,"D")]));
        this.checkouts.push(new CheckoutValue(45,[ new ChekcoutScore(13,""),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(44,[ new ChekcoutScore(12,""),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(43,[ new ChekcoutScore(3,""),new ChekcoutScore(20,"D")]));
        this.checkouts.push(new CheckoutValue(42,[ new ChekcoutScore(10,""),new ChekcoutScore(16,"D")]));
        this.checkouts.push(new CheckoutValue(41,[ new ChekcoutScore(9,""),new ChekcoutScore(16,"D")]));

        for (let index = 40; index > 1; index--) {
            if (index % 2 === 0) {
                this.checkouts.push(new CheckoutValue(index,[new ChekcoutScore(index/2,"D")]));
            }
            else
            {
                this.checkouts.push(new CheckoutValue(index,[new ChekcoutScore(1,""), new ChekcoutScore((index-1)/2,"D")]));
            }
        }
    }
    
}