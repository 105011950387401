export enum TargetTypeEnum {
  Single,
  Double,
  Triple,
  None
}

export const matrix = [
  { "x": 0, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 0, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 1, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 2, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 3, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 4, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 5, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 6, "value": "D5", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 6, "value": "D5", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 6, "value": "D20", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 6, "value": "D20", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 6, "value": "D20", "targetType": TargetTypeEnum.Double },
  { "x": 23, "y": 6, "value": "D20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 6, "value": "D20", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 6, "value": "D20|D1", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 6, "value": "D1|*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 6, "value": "D1|*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 6, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 7, "value": "D5|*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 7, "value": "D5|*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 7, "value": "D5", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 7, "value": "D5", "targetType": TargetTypeEnum.Double },
  { "x": 19, "y": 7, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 7, "value": "5|20", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 7, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 7, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 7, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 7, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 7, "value": "1|20", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 7, "value": "1|D1", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 7, "value": "D1", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 7, "value": "D1", "targetType": TargetTypeEnum.Double },
  { "x": 29, "y": 7, "value": "D1", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 7, "value": "d1|*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 7, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 8, "value": "D12", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 8, "value": "D5|D12", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 8, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 8, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 8, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 8, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 8, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 8, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 8, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 8, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 8, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 8, "value": "1|20", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 8, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 8, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 8, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 8, "value": "1|D1", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 8, "value": "D1|D18", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 8, "value": "", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 8, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 9, "value": "D12", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 9, "value": "D12", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 9, "value": "12|D12", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 9, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 9, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 9, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 9, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 9, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 9, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 9, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 9, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 9, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 9, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 9, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 9, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 9, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 9, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 9, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 9, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 9, "value": "18|D18", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 9, "value": "D18", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 9, "value": "D18|*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 9, "value": "", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 9, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 10, "value": "D12", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 10, "value": "D12", "targetType": TargetTypeEnum.Double },
  { "x": 13, "y": 10, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 10, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 10, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 10, "value": "5|12", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 10, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 10, "value": "5", "targetType": TargetTypeEnum.Single },
  { "x": 19, "y": 10, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 10, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 10, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 10, "value": "20", "targetType": TargetTypeEnum.Single },
  { "x": 23, "y": 10, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 10, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 10, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 10, "value": "1", "targetType": TargetTypeEnum.Single },
  { "x": 27, "y": 10, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 10, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 10, "value": "18|1", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 10, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 10, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 10, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 10, "value": "D18", "targetType": TargetTypeEnum.Double },
  { "x": 34, "y": 10, "value": "D18|*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 10, "value": "", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 10, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 11, "value": "D9|*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 11, "value": "D12", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 11, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 11, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 11, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 11, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 11, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 11, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 11, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 11, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 11, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 11, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 11, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 11, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 11, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 11, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 11, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 11, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 11, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 11, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 11, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 11, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 11, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 11, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 11, "value": "D18|D4", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 11, "value": "D4|*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 11, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 12, "value": "D9|*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 12, "value": "D9", "targetType": TargetTypeEnum.Double },
  { "x": 11, "y": 12, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 12, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 12, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 12, "value": "12", "targetType": TargetTypeEnum.Single },
  { "x": 15, "y": 12, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 12, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 12, "value": "5|12", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 12, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 12, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 12, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 12, "value": "20|T20", "targetType": TargetTypeEnum.None },
  {
    "x": 22,
    "y": 12,
    "value": "20|T20",
    "targetType": TargetTypeEnum.Triple
  },
  { "x": 23, "y": 12, "value": "20|T20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 12, "value": "20|T20", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 12, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 12, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 12, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 12, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 12, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 12, "value": "18", "targetType": TargetTypeEnum.Single },
  { "x": 31, "y": 12, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 12, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 12, "value": "18|4", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 12, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 12, "value": "D4", "targetType": TargetTypeEnum.Double },
  { "x": 36, "y": 12, "value": "D4|*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 12, "value": "", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 12, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 13, "value": "D9|*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 13, "value": "D9", "targetType": TargetTypeEnum.Single },
  { "x": 10, "y": 13, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 13, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 13, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 13, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 13, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 13, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 13, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 13, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 13, "value": "T5", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 13, "value": "T5", "targetType": TargetTypeEnum.Triple },
  { "x": 20, "y": 13, "value": "T5", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 13, "value": "T20|T5", "targetType": TargetTypeEnum.None },
  {
    "x": 22,
    "y": 13,
    "value": "20|T20",
    "targetType": TargetTypeEnum.Triple
  },
  { "x": 23, "y": 13, "value": "20|T20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 13, "value": "T1|T20", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 13, "value": "T1", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 13, "value": "T1", "targetType": TargetTypeEnum.Triple },
  { "x": 27, "y": 13, "value": "1|T1", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 13, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 13, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 13, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 13, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 13, "value": "18|4", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 13, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 13, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 13, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 13, "value": "D4", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 13, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 14, "value": "D9", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 14, "value": "9|D9", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 14, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 14, "value": "9", "targetType": TargetTypeEnum.Single },
  { "x": 12, "y": 14, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 14, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 14, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 14, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 14, "value": "12|T12", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 14, "value": "", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 14, "value": "T5|T12", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 14, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 14, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 14, "value": "5|20", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 14, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 14, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 14, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 14, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 14, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 14, "value": "T18", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 14, "value": "T18", "targetType": TargetTypeEnum.Triple },
  { "x": 29, "y": 14, "value": "T18|18", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 14, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 14, "value": "18|4", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 14, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 14, "value": "4", "targetType": TargetTypeEnum.Single },
  { "x": 34, "y": 14, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 14, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 14, "value": "4|D4", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 14, "value": "D4|*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 14, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 15, "value": "D9|D14", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 15, "value": "D9", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 15, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 15, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 15, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 15, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 15, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 15, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 15, "value": "T12", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 15, "value": "T12", "targetType": TargetTypeEnum.Triple },
  { "x": 17, "y": 15, "value": "12|T12", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 15, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 15, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 15, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 15, "value": "5|20", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 15, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 15, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 15, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 15, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 15, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 15, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 15, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 15, "value": "T18", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 15, "value": "18|T4", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 15, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 15, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 15, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 15, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 15, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 15, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 15, "value": "D4|D13", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 15, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 16, "value": "D14", "targetType": TargetTypeEnum.Double },
  { "x": 8, "y": 16, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 16, "value": "14|9", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 16, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 16, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 16, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 16, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 16, "value": "9|T9", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 16, "value": "T9", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 16, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 16, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 16, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 16, "value": "5|12", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 16, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 16, "value": "5|20", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 16, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 16, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 16, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 16, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 16, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 16, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 16, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 16, "value": "18|4", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 16, "value": "T4", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 16, "value": "4|T4", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 16, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 16, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 16, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 16, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 16, "value": "13|4", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 16, "value": "D13|13", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 16, "value": "D13|*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 16, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 17, "value": "D14|*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 17, "value": "D14", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 17, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 17, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 17, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 17, "value": "9|14", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 17, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 17, "value": "9|T9", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 17, "value": "T9", "targetType": TargetTypeEnum.Triple },
  { "x": 15, "y": 17, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 17, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 17, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 17, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 17, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 17, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 17, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 17, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 17, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 17, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 17, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 17, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 17, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 17, "value": "4|18", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 17, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 17, "value": "4|T4", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 17, "value": "T4", "targetType": TargetTypeEnum.Triple },
  { "x": 32, "y": 17, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 17, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 17, "value": "13|4", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 17, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 17, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 17, "value": "13|D13", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 17, "value": "D13", "targetType": TargetTypeEnum.Double },
  { "x": 39, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 17, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 18, "value": "D14", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 18, "value": "14|D14", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 18, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 18, "value": "14", "targetType": TargetTypeEnum.Single },
  { "x": 10, "y": 18, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 18, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 18, "value": "14|9", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 18, "value": "T9|T4", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 18, "value": "9|T9", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 18, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 18, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 18, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 18, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 18, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 18, "value": "5|12", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 18, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 18, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 18, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 18, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 18, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 18, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 18, "value": "4|18", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 18, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 18, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 18, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 18, "value": "T4", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 18, "value": "13|T13", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 18, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 18, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 18, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 18, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 18, "value": "13|D13", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 18, "value": "D13", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 18, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 19, "value": "D14", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 19, "value": "14|D14", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 19, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 19, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 19, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 19, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 19, "value": "14|T14", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 19, "value": "T14", "targetType": TargetTypeEnum.Triple },
  { "x": 14, "y": 19, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 19, "value": "9|14", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 19, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 19, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 19, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 19, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 19, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 19, "value": "5", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 19, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 19, "value": "20", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 19, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 19, "value": "18", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 19, "value": "4|18", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 19, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 19, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 19, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 19, "value": "13|4", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 19, "value": "13|T13", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 19, "value": "T13", "targetType": TargetTypeEnum.Triple },
  { "x": 33, "y": 19, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 19, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 19, "value": "13", "targetType": TargetTypeEnum.Single },
  { "x": 36, "y": 19, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 19, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 19, "value": "D13", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 19, "value": "D13|*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 19, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 20, "value": "D14", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 20, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 20, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 20, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 20, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 20, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 20, "value": "T14|14", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 20, "value": "T14|14", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 20, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 20, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 20, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 20, "value": "9|14", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 20, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 20, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 20, "value": "12", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 20, "value": "5|12", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 20, "value": "5|20", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 20, "value": "1", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 20, "value": "18|1", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 20, "value": "4|18", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 20, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 20, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 20, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 20, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 20, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 20, "value": "13|T13", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 20, "value": "T13", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 20, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 20, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 20, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 20, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 20, "value": "13|6", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 20, "value": "D13|D6", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 20, "value": "D6D13|*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 20, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 21, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 21, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 21, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 21, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 21, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 21, "value": "D11|*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 21, "value": "D11|11", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 21, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 21, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 21, "value": "11|14", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 21, "value": "11|14", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 21, "value": "11|14", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 21, "value": "T14|T11", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 21, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 21, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 21, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 21, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 21, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 21, "value": "14|9", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 21, "value": "9|14", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 21, "value": "9", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 21, "value": "9|12", "targetType": TargetTypeEnum.None },
  {
    "x": 22,
    "y": 21,
    "value": "D5|D20|BULL",
    "targetType": TargetTypeEnum.None
  },
  {
    "x": 23,
    "y": 21,
    "value": "1|18|BULL",
    "targetType": TargetTypeEnum.None
  },
  { "x": 24, "y": 21, "value": "18|4", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 21, "value": "4", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 21, "value": "13|4", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 21, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 21, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 21, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 21, "value": "13", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 21, "value": "13|6", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 21, "value": "T13|T6", "targetType": TargetTypeEnum.None },
  {
    "x": 33,
    "y": 21,
    "value": "6|13|T6|T13",
    "targetType": TargetTypeEnum.None
  },
  { "x": 34, "y": 21, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 21, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 21, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 21, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 21, "value": "6|D6", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 21, "value": "D6|*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 21, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 21, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 21, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 21, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 21, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 22, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 22, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 22, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 22, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 22, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 22, "value": "D11|*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 22, "value": "D11|11", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 22, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 22, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 22, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 22, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 22, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 22, "value": "T11", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 22, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 22, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 22, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 22, "value": "11|14", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 22, "value": "11|14", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 22, "value": "14|11", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 22, "value": "14|9", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 22, "value": "14", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 22, "value": "BULL", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 22, "value": "BULL", "targetType": TargetTypeEnum.Single },
  { "x": 23, "y": 22, "value": "BULL", "targetType": TargetTypeEnum.None },
  {
    "x": 24,
    "y": 22,
    "value": "13|4|BULL",
    "targetType": TargetTypeEnum.None
  },
  { "x": 25, "y": 22, "value": "13|6", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 22, "value": "6|13", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 22, "value": "13|6", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 22, "value": "6|13", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 22, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 22, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 22, "value": "|6", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 22, "value": "T6", "targetType": TargetTypeEnum.Triple },
  { "x": 33, "y": 22, "value": "6|T6", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 22, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 22, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 22, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 22, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 22, "value": "6|D6", "targetType": TargetTypeEnum.Double },
  { "x": 39, "y": 22, "value": "D6|*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 22, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 22, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 22, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 22, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 22, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 23, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 23, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 23, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 23, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 23, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 23, "value": "D11|*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 23, "value": "D11|11", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 23, "value": "11", "targetType": TargetTypeEnum.Single },
  { "x": 9, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 23, "value": "T11", "targetType": TargetTypeEnum.Triple },
  { "x": 13, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 23, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 23, "value": "BULL", "targetType": TargetTypeEnum.Single },
  { "x": 22, "y": 23, "value": "DBULL", "targetType": TargetTypeEnum.Double },
  { "x": 23, "y": 23, "value": "BULL", "targetType": TargetTypeEnum.Single },
  {
    "x": 24,
    "y": 23,
    "value": "6|10|BULL",
    "targetType": TargetTypeEnum.None
  },
  { "x": 25, "y": 23, "value": "6|10", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 23, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 23, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 23, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 23, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 23, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 23, "value": "|6", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 23, "value": "T6|6", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 23, "value": "6|T6", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 23, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 23, "value": "6", "targetType": TargetTypeEnum.Single },
  { "x": 36, "y": 23, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 23, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 23, "value": "6|D6", "targetType": TargetTypeEnum.Double },
  { "x": 39, "y": 23, "value": "D6|*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 23, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 23, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 23, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 23, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 23, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 24, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 24, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 24, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 24, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 24, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 24, "value": "D11|*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 24, "value": "D11", "targetType": TargetTypeEnum.Double },
  { "x": 7, "y": 24, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 24, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 24, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 24, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 24, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 24, "value": "T11", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 24, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 24, "value": "11|8", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 24, "value": "8|11", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 24, "value": "8|11", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 24, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 24, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 24, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 24, "value": "8|16", "targetType": TargetTypeEnum.None },
  {
    "x": 21,
    "y": 24,
    "value": "7|16|BULL",
    "targetType": TargetTypeEnum.None
  },
  { "x": 22, "y": 24, "value": "BULL", "targetType": TargetTypeEnum.Single },
  { "x": 23, "y": 24, "value": "BULL", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 24, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 24, "value": "10|15", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 24, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 24, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 24, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 24, "value": "10|6", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 24, "value": "10|6", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 24, "value": "6|10", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 24, "value": "T6", "targetType": TargetTypeEnum.Triple },
  { "x": 33, "y": 24, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 24, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 24, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 24, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 24, "value": "6", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 24, "value": "6|D6", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 24, "value": "D6|*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 24, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 24, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 24, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 24, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 24, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 25, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 25, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 25, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 25, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 25, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 25, "value": "D11|*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 25, "value": "D11", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 25, "value": "11", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 25, "value": "11|8", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 25, "value": "8|11", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 25, "value": "8|11", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 25, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 25, "value": "T8|8", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 25, "value": "8|T8", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 25, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 25, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 25, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 25, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 25, "value": "8|16", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 25, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 25, "value": "16|7", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 25, "value": "7|19", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 25, "value": "3|19", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 25, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 25, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 25, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 25, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 25, "value": "10|15", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 25, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 25, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 25, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 25, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 25, "value": "T10|10", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 25, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 25, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 25, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 25, "value": "10|6", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 25, "value": "10|6", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 25, "value": "D6|D10", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 25, "value": "D6|*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 25, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 25, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 25, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 25, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 25, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 26, "value": "D8", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 26, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 26, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 26, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 26, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 26, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 26, "value": "T8|8", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 26, "value": "8|T8", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 26, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 26, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 26, "value": "8|16", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 26, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 26, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 26, "value": "16|7", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 26, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 26, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 26, "value": "3|19", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 26, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 26, "value": "2|7", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 26, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 26, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 26, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 26, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 26, "value": "10|15", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 26, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 26, "value": "10|T10", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 26, "value": "T10", "targetType": TargetTypeEnum.Triple },
  { "x": 33, "y": 26, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 26, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 26, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 26, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 26, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 26, "value": "D10", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 26, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 27, "value": "D8", "targetType": TargetTypeEnum.Double },
  { "x": 7, "y": 27, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 27, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 27, "value": "8", "targetType": TargetTypeEnum.Single },
  { "x": 10, "y": 27, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 27, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 27, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 27, "value": "T8", "targetType": TargetTypeEnum.Triple },
  { "x": 14, "y": 27, "value": "16|8", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 27, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 27, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 27, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 27, "value": "7|16", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 27, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 27, "value": "7|19", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 27, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 27, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 27, "value": "7|3", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 27, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 27, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 27, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 27, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 27, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 27, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 27, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 27, "value": "T10|T15", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 27, "value": "10|T10", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 27, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 27, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 27, "value": "10", "targetType": TargetTypeEnum.Single },
  { "x": 36, "y": 27, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 27, "value": "10|D10", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 27, "value": "D10", "targetType": TargetTypeEnum.Double },
  { "x": 39, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 27, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 28, "value": "D8", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 28, "value": "8|D8", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 28, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 28, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 28, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 28, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 28, "value": "8|16", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 28, "value": "T16|16", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 28, "value": "T16|16", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 28, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 28, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 28, "value": "7|16", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 28, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 28, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 28, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 28, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 28, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 28, "value": "3|7", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 28, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 28, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 28, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 28, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 28, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 28, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 28, "value": "15|T15", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 28, "value": "T15|15", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 28, "value": "10|15", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 28, "value": "10|15", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 28, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 28, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 28, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 28, "value": "D10|10", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 28, "value": "D10|*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 28, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 29, "value": "D8|*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 29, "value": "D8|8", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 29, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 29, "value": "8", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 29, "value": "8|16", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 29, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 29, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 29, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 29, "value": "T16", "targetType": TargetTypeEnum.Triple },
  { "x": 15, "y": 29, "value": "16|T16", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 29, "value": "7|16", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 29, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 29, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 29, "value": "19|7", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 29, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 29, "value": "19|3", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 29, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 29, "value": "3|7", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 29, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 29, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 29, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 29, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 29, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 29, "value": "15|T15", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 29, "value": "T15", "targetType": TargetTypeEnum.Triple },
  { "x": 31, "y": 29, "value": "15|T15", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 29, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 29, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 29, "value": "15|10", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 29, "value": "10|15", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 29, "value": "10", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 29, "value": "D10|D15", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 29, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 30, "value": "D8|8", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 30, "value": "8|16", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 30, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 30, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 30, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 30, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 30, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 30, "value": "16|T16", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 30, "value": "T7|T16", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 30, "value": "7|T7", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 30, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 30, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 30, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 30, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 30, "value": "3|19", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 30, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 30, "value": "3|7", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 30, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 30, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 30, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 30, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 30, "value": "T2|2", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 30, "value": "T2", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 30, "value": "15|T15", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 30, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 30, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 30, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 30, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 30, "value": "15|10", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 30, "value": "D15|15", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 30, "value": "D10|D15", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 30, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 31, "value": "D16|*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 31, "value": "D16|16", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 31, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 31, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 31, "value": "16", "targetType": TargetTypeEnum.Single },
  { "x": 12, "y": 31, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 31, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 31, "value": "7|16", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 31, "value": "7|T7", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 31, "value": "T7", "targetType": TargetTypeEnum.Triple },
  { "x": 17, "y": 31, "value": "T7|7", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 31, "value": "19|7", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 31, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 31, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 31, "value": "3|19", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 31, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 31, "value": "3|7", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 31, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 31, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 31, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 31, "value": "T2|2", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 31, "value": "T2", "targetType": TargetTypeEnum.Triple },
  { "x": 29, "y": 31, "value": "2|T2", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 31, "value": "2|15", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 31, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 31, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 31, "value": "15", "targetType": TargetTypeEnum.Single },
  { "x": 34, "y": 31, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 31, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 31, "value": "D15|15", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 31, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 32, "value": "D16", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 32, "value": "16|D16", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 32, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 32, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 32, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 32, "value": "16|7", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 32, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 32, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 32, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 32, "value": "T7|7", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 32, "value": "T7|T19", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 32, "value": "T19|19", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 32, "value": "19|T19", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 32, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 32, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 32, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 32, "value": "17|T17", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 32, "value": "T17|17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 32, "value": "T17", "targetType": TargetTypeEnum.Triple },
  { "x": 27, "y": 32, "value": "2|T2|17", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 32, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 32, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 32, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 32, "value": "2|15", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 32, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 32, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 32, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 32, "value": "D15", "targetType": TargetTypeEnum.Double },
  { "x": 36, "y": 32, "value": "D15|15", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 32, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 33, "value": "D116|*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 33, "value": "D16", "targetType": TargetTypeEnum.Double },
  { "x": 10, "y": 33, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 33, "value": "16", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 33, "value": "7|16", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 33, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 33, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 33, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 33, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 33, "value": "19|7", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 33, "value": "T19|19", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 33, "value": "T19", "targetType": TargetTypeEnum.Triple },
  { "x": 20, "y": 33, "value": "T19|19", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 33, "value": "T3", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 33, "value": "T3", "targetType": TargetTypeEnum.Triple },
  { "x": 23, "y": 33, "value": "T3", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 33, "value": "T17|17", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 33, "value": "17|T17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 33, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 33, "value": "17|2", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 33, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 33, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 33, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 33, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 33, "value": "2|15", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 33, "value": "15", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 33, "value": "15|D15", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 33, "value": "D15", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 33, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 34, "value": "D15|*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 34, "value": "D16", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 34, "value": "7|16", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 34, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 34, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 34, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 34, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 34, "value": "7|19", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 34, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 34, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 34, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 34, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 34, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 34, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 34, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 34, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 34, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 34, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 34, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 34, "value": "2|17", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 34, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 34, "value": "2", "targetType": TargetTypeEnum.Single },
  { "x": 31, "y": 34, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 34, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 34, "value": "D2", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 34, "value": "D15", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 34, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 35, "value": "", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 35, "value": "D7|*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 35, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 35, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 35, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 35, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 35, "value": "19|7", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 35, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 35, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 35, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 35, "value": "19|3", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 35, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 35, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 35, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 35, "value": "17|3", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 35, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 35, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 35, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 35, "value": "17|2", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 35, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 35, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 35, "value": "2", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 35, "value": "D2", "targetType": TargetTypeEnum.Double },
  { "x": 33, "y": 35, "value": "D2", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 35, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 36, "value": "D7|*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 36, "value": "D7|*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 36, "value": "D7|7", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 36, "value": "7", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 36, "value": "7|19", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 36, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 36, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 36, "value": "19", "targetType": TargetTypeEnum.Single },
  { "x": 19, "y": 36, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 36, "value": "3|19", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 36, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 36, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 36, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 36, "value": "3|17", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 36, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 36, "value": "17", "targetType": TargetTypeEnum.Single },
  { "x": 27, "y": 36, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 36, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 36, "value": "2|17", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 36, "value": "2|D2", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 36, "value": "D2", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 36, "value": "D2", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 36, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 37, "value": "D7|*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 37, "value": "D7|*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 37, "value": "D7", "targetType": TargetTypeEnum.Double },
  { "x": 15, "y": 37, "value": "19|D19", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 37, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 37, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 37, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 37, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 37, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 37, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 37, "value": "3", "targetType": TargetTypeEnum.Single },
  { "x": 23, "y": 37, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 37, "value": "3|17", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 37, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 37, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 37, "value": "17", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 37, "value": "17|D17", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 37, "value": "D17|D2", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 37, "value": "D2", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 37, "value": "D2|*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 37, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  {
    "x": 14,
    "y": 38,
    "value": "D7|D19|*",
    "targetType": TargetTypeEnum.None
  },
  { "x": 15, "y": 38, "value": "D19", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 38, "value": "D19", "targetType": TargetTypeEnum.Double },
  { "x": 17, "y": 38, "value": "D19|19", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 38, "value": "19|D19", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 38, "value": "19", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 38, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 38, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 38, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 38, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 38, "value": "3", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 38, "value": "17|D17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 38, "value": "D17|17", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 38, "value": "D17", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 38, "value": "D17", "targetType": TargetTypeEnum.Double },
  { "x": 29, "y": 38, "value": "D17|*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 38, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 39, "value": "D19|*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 39, "value": "D19|*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 39, "value": "D19", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 39, "value": "D3", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 39, "value": "D3", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 39, "value": "D3", "targetType": TargetTypeEnum.Double },
  { "x": 23, "y": 39, "value": "D3", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 39, "value": "D3", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 39, "value": "D17", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 39, "value": "D17|*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 39, "value": "D17|*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 39, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 40, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 41, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 42, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 43, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 0, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 1, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 2, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 3, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 4, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 5, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 6, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 7, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 8, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 9, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 10, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 11, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 12, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 13, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 14, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 15, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 16, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 17, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 18, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 19, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 20, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 21, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 22, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 23, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 24, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 25, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 26, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 27, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 28, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 29, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 30, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 31, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 32, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 33, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 34, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 35, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 36, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 37, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 38, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 39, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 40, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 41, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 42, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 43, "y": 44, "value": "*", "targetType": TargetTypeEnum.None },
  { "x": 44, "y": 44, "value": "*", "targetType": TargetTypeEnum.None }
]
