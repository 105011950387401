import * as React from 'react';
import { Alert, Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { MickyMouseContext } from '../state/context';
import { startGame } from '../state/reducer';
import UserInput, { IUserInputProps } from './UserInput';
import t20 from '../../../images/AdobeStock_74500319.jpeg';
export interface IMickeyMouseGameSettingsProps {
    // onSetsChange: (value: string) => void,
    // onTargetsChange: (valule: string) => void
}

const MickeyMouseGameSettings: React.FunctionComponent<IMickeyMouseGameSettingsProps> = (props) => {


    const { dispatch, state } = React.useContext(MickyMouseContext);

    const setsInputRef = React.useRef<HTMLInputElement>(null);
    const hitsInputRef = React.useRef<HTMLInputElement>(null);

    // const handleSubmit = (event:React.FormEvent) => {
    //     event.preventDefault()
    //     if (setsInputRef.current)
    //         dispatch(setGameSettings(+setsInputRef.current.value))
    // }

    const handleStartGameClick = (event:React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        console.log(setsInputRef.current!.value);

        if (setsInputRef.current)
        {
            dispatch(startGame(+setsInputRef.current.value));
        } 
        else
        {
            dispatch(startGame(1));
        }
    }
    
    const p1Props: IUserInputProps = {
        color: "green",
        placeholdertext: "Angiv Grøn spiller/hold",
        iconSize: 24,
        playerId: 1,
    }

    const p2Props: IUserInputProps = {
        playerId: 2,
        color: "red",
        placeholdertext: "Angiv Rød spiller/hold",
        iconSize: 24,
    }

    

    return (
      <React.Fragment>
        <Row className="mb-2">
          <Col xs={6}>
            <InputGroup>
              <InputGroup.Text>Legs (1-11)?</InputGroup.Text>
              <FormControl
                min="1"
                max="11"
                type="number"
                ref={setsInputRef}
                id="gamesets"
                placeholder="1"
              />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup>
              <InputGroup.Text>Darts required (3-7):</InputGroup.Text>
              <FormControl
                ref={hitsInputRef}
                min="3"
                max="7"
                type="number"
                className="form-control"
                id="targethits"
                placeholder="3"
              />
            </InputGroup>
          </Col>
        </Row>

        <Row>

        </Row>
        <Row className="mb-2">
          <Col xs={6}>
            <UserInput {...p1Props} />
          </Col>
          <Col xs={6}>
            <UserInput {...p2Props} />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={6} className="px-4">
            <Alert
              variant="success"
              className={state.player1 ? "visible" : "invisible"}
            >
              <Alert.Heading>Player 1</Alert.Heading>
              <h1>{state.player1?.name}</h1>
              <hr />
              <p className="mb-0">
                Target hit requirement: {state.player1?.hitRequirement}
              </p>
            </Alert>
          </Col>

          <Col xs={6}>
            <Alert
              variant="danger"
              className={state.player2 ? "visible" : "invisible"}
            >
              <Alert.Heading>Player 2</Alert.Heading>
              <h1>{state.player2?.name}</h1>
              <hr />
              <p className="mb-0">
                Target hit requirement: {state.player2?.hitRequirement}
              </p>
            </Alert>
          </Col>
        </Row>

        {state.player1 && state.player2 ? (
          <Row className="mt-3 d-flex justify-content-between">
            <Col xs={12} className="text-center">
              <Button
                onClick={handleStartGameClick}
                className="px-5 py-4"
                variant="success"
              >
                <h1>Start Game</h1>
              </Button>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col className="text-center mt-5">
            <img
              src={t20}
              alt="Tripple 20"
              className="rounded rounded-circle"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
};

export default MickeyMouseGameSettings;
