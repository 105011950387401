import React from 'react';
import { useState, useEffect } from 'react';

export interface ICountdownTimerProps {
    expiresIn:number,
    OnComplete():void
}


const CountdownTimer:React.FC<ICountdownTimerProps> = (props) => {

    const { expiresIn, OnComplete } = props;
    const [timeLeft, setTimeLeft] = useState<number>(expiresIn);

    useEffect(() => {
        const interval = setInterval(() => {
            if (timeLeft<=0) 
            {
                OnComplete();
                return () => clearInterval(interval);
            }
            setTimeLeft(() => timeLeft - 1);
          }, 1000);

          return () => clearInterval(interval);

    }, [timeLeft, OnComplete]);


    const getMinutes = ():string => {
        const minutes = Math.floor(timeLeft / 60);
        return minutes.toString().padStart(2,"0");

    }

    const getSeconds = ():string => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft-minutes*60;

        return seconds.toString().padStart(2,"0");
    }

    return (
        <React.Fragment>
            <div className="d-flex flex-row justify-content-center border rounded border-info bg-info">
                <h1 className='text-light display-1'>{getMinutes()} : {getSeconds()}</h1>
            </div>
        </React.Fragment>
    );
}

export default CountdownTimer;