import React from 'react';
import { Col, Row } from 'react-bootstrap';

interface IscoreListItemProps {
    totalScore:number,
    currentScore:number
}

const ScoreListItem = (props:IscoreListItemProps) => {
    const { totalScore, currentScore} = props;
    return (
        <Row className="pt-2">
            <Col className="h3 text-warning">{currentScore}</Col>
            <Col className="h3 text-warning">{totalScore}</Col>
        </Row>
    );
}

export default ScoreListItem;