import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { IOneThreeOneRoundScore } from "./model";
import { Row } from "react-bootstrap";

interface IActiveRoundInputProps {
    InputScoreId:number;
    IsDisabled:boolean;
    InputRoundScore:IOneThreeOneRoundScore;
    Target:number|null;
    SubmitScore(ScoreId:number, Score:number, darts:number|null):void;
    RollbackScore(ScoreId:number):void;
}



const ActiveRoundInput:React.FC<IActiveRoundInputProps> = props => {

    const {InputScoreId, SubmitScore, IsDisabled, InputRoundScore, RollbackScore, Target } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const [currentValue, setCurrentValue] = useState<string>('');
    // const [currentScore, setCurrentScore] = useState<number|null>(InputRoundScore.score);

    const handleInputChange =(event:ChangeEvent<HTMLInputElement>) => {
        
      event.preventDefault();
      if (event.currentTarget.value === "/") {
        RollbackScore(InputScoreId);
        return;
      }
      setCurrentValue(event.currentTarget.value);
      // if (event.currentTarget.value !== '*' && event.currentTarget.value !== '*1' && event.currentTarget.value !== '*2' && event.currentTarget.value !== '*3')
      // {
      //     setCurrentScore(Number(event.currentTarget.value));
      // } 
    }

    const onKeyboardUp:React.KeyboardEventHandler = (event:React.KeyboardEvent) => {
        event.preventDefault();
        

        if (event.key === "Enter") {

            if (currentValue === '*1') {
                // setCurrentScore(Target)
                SubmitScore(InputScoreId,0, 1);
                return;
            } 
            if (currentValue === '*2') {
                // setCurrentScore(Target)
                SubmitScore(InputScoreId,0, 2);
                return;
            }
            if (currentValue === '*3') {
                // setCurrentScore(Target)
                SubmitScore(InputScoreId,0, 3);
                return;
            }
            if (Number(currentValue) < 0) {
              var newScore = Target! + Number(currentValue)
              setCurrentValue(newScore.toString());
              SubmitScore(InputScoreId,newScore,newScore===0 ? 3 : null);
              return;
            }

            // setCurrentScore(Number(currentValue));
            SubmitScore(InputScoreId,Number(currentValue),Number(currentValue) === 0 ? 3 : null);
        }
    }

    useEffect(()=> {
        if (!IsDisabled) {
            inputRef.current?.focus();
        } 
    },[IsDisabled]);

    useEffect(()=> {
        // setCurrentScore(0);
        setCurrentValue('');
    },[InputRoundScore]);

    return (
      <React.Fragment>
        <Row>
          <input
            ref={inputRef}
            className="rounded text-center h1 p-3 text-warning"
            type="text"
            onChange={handleInputChange}
            onKeyUp={onKeyboardUp}
            disabled={IsDisabled}
            value={currentValue}
          />
        </Row>
      </React.Fragment>
    );

}

export default ActiveRoundInput;