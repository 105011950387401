import { Database } from "firebase/database";
import Firebase from "./Firebase";
import { Firestore } from "firebase/firestore";

interface IDataservice<T> {
    getAll(): Promise<T[]>;
    create(item:T): Promise<T>;
    update(item:T): Promise<T>,
    delete(key:string):void,
    deleteAll():void

}

abstract class Dataservice<T>  implements IDataservice<T> {
    private firebase:Firebase;
    protected mainCollection: string;

    protected DB:Database;
    protected STORE:Firestore;

    constructor(mainCollection:string) {
        
        this.firebase = new Firebase();
        this.mainCollection = mainCollection;
        this.DB = this.firebase.db;
        this.STORE = this.firebase.store;
    }

    abstract getAll(): Promise<T[]> ;
    abstract create(item: T): Promise<T> ;
    abstract update(item: T): Promise<T> ;
    abstract delete(key: string): void ;
    abstract deleteAll(): void ;
}

export default Dataservice;
