import React, { useState } from "react";
import * as Icons from 'react-bootstrap-icons';
import { Button, Col, Container, Row } from "react-bootstrap";
import Timer from "../Timer";


type FieldType = 180 | 160 | 140 | 120 | 100 | 80 | 60 | 25;

interface IScoreStat {
    Field: FieldType,
    Hits: number
}


const TwentiesTraining: React.FC = () => {

    const [score, setScore] = useState<number>(0);
    const [timer, startTimer] = useState<boolean>(false);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [minutes, setMinutes] = useState<number>(1800);
    const [scoreStats, setScoreStats] = useState<IScoreStat[]>([]);

    const addScore = (value: number, field: FieldType) => {

        const existingScores = scoreStats;
        const existingItem = existingScores.find(x => x.Field === field);

        if (existingItem)
            existingItem.Hits++
        else
            existingScores.push({ Field: field, Hits: 1 });

        setScoreStats(existingScores);
        setScore(score + value);
    }


    const getScoreStats = () => {

        return (
            scoreStats.map((item, index) => {
                return (<Row key={index}>
                    <Col>
                        <h2 className="display-2 text-light">
                            {item.Field}
                        </h2>
                    </Col>
                    <Col>
                        <h2 className="display-2 text-light">
                            {item.Hits}
                        </h2>
                    </Col>
                </Row>
                )
            }
            ));

    }

    const ScoreBoard: React.FC<{ enabled: boolean }> = (props) => {

        const { enabled } = props;

        return (
            <React.Fragment>
                <Container>
                    <Row className="d-flex flex-column justify-content-between gap-1">
                        <Col className="d-flex flex-row justify-content-between gap-2">
                            <Button className="p-5" style={{ flexGrow: 1 }} onClick={() => addScore(7, 180)} variant="success" size={"lg"} disabled={!enabled}><span className="display-4">180</span></Button>
                            <Button className="p-5" style={{ flexGrow: 1 }} onClick={() => addScore(6, 160)} variant="success" size={"lg"} disabled={!enabled}><span className="display-4">160</span></Button>
                        </Col>
                        <Col className="d-flex flex-row justify-content-between gap-2">
                            <Button className="p-5" style={{ flexGrow: 1 }} onClick={() => addScore(5, 140)} variant="warning" size={"lg"} disabled={!enabled}><span className="display-4">140</span></Button>
                            <Button className="p-5" style={{ flexGrow: 1 }} onClick={() => addScore(4, 120)} variant="warning" size={"lg"} disabled={!enabled}><span className="display-4">120</span></Button>
                            <Button className="p-5" style={{ flexGrow: 1 }} onClick={() => addScore(3, 100)} variant="warning" size={"lg"} disabled={!enabled}><span className="display-4">100</span></Button>
                        </Col>
                        <Col className="d-flex flex-row justify-content-between gap-2">
                            <Button className="p-5" style={{ flexGrow: 1 }} onClick={() => addScore(2, 80)} variant="danger" size={"lg"} disabled={!enabled}><span className="display-4">80</span></Button>
                            <Button className="p-5" style={{ flexGrow: 1 }} onClick={() => addScore(1, 60)} variant="danger" size={"lg"} disabled={!enabled}><span className="display-4">60</span></Button>
                            <Button className="p-5" style={{ flexGrow: 1 }} onClick={() => addScore(1, 25)} variant="danger" size={"lg"} disabled={!enabled}><Icons.Bullseye size={40} className="text-light" /></Button>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }

    return (
        <Container>
            <h1 className="text-light display-1 mb-5">{minutes / 60} minutes of Twenties</h1>

            <Row className="d-flex justify-content-between">
                {timer ?
                    <Timer expiresIn={minutes} OnComplete={() => {
                        setIsComplete(true);
                        startTimer(false)
                    }
                    } /> :
                    isComplete ?
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Row className="rounded text-center bg-success">
                                        <h1 className="text-light display-1">Total score: {score}</h1>
                                    </Row>
                                    <Row className="rounded text-center bg-success mt-3">
                                        <Button className="text-light display-1 p-4" variant="info" onClick={() => {
                                            setMinutes(1800);
                                            setIsComplete(false);
                                            startTimer(false);
                                        }} value={"New Session"} title="New Session"><h1> New Session</h1></Button>
                                    </Row>
                                </Col>
                                <Col>
                                    {getScoreStats()}
                                </Col>
                            </Row>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="d-flex flex-row justify-content-between gap-3">
                                <Button variant="success" style={{ width: "100%" }} onClick={() => { setMinutes(60); startTimer(true) }}><h2>1 mins</h2></Button>
                                <Button variant="warning" style={{ width: "100%" }} onClick={() => { setMinutes(5 * 60); startTimer(true) }}><h2>5 mins</h2></Button>
                                <Button variant="danger" style={{ width: "100%" }} onClick={() => { setMinutes(10 * 60); startTimer(true) }}><h2>10 mins</h2></Button>
                            </div>
                            <div className="d-flex flex-row justify-content-between gap-3 mt-1">
                                <Button variant="success" style={{ width: "100%" }} onClick={() => { setMinutes(15 * 60); startTimer(true) }}><h2>15 mins</h2></Button>
                                <Button variant="warning" style={{ width: "100%" }} onClick={() => { setMinutes(30 * 60); startTimer(true) }}><h2>30 mins</h2></Button>
                                <Button variant="danger" style={{ width: "100%" }} onClick={() => { setMinutes(45 * 60); startTimer(true) }}><h2>45 mins</h2></Button>
                            </div>
                        </React.Fragment>
                }
            </Row>
            {timer ?

                <Row className="rounded text-center my-1 p-2">
                    <ScoreBoard enabled={timer} />
                </Row>

                : null}


        </Container>

    );
}

export default TwentiesTraining;

