import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
// import { MsalProvider } from '@azure/msal-react';
// import { PublicClientApplication } from '@azure/msal-browser';
// import { msalConfig } from './auth/config';

// const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  // <MsalProvider instance={msalInstance}>
  // </MsalProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ,document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

