import * as React from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,

} from "react-bootstrap";
import * as Icons from "react-bootstrap-icons";
import { Five0OneContext } from "../state/context";
import { setGameSettings, switchPlayers } from "../state/reducer";
import Player, { IPlayerProps } from "./Player";
// import { Five0OneContext } from '../state/context';

interface IFive0OneGameSettingsProps {}

const Five0OneGameSettings: React.FunctionComponent<IFive0OneGameSettingsProps> =
  (props) => {
    const { state, dispatch } = React.useContext(Five0OneContext);
    const [score, setScore] = React.useState<170 | 301 | 501 | 701 | 1001>(501);
    const [roundType, setRoundType] = React.useState<number>(0);
    const [showSuggestions, setShowSuggestions] = React.useState<boolean>(true);
    const [randomPlayerId, setRandOmplayerId] = React.useState<number | null>(
      null
    );
    // const [tbAllRounds, setTBAllRounds] = React.useState<boolean>(false);
    const p1Props: IPlayerProps = {
      iconColor: "red",
      placeHolder: "Navn",
      playerId: 1,
      disabled: false,
    };

    const p2Props: IPlayerProps = {
      ...p1Props,
      iconColor: "green",
      playerId: 2,
    };

    const refRounds = React.useRef<HTMLInputElement>(null);
    const refTiebreak = React.useRef<HTMLInputElement>(null);

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      const rounds = refRounds.current
        ? +refRounds.current.value
        : state.totalSets;
      const tbValue = refTiebreak.current
        ? +refTiebreak.current.value
        : state.tiebrakValue;
      dispatch(
        setGameSettings(
          score,
          rounds,
          tbValue,
          false,
          roundType,
          randomPlayerId ? randomPlayerId : 1,
          showSuggestions
        )
      );
    };

    const handleRoundType = (event: React.ChangeEvent<HTMLSelectElement>) => {
      setRoundType(+event.currentTarget.value);
    };

    const handleScoreOnchange = (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      event.preventDefault();
      const value = +event.target.value as 170 | 301 | 501 | 701 | 1001;
      setScore(value);
    };

    const handleRandomStarterClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault();

      for (let index = 0; index < 1000; index++) {
        let playerId: number = 1;
        setTimeout(() => {
          const rd = Math.floor(Math.random() * 100);
          playerId = rd % 2 === 0 ? 1 : 2;
          setRandOmplayerId(playerId);
        }, 500);
      }
    };

    return (
      <React.Fragment>
        <Row><h1 style={{color:"#fff", textAlign:"center", marginBottom:20}}>501</h1></Row>
        <Row className="mb-2 d-flex justify-content-center">
          <Col xs={4}>
            <Player {...p1Props} />
          </Col>
          <Col xs={1}></Col>
          <Col xs={4}>
            <Player {...p2Props} />
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-2 d-flex justify-content-center">
            <Col xs={4}>
              <InputGroup>
                <InputGroup.Text>Legs&nbsp;</InputGroup.Text>
                <Form.Select
                  defaultValue={state.roundsTypeBestOf}
                  onChange={handleRoundType}
                >
                  <option value={0}>Best of</option>
                  <option value={1}>All </option>
                </Form.Select>
                <FormControl
                  type="number"
                  ref={refRounds}
                  defaultValue={state.totalSets}
                  placeholder="5-17"
                ></FormControl>
              </InputGroup>
            </Col>

            <Col xs={1}></Col>

            <Col xs={4}>
              <InputGroup>
                <InputGroup.Text>Game</InputGroup.Text>
                <Form.Select
                  defaultValue={state.gameScore}
                  onChange={handleScoreOnchange}
                >
                  <option value={170}>170</option>
                  <option value={301}>301</option>
                  <option value={501}>501</option>
                  <option value={701}>701</option>
                  <option value={1001}>1001</option>
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2 d-flex justify-content-center">
              <Col xs={4}>
                <InputGroup>
                  <InputGroup.Checkbox onChange={() => setShowSuggestions(!showSuggestions)} checked={showSuggestions}/>
                  <InputGroup.Text >Show check out suggestions</InputGroup.Text> 
                </InputGroup>
              </Col>
              <Col xs={5}>&nbsp;</Col>
          </Row>

          {/* <Row>
                    <Col xs={6}>
                        <InputGroup>
                            <InputGroup.Text>Tiebreak</InputGroup.Text>
                            <FormControl type="number" ref={refTiebreak} defaultValue={state.tiebrakValue} ></FormControl>
                            <InputGroup.Checkbox onChange={handleTiebreakAllRoundsChange} />
                            <InputGroup.Text>I alle runder</InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row> */}

          <Row className="my-3 pt-3 pd-flex justify-content-center border-0 border-top border-success">
            <Col xs={4}>
              <Alert
                variant="success"
                className={state.player1 ? "visible" : "invisible"}
              >
                <Alert.Heading>Spiller 1</Alert.Heading>
                <h1>{state.player1?.name}</h1>
                <hr />
                <p className="mb-0">handicap: {state.player1?.handicap}</p>
              </Alert>
            </Col>
            <Col xs={1} className="text-center pt-5">
              {state.player1 && state.player2 ? (
                <Icons.ArrowLeftRight
                  color="white"
                  size={56}
                  className="btn btn-danger rounded rounded-circle"
                  onClick={() => dispatch(switchPlayers())}
                />
              ) : null}
            </Col>
            <Col xs={4}>
              <Alert
                variant="danger"
                className={state.player2 ? "visible" : "invisible"}
              >
                <Alert.Heading>Spiller 2</Alert.Heading>
                <h1>{state.player2?.name}</h1>
                <hr />
                <p className="mb-0">handicap: {state.player2?.handicap}</p>
              </Alert>
            </Col>
          </Row>

          {state.player1 && state.player2 ? (
            <Row className="text-center mt-1 d-flex justify-content-center">
              <Col xs={4} className="text-end">
                <Button
                  onClick={handleRandomStarterClick}
                  variant="success"
                  className="px-5 py-4"
                  style={{ width: 300 }}
                >
                  <h1>Random starter</h1>
                </Button>
              </Col>
              <Col
                xs={1}
                className={randomPlayerId ? "visible text-center" : "invisible"}
              >
                <Alert variant="info" className="py-5 mt-1">
                  <h4>
                    {randomPlayerId === 2
                      ? state.player2?.name
                      : state.player1?.name}
                  </h4>
                </Alert>
              </Col>
              <Col xs={4} className="text-start">
                <Button
                  onClick={handleSubmit}
                  variant="success"
                  className="px-5 py-4"
                  style={{ width: 300 }}
                >
                  <h1>Start Game</h1>
                </Button>
              </Col>
            </Row>
          ) : null}
        </Form>
        {/* <Row>
          <Col className="text-center mt-5">
            <img
              src={t20}
              alt="Tripple 20"
              className="rounded rounded-circle"
            />
          </Col>
        </Row> */}
      </React.Fragment>
    );
  };

export default Five0OneGameSettings;
