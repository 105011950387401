import React, { useContext } from "react";
import { BermudaCOntext } from "../state/context";
import { Col, Container, Row } from "react-bootstrap";
import * as Icons from "react-bootstrap-icons";
import { IPlayer } from "../model/Player";
import { PlayerService } from "./PlayerService";


interface IPlayerStatusProps {
    player: IPlayer, 
    isWinner:boolean
}

const PlayerRoundStats:React.FC<IPlayerStatusProps> = (props) => {
    
    const  {player, isWinner} = props;
    
    const playerService = new PlayerService(player);
    
    return (
        <React.Fragment>
        <Row>
            <Col xs={6}>            
            {isWinner ? (
                <Icons.TrophyFill size={142} color="gold" />
                ) : (
                    <Icons.TrophyFill size={142} color="silver" />
                    )}
            </Col>
            <Col>
            <h1
          style={{
              color: "white",
            verticalAlign: "center",
            textAlign: "center",
          }}
        >
          {player.name} : {player.wonSets}
        </h1>
        <h3
          style={{
              color: "Yellow",
              verticalAlign: "center",
              textAlign: "center",
            }}
            >
          Total score: {playerService.getTotalScore(10)}
        </h3>

            </Col>
        </Row>

      </React.Fragment>
    );
}

export interface IRoundStatusProps {
    currentRound:number
}

const RoundStatus:React.FC<IRoundStatusProps> = (props)  => {
    
    const {
        state: { player1 ,player2, activeRound, rounds }
    } = useContext(BermudaCOntext);
    
    const { currentRound } = props;
    
    const player1Service = new PlayerService(player1!);
    const player2Service = new PlayerService(player2!);

    const getWinner = ():IPlayer => {

      if (activeRound === rounds) {
        if (player1!.wonSets > player2!.wonSets)
          return player1!
        else 
          return player2!;
      } else {
        if (player1Service.getTotalScore(10) > player2Service.getTotalScore(10))
          return player1!
        else 
          return player2!
      }

    }
    
    return (
      <React.Fragment>
        <Container>
          <Row style={{ marginBottom: 50 }}>
            <Col>
              <h1
                style={{
                  color: "white",
                  verticalAlign: "center",
                  textAlign: "center",
                }}
              >
                Result leg {currentRound}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <PlayerRoundStats
                player={player1!}
                isWinner={getWinner().id === 1}
              />
            </Col>
            <Col>
              <PlayerRoundStats
                player={player2!}
                isWinner={getWinner().id === 2}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
}

export default RoundStatus;