import React from 'react';

interface IUserTargetProps {
    // targetid: number,
    // playerId:number,
    active:boolean,
    text:string
}

const UserTarget: React.FunctionComponent<IUserTargetProps> = (props) => {

    // const { state } = useContext(MickyMouseContext);
    // const { targetid, playerId } = props;
    // const player = playerId === 1 ? state.player1 : state.player2;
    // const target = player!.targets.find(tg=> {
    //     return tg.id === targetid
    // });

    const icon = props.active ?
        <span className="badge bg-black border border-white text-white" style={{padding:0,paddingTop:10, paddingBottom:10, width:50, fontSize:"16px"}}>{props.text}</span>:
        <span className="badge bg-black border border-black" style={{padding:0,paddingTop:10, paddingBottom:10, width:50, fontSize:"16px"}}>&nbsp;</span>
        // <Icons.Circle color="#060" size="36" className="pb-2" /> :
        // <Icons.CircleFill color="#060" size="36" />

    return (<React.Fragment>
            {icon}

    </React.Fragment>)
};

export default UserTarget;