import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import * as Icons from 'react-bootstrap-icons';

export interface IOnScreenKeypadProps {
    OnEnter(value:number|string):void,
    ShowClosingButtons:boolean;
    ShowFixedScores:boolean;
}

const OnScreenKeypad = (props: IOnScreenKeypadProps) => {

    const [numericPadString, enterNumericPadString] = useState<string>(' ');

    const { OnEnter, ShowClosingButtons, ShowFixedScores } = props;

    const handleOnEnter = (value: number) => {
        OnEnter(value);
    }

    const enterNumericPadValue = (value: string) => {
        enterNumericPadString(numericPadString + value);
    }

    const passNumericPadValue = () => {
        OnEnter(numericPadString);
        enterNumericPadString(' ');
    }

    const passNumericPadValueAsRemaining = () => {
        OnEnter('/' + numericPadString);
        enterNumericPadString(' ');
    }

    const enterCloseWithDarts = (value: number) => {
        OnEnter("*" + value);
    }

    const clearEnteredValue = () => {
        enterNumericPadString(' ');
    }

    const backstepScore = () => {
        OnEnter("-");
    }

    const Keypad = () => {
        return (
            <Col xs={6}>
            <Row className="d-flex flex-row">
              <Col className="d-flex flex-column gap-1">
                <Button
                  variant="secondary"
                  onClick={() => enterNumericPadValue("1")}
                  
                >
                  1
                </Button>
              </Col>
              <Col className="d-flex flex-column gap-1">
                <Button
                  variant="secondary"
                  onClick={() => enterNumericPadValue("2")}
                  
                >
                  2
                </Button>
              </Col>
              <Col className="d-flex flex-column gap-1">
                <Button
                  variant="secondary"
                  onClick={() => enterNumericPadValue("3")}
                  
                >
                  3
                </Button>
              </Col>
            </Row>
            <Row className="d-flex flex-row mt-1">
              <Col className="d-flex flex-column gap-1">
                <Button
                  variant="secondary"
                  onClick={() => enterNumericPadValue("4")}
                >
                  4
                </Button>
              </Col>
              <Col className="d-flex flex-column gap-1">
                <Button
                  variant="secondary"
                  onClick={() => enterNumericPadValue("5")}
                >
                  5
                </Button>
              </Col>
              <Col className="d-flex flex-column gap-1">
                <Button
                  variant="secondary"
                  onClick={() => enterNumericPadValue("6")}
                >
                  6
                </Button>
              </Col>
            </Row>
            <Row className="d-flex flex-row mt-1">
              <Col className="d-flex flex-column gap-1">
                <Button
                  variant="secondary"
                  onClick={() => enterNumericPadValue("7")}
                >
                  7
                </Button>
              </Col>
              <Col className="d-flex flex-column gap-1">
                <Button
                  variant="secondary"
                  onClick={() => enterNumericPadValue("8")}
                >
                  8
                </Button>
              </Col>
              <Col className="d-flex flex-column gap-1">
                <Button
                  variant="secondary"
                  onClick={() => enterNumericPadValue("9")}
                >
                  9
                </Button>
              </Col>
            </Row>
            <Row className="d-flex flex-row mt-1">
              <Col className="d-flex flex-column gap-1">
                <Button
                  variant="secondary"
                  onClick={() => enterNumericPadValue("0")} 
                >
                  0
                </Button>
              </Col>
            </Row>
          </Col>
        )
    }

    const FixedScores = () => {
        return (
            <Col xs={2} className="d-flex flex-column gap-1" >
            <Button
              variant="success"
              className=""
              onClick={() => handleOnEnter(180)} style={{flexGrow:1}}
            >
              180
            </Button>
            <Button
              variant="success"
              className=""
              onClick={() => handleOnEnter(140)}style={{flexGrow:1}}
            >
              140
            </Button>
            <Button
              variant="success"
              className=""
              onClick={() => handleOnEnter(100)}style={{flexGrow:1}}
            >
              100
            </Button>
            <Button
              variant="success"
              className=""
              onClick={() => handleOnEnter(60)}style={{flexGrow:1}}
            >
              60
            </Button>
            {/* <Button variant='success' className='' onClick={() => handleOnEnter(26)}>26</Button> */}
          </Col>
        )
    }

    const UsedDartsButtons = () => {
        return (
            <React.Fragment>
                <Col xs={2} className="d-flex justify-content-start flex-column gap-1">
                    <Button
                        variant="info"
                        style={{ flexGrow: 2 }}
                        className=""
                        onClick={() => enterCloseWithDarts(1)}
                    >
                        *1
                    </Button>
                    <Button
                        variant="info"
                        style={{ flexGrow: 2 }}
                        className=""
                        onClick={() => enterCloseWithDarts(3)}
                    >
                        *2
                    </Button>
                    <Button
                        variant="info"
                        style={{ flexGrow: 2 }}
                        className=""
                        onClick={() => enterCloseWithDarts(2)}
                    >
                        *3
                    </Button>
                    <Button
                        variant="danger"
                        style={{ flexGrow: 1 }}
                        className=""
                        onClick={backstepScore}
                    >
                        <Icons.ArrowBarLeft size={24} />
                    </Button>
                </Col>
            </React.Fragment>
        )
    }

    const ActionButtons = () => {
        return (
            <React.Fragment>
                <Col xs={2} className="d-flex flex-column gap-1">
                    <Button
                        variant="success"
                        onClick={passNumericPadValue}
                        style={{ flexGrow: 1 }}
                    >
                        Score
                    </Button>
                    <Button
                        variant="primary"
                        onClick={passNumericPadValueAsRemaining}
                        style={{ flexGrow: 1 }}
                    >
                        Left
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => handleOnEnter(0)}
                        style={{ flexGrow: 1 }}
                    >
                        Miss
                    </Button>
                </Col>
            </React.Fragment>
        )
    }

    const EnteredValue = () => {
        return (
        <React.Fragment>
            <Col xs={6} className="text-warning border rounded bg-dark text-center" >
                <h2>{numericPadString}</h2>
            </Col>
            <Col xs={4}>
                <Button variant="dark" onClick={clearEnteredValue}>
                    <Icons.Backspace size={36} />
                </Button>
            </Col>
        </React.Fragment>
        )
    }

    return (
      <React.Fragment>
        <Container>
            <Row>
                {ShowFixedScores ? <FixedScores /> : null}
                <Keypad />
                <ActionButtons />
                {ShowClosingButtons ? <UsedDartsButtons /> : null}
            </Row>
            <Row className="mt-1 d-flex justify-content-between">
                <EnteredValue />
            </Row>
        </Container>
      </React.Fragment>
    );
}

export default OnScreenKeypad;