import * as React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import *  as Icon from 'react-bootstrap-icons';
import { IPlayer } from '../model/Player';
import { MickyMouseContext } from '../state/context';
import { setPlayer1, setPlayer2 } from '../state/reducer';

export interface IUserInputProps {
    playerId: number,
    color: string,
    placeholdertext:string,
    iconSize:number,
}

const UserInput: React.FunctionComponent<IUserInputProps> = (props) => {

    const { dispatch } = React.useContext(MickyMouseContext);
    const pName = React.useRef<HTMLInputElement>(null);

    const onKeyPressHandler = (event:React.KeyboardEvent) => {
        if (event.key==="Enter") {
            if (!pName.current?.value)
            return;
   
           const hitsRequiredEl = document.getElementById("targethits")! as HTMLInputElement;
   
           const player : IPlayer = {
               id : props.playerId,
               name : pName.current!.value,
               wonSets : 0,
               targets:[],
               hitRequirement:+(hitsRequiredEl.value ? hitsRequiredEl.value : 3)
           }
           
           // Add dispatcher -dispather should also create the targets for this player
           if (props.playerId === 1)
               dispatch(setPlayer1(player));
           else 
               dispatch(setPlayer2(player));
           
           pName.current.value="";
   
        }
    }


    return (
        <React.Fragment>
                        <InputGroup>
            <InputGroup.Text 
                style={{width:"10%"}}
                id="playerName">
                <Icon.PersonCircle color={props.color} size={24}/>
            </InputGroup.Text>
            <FormControl
                style={{width:"65%"}}
                type="text"
                ref={pName}
                placeholder={props.placeholdertext}
                aria-label="Navn"
                aria-describedby="playername"
                id={props.playerId.toString()}
                onKeyPress={onKeyPressHandler}
                minLength={1}
                />
            </InputGroup>
        </React.Fragment>
    )
};

export default UserInput;
