import React, { useContext } from 'react';
import { ITarget } from '../model/Target';
import { MickyMouseContext } from '../state/context';
import UserTargets from './UserTargets';
import Curtain from '../../../common/Curtain';

interface ITargetListProps {
    playerid: number,
    active:boolean
}

const TargetList: React.FunctionComponent<ITargetListProps> = (props) => {

    const { state: { player1}, state:{player2} } = useContext(MickyMouseContext);

    const { playerid, active } = props;
    
    const player =  playerid === 1 ? player1 : player2;

    const targets = player?.targets;

    const placement:"LEFT"|"RIGHT" = playerid === 1 ? "LEFT" : "RIGHT";


    return (
        <React.Fragment>
            <div className="position-relative bg-black rounded px-1 h1">
            
                <Curtain bg="#FFF" opacity={0.135} visible={active} />
                
                <div className="row justify-content-between" style={{margin:0, padding:0}}>
                    <div className="col-8 col-sm-6"><h1 style={ active ? {color:"lime"} :{color:"#CCC"} }>{player?.name}</h1></div>
                    <div className="col-2 col-sm-3 text-start" title="Nuværende score"><h1 style={ active ? {color:"lime"} :{color:"#CCC"}}>{player?.wonSets}</h1></div>
                </div>
                <div >
                { targets?.sort((a:ITarget,b:ITarget) => a.id - b.id).map((targetObj:ITarget, index:number) => {
                    return <UserTargets placement={placement} key={index}  playerId = {playerid} target={targetObj} hitsPrTarget={player?.hitRequirement} isActive={active} />
                })}
                </div>
            </div>
        </React.Fragment>
    )
};


export default TargetList;
