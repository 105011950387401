import { addDoc, collection } from "firebase/firestore";
import Dataservice from "./DataService";
import { GameType } from "../common/GameTypes";
// import { Guid } from "guid-typescript";



export class DataStoreService extends Dataservice<any> {

    private rootCollection:GameType;

    constructor(mainCollection:string,rootCollection:GameType) {
        super(mainCollection);
        this.rootCollection = rootCollection;
    }

    create = async (data:any):Promise<string> => {

        const collectionPath = `${this.mainCollection}/${this.rootCollection.toString()}/${new Date().toDateString()}/`;
        const saveInCollection = collection(this.STORE,collectionPath);

       
        const docId = await addDoc(saveInCollection,JSON.parse(JSON.stringify(data)))
        .then(ref => {
            return ref.id;

        }).catch(error => {
            throw new Error("Error when adding data ", { cause: error });
        })
        ;

        return docId;
        // try {

        // } 
        // catch( e ) {

        //     throw new Error("Error when adding data ", { cause: e }); 
        // }

    }

    getAll(): Promise<any[]> {
        throw new Error("Method not implemented.");
    }
    update(item: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    delete(key: string): void {
        throw new Error("Method not implemented.");
    }
    deleteAll(): void {
        throw new Error("Method not implemented.");
    }
}

