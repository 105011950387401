import { IPlayer } from "../model/Player";
import { IScore } from "../model/Score";

export enum  ActionType {
    SetActivePlayer,
    AddPlayer1,
    AddPlayer2,
    AddScore,
    StartGame,
    StartNextRound,
    ResetGame,
    NewGame,
    UndoScore
}

export interface SetActivePlayer {
    type: ActionType.SetActivePlayer,
    payload:number
}

export interface AddPlayer1 {
    type: ActionType.AddPlayer1,
    payload:IPlayer
}

export interface AddPlayer2 {
    type: ActionType.AddPlayer2,
    payload:IPlayer
}

export interface AddScore {
    type:ActionType.AddScore,
    payload: IScore
}

export interface StartGame { 
    type:ActionType.StartGame,
    payload: { Rounds:number, UseRunningTotals:boolean, UseFullBermudaRules:boolean, Include41InBermuda:boolean }
}

export interface StartNextRound {
    type:ActionType.StartNextRound
}

export interface ResetGame {
    type:ActionType.ResetGame
}

export interface NewGame {
    type:ActionType.NewGame
}
export interface UndoScore {
    type:ActionType.UndoScore
}

export type BermudaGameActions = SetActivePlayer | AddPlayer1 | AddPlayer2 | AddScore | StartGame | StartNextRound | ResetGame | NewGame | UndoScore; 