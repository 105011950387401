import React, { useContext } from 'react';
import { Checkout } from '../../../common/Checkouts';
import { IPlayer } from '../model/Player';
import ScoreListItem from './ScoreListItem';
import { GameStats } from '../model/GameStats';
import { Five0OneContext } from '../state/context';

interface IPlayerStatusProps {
    player: IPlayer,
    activePlayerId: number,
    currentSet: number,
    setOver:boolean,
    showSuggestions:boolean
}

const PlayerStatus = (props: IPlayerStatusProps) => {


    const { player, currentSet, activePlayerId, setOver } = props;
    const { state: { gameScore }} = useContext(Five0OneContext);

    const checkOutClass = new Checkout();
    
    const checkoutText = checkOutClass.getCheckOut(player.currentScore);

    let lastScore: number = 0;
    let totalDarts: number = 0;

    const currentSetScores = player.scores.filter(flt => { return flt.setId === currentSet })

    const gameStats = new GameStats(player,[], gameScore);

    if (currentSetScores.length > 0) {
        lastScore = currentSetScores[currentSetScores.length - 1].value
        totalDarts = currentSetScores.reduce((sum, current) => sum + current.darts, 0);
    }

    return (
      <React.Fragment>
        <div
          className={
            activePlayerId !== player.id || setOver
              ? "position-relative"
              : "position-relative border rounded border-warning bg-black"
          }
        >
          <div className="d-flex justify-content-between p-3">
            <div
              className={
                activePlayerId === player.id && !setOver
                  ? "text-success"
                  : "text-muted"
              }
            >
              <h1
                style={
                  activePlayerId === player.id && !setOver
                    ? { color: "lime", fontSize: "4rem" }
                    : { fontSize: "4rem" }
                }
              >
                {player.name}
              </h1>
            </div>
            <div className="text-right d-flex flex-column justify-items-between">
              <div className="text-info h5 text-end">({player.handicap})</div>
              <div className="h6 text-end">
                (
                <span className="text-primary">
                  {gameStats.getAverageScore()}
                </span>
                <span className="text-light mx-2">/</span>
                <span className="text-secondary">
                  {gameStats.getAverageScorePerLeg(currentSet)}
                </span>
                )
              </div>
            </div>
          </div>

          <div className="row mt-1 px-3">
            <div className="d-flex justify-content-between">
              <div>
                <span className="text-success" style={{ fontSize: "5rem" }}>
                  {player.wonSets}
                </span>
              </div>

              <div className="text-end" style={{ height: 160 }}>
                {player.scores
                  .filter((flt) => {
                    return flt.setId === currentSet;
                  })
                  .slice(-3)
                  .map((sc, index) => {
                    return (
                      <ScoreListItem
                        key={index}
                        totalScore={sc.newTotalScore}
                        currentScore={sc.value}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="row px-3">
            <div className="d-flex justify-content-between">
              <div
                className="text-start text-danger"
                style={{ fontSize: "5rem" }}
              >
                {totalDarts}
              </div>
              <div
                className="text-end text-secondary"
                style={{ fontSize: "5rem" }}
              >
                {lastScore}
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="text-center text-light"
              style={{ fontSize: "11rem" }}
            >
              {player.currentScore}
            </div>
            {props.showSuggestions && player.currentScore > 41 ? (
              <div className="text-center text-info h4">{checkoutText}</div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );

}

export default PlayerStatus;