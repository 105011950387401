import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { IOneThreeOneRound } from "./model";
import RoundScore from "./RoundScore";
import ActiveRound from "./ActiveRound";

const OneThreeOneGame:React.FC = () => {

    // const [currentTarget, setCurrentTarget] = useState<number>(131);
    const [startTarget, setStarttarget] = useState<number>(131);
    const [rounds, setRounds] = useState<IOneThreeOneRound[]>([]);

    const calculateNextTarget = (): void => {
        let darts:number=12;
        let hasClosed:boolean = false;
        const lastRound = rounds[0];
        
        for (let item of lastRound.scores) {
            if (item.score !== 0) {
                darts = darts-3;
            } else {
                
                hasClosed = true;
                if (item.darts) {
                    darts = darts - item.darts;
                } else {
                    darts = darts - 3;
                }
                break;
            }
        }

        if (hasClosed) {
            setStarttarget(startTarget + 8 + darts);
        } else {
            setStarttarget(startTarget - 8);
        }
    }

    const getRoundOutputs = () => {
        // const reversedRounds = rounds.reverse();
        // console.log(rounds, reversedRounds);
        // console.log(reversedRounds[rounds.length-1]);
        // console.log(rounds[rounds.length-1]);

        return (
            rounds.map((item, index) => {
                return <RoundScore key={index} round={item} />
              })
        );
    }

    const handleOnChangeScore = (roundResult:IOneThreeOneRound) =>  {

        const currentRounds = rounds;
        roundResult.target = startTarget;
        currentRounds.unshift(roundResult);
        setRounds(currentRounds);

        calculateNextTarget();
    }

    return (
      <React.Fragment>
        <Container className="text-light">
          <Row className="border border-0 border-bottom border-info text-info my-3">
            <div className="d-flex  flex-row justify-content-between mb-3">
                <p className="display-5">Training game 131</p>
                <a href="/" className="btn btn-secondary small btn-small pt-3">Exit training</a>
            </div>
          </Row>
          <ActiveRound Target={startTarget} SubmitRound={handleOnChangeScore} />
          <Row className="text-success border-0 border-top pt-4">
            {getRoundOutputs()}
          </Row>
        </Container>
      </React.Fragment>
    );
}

export default OneThreeOneGame;