import React from "react";
import { IOneThreeOneRound } from "./model";
import { Col, Row } from "react-bootstrap";


const RoundScore:React.FC<{round:IOneThreeOneRound}> = (props) => {

    const { round } = props;

    let isClosed:boolean = false;

    const scores = round.scores.map((element,index) => {

      let classNames = "h4 text-center";
      let displayText = element.score?.toString();

      if (element.score === 0) {
        displayText = `Closed in ${element.darts}`;
        classNames = `${classNames} text-success`
        isClosed=true;
      } else {
        classNames = `${classNames} text-warning`
      }

        return (
            <React.Fragment>
                <Col sm={2} key={index} className={classNames}>
                    {displayText}
                </Col>
            </React.Fragment>
        );
        
    });

    let classes = "text-center h4";
    if (isClosed) {
      classes = `${classes} text-success`
    } else {
      classes = `${classes} text-danger`
    }

    return (
        <Row>
            <Col sm={2} className="d-flex justify-content-around" >
                <span className="text-light text-start small">
                    #{round.round}
                </span>
                <span className={classes}>
                    {round.target }
                </span>
            </Col>
            {scores}
        </Row>
    );

}
export default RoundScore;