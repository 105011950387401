import React from 'react';

export interface ICurtainProps {
    bg:string,
    opacity:number,
    visible:boolean
}

const Curtain =(props:ICurtainProps) => {

    const { bg, opacity, visible } = props;
    const classes = `position-absolute rounded ${visible? "invisible" : "visible"}`
    return (
    <div className={classes}  
            style={
                {zIndex:1, 
                cursor:'not-allowed', 
                top:0, 
                left:0,
                backgroundColor:bg, 
                opacity:opacity, 
                width:"100%", 
                height:"100%"}} 
        />
    )
}

export default Curtain;