import * as React from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import t20 from "../../../images/AdobeStock_74500319.jpeg";
import { BermudaCOntext } from "../state/context";
import UserInput, { IUserInputProps } from "./UserInput";
import { startGame } from "../state/reducer";

export interface IBermudaGameSettingsProps {
  // onSetsChange: (value: string) => void,
  // onTargetsChange: (valule: string) => void
}

const GameSettings: React.FunctionComponent<IBermudaGameSettingsProps> = (
  props
) => {
  const { dispatch, state } = React.useContext(BermudaCOntext);
  const [useRunningTotals, setUseRunningTotals] = React.useState<boolean>(true);
  const [useFullBermuda, setUseFullBermudaRules] = React.useState<boolean>(false);
  const [use41InBermuda, setUse41InBermudaRules] = React.useState<boolean>(true);
  const [selectedLegs, setSelectedLegs] = React.useState<number>(state.rounds);

  const handleStartGameClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Rounds selected POST:",selectedLegs);
    dispatch(startGame(selectedLegs, useRunningTotals, useFullBermuda, use41InBermuda));
  };

  const handleScoreOnchange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const value = +event.target.value;
    console.log("Rounds selected PRE:",selectedLegs, "New value:", value);
    setSelectedLegs(value);

  };

  const p1Props: IUserInputProps = {
    color: "green",
    placeholdertext: "Angiv Grøn spiller/hold",
    iconSize: 24,
    playerId: 1,
  };

  const p2Props: IUserInputProps = {
    playerId: 2,
    color: "red",
    placeholdertext: "Angiv Rød spiller/hold",
    iconSize: 24,
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <h1 className="text-light">
            {!useFullBermuda ? <span>Skovhugger</span> : <span>Bermuda</span>}
          </h1>
        </Row>
        <Row className="mb-2">
          <Col xs={6}>
            <UserInput {...p1Props} />
          </Col>
          <Col xs={6}>
            <UserInput {...p2Props} />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <InputGroup>
              <InputGroup.Text>No of rounds</InputGroup.Text>
              <Form.Select
                defaultValue={state.rounds}
                onChange={handleScoreOnchange}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={5}>5</option>
                <option value={7}>7</option>
                <option value={7}>9</option>
                <option value={11}>11</option>
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <InputGroup>
              <InputGroup.Checkbox
                onChange={() => setUseRunningTotals(!useRunningTotals)}
                checked={useRunningTotals}
              />
              <InputGroup.Text>Use running totals</InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Checkbox
                onChange={() => setUseFullBermudaRules(!useFullBermuda)}
                checked={useFullBermuda}
              />
              <InputGroup.Text>Use Bermuda rules</InputGroup.Text>
            </InputGroup>
          </Col>
          <Col>

            <InputGroup style={!useFullBermuda ? { display: "none" } : undefined}>
              <InputGroup.Checkbox
                onChange={() => setUse41InBermudaRules(!use41InBermuda)}
                checked={use41InBermuda}
              />
              <InputGroup.Text>Include 41 target</InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>        

        <Row className="mt-4">
          <Col xs={6} className="px-4">
            <Alert
              variant="success"
              className={state.player1 ? "visible" : "invisible"}
            >
              <Alert.Heading>Player 1</Alert.Heading>
              <h1>{state.player1?.name}</h1>
            </Alert>
          </Col>

          <Col xs={6}>
            <Alert
              variant="danger"
              className={state.player2 ? "visible" : "invisible"}
            >
              <Alert.Heading>Player 2</Alert.Heading>
              <h1>{state.player2?.name}</h1>
            </Alert>
          </Col>
        </Row>

        {state.player1 && state.player2 ? (
          <Row className="mt-3 d-flex justify-content-between">
            <Col xs={12} className="text-center">
              <Button
                onClick={handleStartGameClick}
                className="px-5 py-4"
                variant="success"
              >
                <h1>Start Game</h1>
              </Button>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col className="text-center mt-5">
            <img
              src={t20}
              alt="Tripple 20"
              className="rounded rounded-circle"
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GameSettings;
