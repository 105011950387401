import React, { useState } from "react";
import { TargetTypeEnum } from "./common/DartBoardMatrix";
import { DartPlayer, IDartScore } from "./common/ComputerPlayer";

import { Button, Col, Container, Row } from "react-bootstrap";
import { Checkout } from "./common/Checkouts";


interface ITestProps {}
interface IRoundItemProps {
    round : IRound
}

interface IRoundListProps {
    rounds : IRound[]
}

interface IRound {
    round:number;
    darts:IDartScore[],
    roundStatus:string|null
}

const Dart:React.FC<{dart: IDartScore}> = (props) => {
    const { dart } = props;

    return <Col className="text-success">
        <Row>Score: {dart.score}</Row>
        <Row>Targeted: {dart.fieldTargeted}</Row>
        <Row>Hit: {dart.fieldHit}</Row>
    </Col>

}

const RoundItem: React.FC<IRoundItemProps> = props => {

    const { round, round: {darts}} = props;

    const renderDarts = () => {
        return darts.map((item, index) => {
            console.log("rendering dart:",item)
            return <Dart key={index} dart={item} />
        })
    }

    const totalRoundScore = darts.reduce((sum, current) => {return sum + current.score},0);

    return (<React.Fragment>
        <Row className="text-info border border-0 border-bottom mb-2 p-4">
            <Col>
                <Row>
                    Round: {round.round}
                </Row>
                <Row>
                    Status: {round.roundStatus}
                </Row>
            </Col>
            <Col>
                Score: {totalRoundScore}
            </Col>
            {renderDarts()}
        </Row>
    </React.Fragment>
    )
}

const RoundsList: React.FC<IRoundListProps> = props => {

    const { rounds } = props;

    const renderRounds = () => {
        return rounds.map((round, index) => {
            //return <div className="text-light" key = {index}><h3>{round.round} - {round.roundStatus}</h3></div>
            return <RoundItem key={index} round={round} />
        });
    }

    return (<React.Fragment>
        <Container className="text-light">
            {renderRounds()}
        </Container>

    </React.Fragment>);
}

const Test: React.FC<ITestProps> = (props) => {
  const checkOut = new Checkout();
  const computerPlayer = new DartPlayer(4);
  // const [darts, setDarts] = useState<number>(0);
  const [totalScore, setTotalScore] = useState<number>(170);
  const [roundNumber, setRoundNumber] = useState<number>(0);
  const [rounds, setRounds] = useState<IRound[]>([]);
  
  const getRound = async () => {
    const currentRound:IRound = {
        round:roundNumber,
        darts:[ 
            {fieldType: TargetTypeEnum.None, score:0 , fieldHit:"", fieldTargeted:""}, 
            {fieldType: TargetTypeEnum.None, score:0 , fieldHit:"", fieldTargeted:""},
            {fieldType: TargetTypeEnum.None, score:0 , fieldHit:"", fieldTargeted:""}
        ],
        roundStatus:null
    }
    
    let currentTotalScore = totalScore;
    for (let index = 0; index < 3; index++) {
        // setDarts(index);        
        const checkoutTarget = checkOut.getCheckOut(currentTotalScore)?.trim();
        // console.log("Found checkpout: ", checkoutTarget);
        
        let fieldType:TargetTypeEnum = TargetTypeEnum.Triple;
        let field:string="T20";

        if (checkoutTarget) 
        {
            const checkoutTargetFields:string[] = checkoutTarget.split("  ");
            field = checkoutTargetFields[0];
            // console.log("Available fields in checkout:", checkoutTargetFields)

            if (field.includes("D"))
                fieldType = TargetTypeEnum.Double;
            if (field.includes("T"))
                fieldType = TargetTypeEnum.Triple;
        }

        // console.log("Going for score: ", field, fieldType);

        const score = computerPlayer.getScore(field,fieldType);
        if (score)
        {
            // console.log("Scored: ", score);
            currentRound.darts[index] = score
            currentTotalScore = currentTotalScore - score.score;
            if (currentTotalScore < 0 || (currentTotalScore === 0 && score.fieldType !== TargetTypeEnum.Double))
            {
                currentRound.roundStatus = "Bust"
                
                currentTotalScore = totalScore;
                // setDarts(2);
                break;

            } else if (currentTotalScore === 0 && score.fieldType === TargetTypeEnum.Double)
            {
                currentRound.roundStatus = "Closed";
                // setDarts(2);
                break;
            }
            
            // else 
            // {
            //     // setTotalScore(totalScore - score.score)
            //     currentRound.darts[darts] = score
            // }
        } 
        else 
        {
            currentRound.darts[index] = { score: 0, fieldType, fieldHit: "", fieldTargeted: field }
        }
        // console.log("Current Total Score: ", currentTotalScore);


        await new Promise(f => setTimeout(f,500));
        // console.log("Throwing next dart...");

    }
    // console.log("Current round status:", currentRound);
    rounds.push(currentRound);
    setTotalScore(currentTotalScore);
    setRounds(rounds);
    setRoundNumber(roundNumber +1);

    // console.log("Currnet game rounds:", rounds);
  }

  const reset = () => {
    setTotalScore(501);
    setRoundNumber(0);
    setRounds([]);

  }

  return (
    <React.Fragment>
      <Container>
        <div className="d-flex flex-row justify-content-between">
          <h6 className="text-light my-4">Remaining score: {totalScore}</h6>

          {totalScore === 0 ? (
            <Button onClick={reset} variant="success">
              Reset
            </Button>
          ) : (
            <Button onClick={getRound}>Throw darts</Button>
          )}
        </div>
      </Container>
      <RoundsList rounds={rounds} />
    </React.Fragment>
  );
};

export default Test;
