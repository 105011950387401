
export type FieldType = 13|14|15|16|17|18|19|20|41|"T"|"D"|"B";

export interface IScore {
    value: number|null,
    field : FieldType;
    fieldOrder:number|null;
    initialScore:number;
    min:number|null;
    max:number|null;
    fieldHint:string;
    // getScoreValue(): number;
}

export class Score implements IScore {
    value:number|null= null;
    field: FieldType;
    fieldOrder: number|null = null;
    initialScore: number = 0;
    fieldHint: string = "";
    min: number | null = 0;
    max: number | null = 0;

    constructor(field :  FieldType, value:number|null, initialValue:number = 0) {

        this.field = field;
        this.value = value;

        
        if (initialValue !== undefined)
        this.initialScore = initialValue;
    
        this.fieldHint = "Enter number of hits (1-9)";
    
        if (field === "D" || field === "T")
            this.fieldHint = "Enter the actual score";

        if (field === 41)
            this.fieldHint = "Any value other than 0 will give score";

    }

    getScoreValue = ():number => {

        if (this.value && this.value !== 0) {
            
            if (this.field === "T" || this.field === "D")
            {
                return this.value;
            }
            
            if (this.field === ("B")) 
            {
                return this.value * 25;
            }
            
            if(this.field === 41) 
            {
                return 41;
            }
            return this.value * this.field;
        }
        return 0;
    }

}