import React, { useRef } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import * as Icons from "react-bootstrap-icons";
import { IPlayer } from "../model/Player";
import { Five0OneContext } from "../state/context";
import { setPlayer } from "../state/reducer";

export interface IPlayerProps {
  placeHolder: string;
  playerId: number;
  disabled: boolean;
  iconColor: string;
}

const Player = (props: IPlayerProps) => {
  const { placeHolder, disabled, iconColor, playerId } = props;

  const { dispatch } = React.useContext(Five0OneContext);
  const refValueName = useRef<HTMLInputElement>(null);
  const refValueHandicap = useRef<HTMLInputElement>(null);

  // const onSavePlayersHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
  //     event.preventDefault();

  //     if (!refValueName.current?.value)
  //         return;

  //     if (refValueName.current)
  //     {
  //         const p:IPlayer = {
  //             name: refValueName.current.value,
  //             handicap: refValueHandicap.current ? +refValueHandicap.current.value : 0,
  //             id : playerId,
  //             scores:[],
  //             wonSets : 0,
  //             currentScore : 0
  //         }
  //         dispatch(setPlayer(p));
  //     }
  // }
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (refValueName.current) {
        const p: IPlayer = {
          name: refValueName.current.value,
          handicap: refValueHandicap.current
            ? +refValueHandicap.current.value
            : 0,
          id: playerId,
          scores: [],
          wonSets: 0,
          currentScore: 0,
        };
        dispatch(setPlayer(p));
      }
    }
  };
  return (
    <React.Fragment>
      <InputGroup>
        <InputGroup.Text style={{ width: "10%" }} id="playerName">
          <Icons.PersonCircle color={iconColor} size={24} />
        </InputGroup.Text>
        <FormControl
          style={{ width: "65%" }}
          ref={refValueName}
          placeholder={placeHolder}
          disabled={disabled}
          aria-label="Navn"
          onKeyPress={handleKeyPress}
          aria-describedby="playername"
        />
        <FormControl
          style={{ width: "15%" }}
          ref={refValueHandicap}
          placeholder="Handicap"
          disabled={disabled}
          aria-label="Handicap"
          onKeyPress={handleKeyPress}
          aria-describedby="playername"
        />
        {/* <Button variant="outline-success" 
                style={{width:"10%"}}
                onClick={onSavePlayersHandler}>
                <Icons.HandThumbsUpFill size={24} />

            </Button> */}
      </InputGroup>
    </React.Fragment>
  );
};

export default Player;
