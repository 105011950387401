import React, { useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormControl,
  Overlay,
} from "react-bootstrap";
import { Status } from "../../../common/Status";
import { Checkout } from "../../../common/Checkouts";
import { Five0OneContext } from "../state/context";
import { addPlayerScore, newRound, removeLastScore } from "../state/reducer";
import PlayerStatus from "./Five0OnePlayerStatus";
import * as Icons from 'react-bootstrap-icons';
import OnScreenKeypad from "../../../common/OnScreenKeypad";

const Five0OneGame = () => {
  interface IAlert {
    showAlert: boolean;
    message: String;
  }

  const [alert, setAlert] = React.useState<IAlert>({
    showAlert: false,
    message: "",
  });
  const scoreValue = useRef<HTMLInputElement>(null);
  const { state, dispatch } = React.useContext(Five0OneContext);
  const { player1, player2, activePlayerId } = state;
  const [showKeypad, setShowKeypad] = React.useState<boolean>(false);
  // const [enteredValue, setEnteredValue] = React.useState<string|undefined>();

  const checkouts = new Checkout();
  const handleKeyPress = (event: React.KeyboardEvent) => {
    // reset the Alert box
    setAlert({ showAlert: false, message: "" });

    if (event.key === "Enter") {
      console.log(scoreValue.current?.value);
      submitScore(scoreValue.current?.value);
    }

    if (event.key === "-" && showCancelScoreButton()) {
      event.preventDefault();
      removeScore();
    }
  };

  const submitScore = (enteredValue:string|undefined) => {
    setAlert({ showAlert: false, message: "" });

    if (enteredValue) {
      if (
        Number(enteredValue) &&
        Number(enteredValue) > 180
      ) {
        setAlert({
          showAlert: true,
          message: "Cannot score more than 180!!",
        });
      } else {


        const playerScore =
          activePlayerId === 1
            ? player1!.currentScore
            : player2!.currentScore;

        const invalidScores = [
          179, 178, 176, 175, 173, 172, 169, 166, 163,
        ];

        let score: number | "*1" | "*2" | "*3" = 0;
        if (
          enteredValue === "*1" ||
          enteredValue === "*2" ||
          enteredValue === "*3"
          //enteredScore === playerScore.toString()
        ) {
          score = enteredValue;
          if (playerScore < 171 && checkouts.tryCheckOut(playerScore)) // todo: Add number of darts for checkout - i.e. cannot chekcout 158 on 2 darts 
          {
            dispatch(addPlayerScore(activePlayerId, score));
          } else {
            setAlert({
              showAlert: true,
              message: "Invalid - Checkout on " + playerScore.toString() + " not possible",
            });
          }
        }

        else {
          let score: number = 0;
          // Check if 1st character is a '/'.. if it is - the entered score is the current score minus whatever is after the '/', ie. 
          // the players current score is 78, entered value is /32, which means that the player is now at 32 and has therefore scored 78 - 32 = 46, so the entered score should be transleted to 46
          // if ONLY a '/' is entered , the current score should be halved...
          if (enteredValue === '/') {
            if (playerScore % 2 === 1) {
              setAlert({
                showAlert: true,
                message: "Invalid - cannot halve " + playerScore.toString(),
              });
              return;
            }
            score = playerScore / 2;
          }
          else {
            if (enteredValue.charAt(0) === '/') {
              score = playerScore - Number(enteredValue.slice(1));
              // console.log(Number(scoreValue.current.value.slice(1)), playerScore, score );

              if (score > 171 || invalidScores.indexOf(score) !== -1) {
                setAlert({
                  showAlert: true,
                  message: "Invalid score: " + score.toString(),
                });
                return;

              }
              if (score < 0)
                return;

            }
            else {
              if (enteredValue.indexOf('+') !== -1) {
                console.log("Score begins with '/'");
                score = enteredValue.split("+").reduce((sum, current) => { return sum + Number(current) }, 0);
              }
              else {
                score = +enteredValue;
              }
            }
          }

          if (playerScore === score && !checkouts.tryCheckOut(playerScore)) {
            setAlert({
              showAlert: true,
              message: "Invalid - Checkout on " + playerScore.toString() + " not possible",
            });
            return;
          }

          if (invalidScores.indexOf(score) !== -1) {
            setAlert({ showAlert: true, message: "Invalid score: " + score.toString() });
            return;
          }
          if (playerScore - score < 2 && playerScore - score !== 0) {
            setAlert({
              showAlert: true,
              message: "Invalid score. Endscore less than 2",
            });
            return;
          }

          if (score === 0) {
            dispatch(addPlayerScore(activePlayerId, 0));
          } else {
            if (score) {
              // score = +scoreValue.current.value;
              dispatch(addPlayerScore(activePlayerId, score));
            } else {
              setAlert({
                showAlert: true,
                message:
                  "Please enter a number or *1,*2,*3 for checkout in 1, 2 or 3 darts",
              });
              return;
            }
          }
        }
      }
    }
    if (scoreValue.current)
      scoreValue.current!.value = "";
  }

  const removeScore = () => {
    setAlert({ showAlert: false, message: "" });

    if (scoreValue.current) {
      scoreValue.current!.value = "";
    }
    dispatch(removeLastScore());

  }

  const handleNewRound = () => {
    const wonByPlayer = player1!.currentScore === 0 ? player1! : player2!;
    dispatch(newRound(wonByPlayer));
  };
  const showCancelScoreButton = (): boolean => {
    if (state.activePlayerId === 1 && showKeypad === false)
      return state.player2!.scores.filter(item => item.setId === state.activeSet).length > 0;
    if (state.activePlayerId === 2 && showKeypad === false)
      return state.player1!.scores.filter(item => item.setId === state.activeSet).length > 0;
    return false;
  }


  const getValueFromComponent = (value: string | number) => {

    if (value === "-")
      removeScore();
    else {
      submitScore(value.toString());
    }

  }

  // const cancelLastScore = () => {
  //     dispatch(removeLastScore())
  // }
  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          {/* <Col>
            <ScoreList playerId={1} setId={state.activeSet} />
          </Col> */}

          <Col>
            {state.status === Status.FInished ? null : (
              <Row>
                <Col xs={6}>
                  <PlayerStatus
                    player={player1!}
                    activePlayerId={activePlayerId}
                    currentSet={state.activeSet}
                    setOver={state.roundOver}
                    showSuggestions={state.showSuggestions}
                  />
                </Col>
                <Col xs={6}>
                  <PlayerStatus
                    player={player2!}
                    activePlayerId={activePlayerId}
                    currentSet={state.activeSet}
                    setOver={state.roundOver}
                    showSuggestions={state.showSuggestions}
                  />
                </Col>
              </Row>
            )}

          </Col>
          {/* <Col>
          <ScoreList playerId={2} setId={state.activeSet} />
        </Col> */}
        </Row>
        {state.roundOver && state.status !== Status.FInished ? (
          <Row>
            <Col xs={12} className="text-center">
              <Button
                variant="success"
                className="p-3 mt-3"
                onClick={handleNewRound}
                autoFocus
              >
                <h1>Next set</h1>
              </Button>
            </Col>
          </Row>
        ) : (
          <>
              <Row className="mt-4">
                <Col xs={1}>
                  {showKeypad ?
                    <Icons.Keyboard className="text-warning" size={24} onClick={() => setShowKeypad(!showKeypad)}></Icons.Keyboard> :
                    <Icons.KeyboardFill className="text-secondary" size={24} onClick={() => setShowKeypad(!showKeypad)}></Icons.KeyboardFill>
                  }
                </Col>
                <Col xs={10}>
                  {showKeypad ?
                  <OnScreenKeypad OnEnter={getValueFromComponent} ShowClosingButtons = {true} ShowFixedScores={true} /> :
                    // <Five0OneKeyboard OnEnter={getValueFromComponent} /> :
                  <FormControl
                    disabled={showKeypad}
                    autoComplete="false"
                    ref={scoreValue}
                    type="text"
                    className="text-center"
                    style={{ fontSize: "3rem" }}
                    onKeyPress={handleKeyPress}
                    placeholder={
                      activePlayerId === 1
                        ? player1!.name + "'s score ?"
                        : player2!.name + "'s score ?"
                    }
                    autoFocus
                  />
                  }
                </Col>
                <Col xs={1} />
              </Row>
              <Row>
                          <Col xs={12} className="pt-3">          
                  {showCancelScoreButton() ? <Row className="mt-1">

                    <Col xs={3} className="text-center" >
                      <span className="text-muted" title="WARNING - use with CARE!!!" >To checkout with 1, 2 or 3 darts,- use *1, *2 or *3</span>
                    </Col>
                    <Col xs={3} className="text-center" >
                      <span className="text-muted" title="WARNING - use with CARE!!!" >To undo last entered score - press '-' on the numeric keypad</span>
                    </Col>
                    <Col xs={3} className="text-center" >
                      <span className="text-muted" title="WARNING - use with CARE!!!" >To set your final score - use '/x' to set your score to x (ie /32 sets your score to 32)</span>
                    </Col>
                    <Col xs={3} className="text-center" >
                      <span className="text-muted" title="WARNING - use with CARE!!!" >To automatically halve your current score  - use '/' without a number</span>
                    </Col>

                  </Row> : null}

                  <Overlay
                    target={scoreValue.current}
                    show={alert.showAlert}
                    placement="bottom"
                  >
                    {({
                      placement,
                      arrowProps,
                      show: _show,
                      popper,
                      ...props
                    }) => (
                      <div
                        {...props}
                        style={{
                          backgroundColor: "rgba(255, 100, 100, 0.85)",
                          padding: "20px 40px",
                          marginTop: 15,
                          marginRight: 30,
                          fontSize: "2rem",
                          color: "white",
                          borderRadius: 10,
                          textAlign: "right",
                          ...props.style,
                        }}
                      >
                        {alert.message}
                      </div>
                    )}
                  </Overlay>
                </Col>
              </Row>
          </>
        )}
      </Container>
    </React.Fragment>
    );
};

export default Five0OneGame;
