import { Guid } from "guid-typescript";
import { Status } from "../../../common/Status";
import { IPlayer } from "../model/Player";
import { IScore } from "../model/Score";
import {
  ActionType,
  AddGameScore,
  Five0OneGameActions,
  NewGame,
  NewRound,
  NextGame,
  RemoveLastScore,
  SetGameSettings,
  SetPlayer,
  StartGame,
  SwitchPlayers,
} from "./actions";

import { IFive0OneGameState, initialFive0OneGameState } from "./state";

export function five0OneGameReducer(
  state: IFive0OneGameState,
  action: Five0OneGameActions
): IFive0OneGameState {
  
  switch (action.type) {
    case ActionType.SetPlayer:
      const setPlayerState = { ...state };
      if (action.payload.id === 1) {
        return {
          ...setPlayerState,
          player1: action.payload,
        };
      }
      if (action.payload.id === 2) {
        return {
          ...setPlayerState,
          player2: action.payload,
        };
      }

      return state;

    case ActionType.SetGameSettings:
      const p1Hcp = state.player1!.handicap;
      const p2Hcp = state.player2!.handicap;
      const s = {
        ...state,
        roundStartPlayerId: action.payload.startPlayerId,
        activePlayerId: action.payload.startPlayerId,
        totalSets: action.payload.rounds,
        gameScore: action.payload.score,
        tiebrakValue: action.payload.tiebreak,
        roundsTypeBestOf: action.payload.roundsType,
        tiebreakAllRounds: action.payload.tiebreakAllROunds,
        showSuggestions: action.payload.showSuggestions,
        status: Status.InProgress,
        player1: {
          ...state.player1!,
          currentScore: action.payload.score - p1Hcp,
        },
        player2: {
          ...state.player2!,
          currentScore: action.payload.score - p2Hcp,
        },
      };
      return s;

    case ActionType.StartGame:
      return state;

    case ActionType.AddPlayerScore:
      const newAddScoreState: IFive0OneGameState = { ...state }; // shallow copy

      const newScores =
        action.payload.playerId === 1
          ? [...newAddScoreState.player1!.scores]
          : [...newAddScoreState.player2!.scores];

      const playerCurrentScore =
        action.payload.playerId === 1
          ? newAddScoreState.player1!.currentScore
          : newAddScoreState.player2!.currentScore;
      const newScore = createScore(
        action.payload.score,
        playerCurrentScore,
        newScores.length,
        newAddScoreState.activeSet
      );
      newScores.push(newScore);

      const newPlayerCurrentScore = playerCurrentScore - newScore.value;

      if (action.payload.playerId === 1) {
        newAddScoreState.player1 = {
          ...newAddScoreState.player1!, // Shallow copy
          currentScore: newPlayerCurrentScore,
          scores: newScores,
          wonSets:
            newPlayerCurrentScore === 0
              ? newAddScoreState.player1!.wonSets + 1
              : newAddScoreState.player1!.wonSets,
        };
      }

      if (action.payload.playerId === 2) {
        newAddScoreState.player2 = {
          ...newAddScoreState.player2!,
          currentScore: playerCurrentScore - newScore.value,
          scores: newScores,
          wonSets:
            newPlayerCurrentScore === 0
              ? newAddScoreState.player2!.wonSets + 1
              : newAddScoreState.player2!.wonSets,
        };
      }
      newAddScoreState.activePlayerId = action.payload.playerId === 1 ? 2 : 1;

      if (newPlayerCurrentScore === 0) newAddScoreState.roundOver = true;

      if (newAddScoreState.roundsTypeBestOf === 0) {
        if (
          newAddScoreState.player1!.wonSets >
          newAddScoreState.totalSets / 2
        ) {
          newAddScoreState.status = Status.FInished;
          newAddScoreState.winner = 1;
        } else if (
          newAddScoreState.player2!.wonSets >
          newAddScoreState.totalSets / 2
        ) {
          newAddScoreState.status = Status.FInished;
          newAddScoreState.winner = 2;
        }
      } else if (newAddScoreState.roundsTypeBestOf === 1) {
        if (
          newAddScoreState.player1!.wonSets +
            newAddScoreState.player2!.wonSets >=
          newAddScoreState.totalSets
        ) {
          newAddScoreState.status = Status.FInished;
          newAddScoreState.winner =
            newAddScoreState.player1!.wonSets >
            newAddScoreState.player2!.wonSets
              ? 1
              : 2;
        }
      }

      return newAddScoreState;

    case ActionType.NextRound:
      const p1Hcp_ = state.player1!.handicap;
      const p2Hcp_ = state.player2!.handicap;

      const roundStartPlayerId = state.roundStartPlayerId === 1 ? 2 : 1;

      const nextRoundState = {
        ...state,
        roundOver: false,
        activeSet: state.activeSet + 1,
        roundStartPlayerId: roundStartPlayerId,
        activePlayerId: roundStartPlayerId,
      };

      nextRoundState.player1 = {
        ...state.player1!,
         currentScore: state.gameScore - p1Hcp_,
      };

      nextRoundState.player2 = {
        ...state.player2!,
         currentScore: state.gameScore - p2Hcp_,
      };

      return nextRoundState;

    case ActionType.NextGame:
      const nextGameP1:IPlayer ={...state.player1!, wonSets:0, currentScore: state.gameScore - state.player1!.handicap, scores:[]}
      const nextGameP2:IPlayer ={...state.player2!, wonSets:0, currentScore: state.gameScore - state.player1!.handicap, scores:[]}

      const nextGameState:IFive0OneGameState = {
        ...state,
        roundOver: false,
        status:Status.InProgress,
        activeSet: 1,
        roundStartPlayerId: 1,
        activePlayerId: 1,
        player1: nextGameP1,
        player2: nextGameP2,
        sessionId:Guid.create().toString()
      };

      return nextGameState;

    case ActionType.NewGame:
        const newGameState:IFive0OneGameState = {...initialFive0OneGameState,
            status: Status.NotStarted,
            player1:null,
            player2:null,
            sessionId:Guid.create().toString()
        }
        return newGameState;

    case ActionType.SwitchPlayer:
        const swithPlayerState = {...state};
        const tempPlayer = {...swithPlayerState.player2!, id:1};
        swithPlayerState.player2 = {...swithPlayerState.player1!,id:2};
        swithPlayerState.player1 =  tempPlayer;
        return swithPlayerState;
      
    case ActionType.RemoveLastScore:
        const p1:IPlayer = {...state.player1!};
        const p1Scores = [...state.player1!.scores];
        const p2 = {...state.player2!};
        const p2Scores = [...state.player2!.scores];
        const activeplayerId = state.activePlayerId === 1 ? 2 :1;

        if (state.activePlayerId === 1) {
          const removedItem = p2Scores.pop();
          if (removedItem) {
            p2.currentScore = p2.currentScore! + removedItem!.value;
            p2.scores={...p2Scores};
          }

        } else if (state.activePlayerId === 2 ) {
          
          const removedItem = p1Scores.pop();
          if (removedItem) {
            p1.currentScore! = p1.currentScore!+removedItem!.value;
            p1.scores={...p1Scores};
          }
        }
        
        const removeScoreState = {
          ...state,
          activePlayerId: activeplayerId,
          player1: { ...p1, scores: [ ...p1Scores ] },
          player2: { ...p2, scores: [ ...p2Scores ] },
        };
        return removeScoreState;
    default:
      return state;
  }
}

// const checkForWinner = () => {

// }

const createScore = (
  s: number | "*1" | "*2" | "*3",
  current: number,
  totalScoresCount: number,
  set: number
): IScore => {
  let sc: number = 0;
  let d: number = 3;
  let c: boolean = false;
  switch (s) {
    case "*1":
      d = 1;
      sc = current;
      c = true;
      break;
    case "*2":
      d = 2;
      sc = current;
      c = true;
      break;
    case "*3":
      sc = current;
      c = true;
      break;
    default:
      sc = s;
      c = current - sc === 0;
      break;
  }

  const score: IScore = {
    value: sc,
    darts: d,
    newTotalScore: current - sc,
    turnNumber: totalScoresCount + 1,
    setId: set,
    isClosing: c,
  };

  return score;
};

// Public helper functions

export const addPlayerScore = (
  playerId: number,
  score: number | "*1" | "*2" | "*3"
): AddGameScore => ({
  type: ActionType.AddPlayerScore,
  payload: { playerId, score },
});

export const setPlayer = (player: IPlayer): SetPlayer => ({
  type: ActionType.SetPlayer,
  payload: player,
});

export const setGameSettings = (
  score: 170 | 301 | 501 | 701 | 1001,
  rounds: number,
  tiebreak: number,
  tiebreakAllROunds: boolean,
  roundsType: number,
  startPlayerId: number,
  showSuggestions:boolean
): SetGameSettings => ({
  type: ActionType.SetGameSettings,
  payload: {
    rounds,
    score,
    tiebreak,
    tiebreakAllROunds,
    roundsType,
    startPlayerId,
    showSuggestions
  },
});

export const startGame = (): StartGame => ({
  type: ActionType.StartGame,
});

export const newRound = (player: IPlayer): NewRound => ({
  type: ActionType.NextRound,
  payload: player,
});

export const nextGame = (): NextGame => ({
  type: ActionType.NextGame,
});

export const newGame = (): NewGame => ({
  type: ActionType.NewGame,
});

export const switchPlayers = (): SwitchPlayers => ({
  type: ActionType.SwitchPlayer,
});

export const removeLastScore = (): RemoveLastScore =>({
  type: ActionType.RemoveLastScore
});
