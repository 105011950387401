import * as React from 'react';
import * as Icons from 'react-bootstrap-icons';
import { MickyMouseContext } from '../state/context';
import { switchPlayer } from '../state/reducer';

interface ILegendProps {
    text: string | number
}

const LegendItem = (props: ILegendProps) => {

    const { text } = props;

    return (
        <div style={{ marginTop: 0, marginBottom:26, paddingLeft:10, paddingRight:10 }}>
            <h1 className="text-center">{text}</h1>
        </div>
    );

}

const MickeyMouseLegend: React.FC = () => {

    const {state, dispatch} = React.useContext(MickyMouseContext)
    
    const middleColum = [];

    for (let index = 0; index < 8; index++) {
        middleColum.push(
            <LegendItem key={index} text={20 - index}/>
        );
    };

    return (
        <React.Fragment>
            <div className="pb-3 text-center bg-light">
                <div className="h5 text-warning pt-3" style={{marginBottom:40}}>{state.CurrentRound}/{state.TotalRounds}</div>
                <div className="text-dark" >
                {middleColum}
                <LegendItem text="D" />
                <LegendItem text="T" />
                <LegendItem text="B" />
                </div>
                
                <button className="btn btn-link" style={{marginTop:5, padding:0, paddingBottom:0}} onClick={() => dispatch(switchPlayer(state.ActivePlayer=== 1 ? 2 :1))}>
                    {state.ActivePlayer === 1 ?
                    <Icons.ArrowRightCircle size="40" color="green" /> :
                    <Icons.ArrowLeftCircle size="40" color="green" />
                    }
                    
                </button>
            </div>
        </React.Fragment>

    );
};

export default MickeyMouseLegend;
