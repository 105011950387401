import React from "react";
import { MickeyMouseGameActions } from "./actions";
import { IMickeyMouseGameState, initialMickeyMouseGameState } from "./state";

export const MickyMouseContext = React.createContext<{
    state: IMickeyMouseGameState,
    dispatch: React.Dispatch<MickeyMouseGameActions>
}>({
    state:initialMickeyMouseGameState,
    dispatch: () => undefined
});