import { IPlayer } from "../model/Player";
import { FieldType, IScore, Score } from "../model/Score";

export class PlayerService {
  player: IPlayer;

  constructor(player: IPlayer) {
    this.player = player;
  }

  getTotalScore = (roundNumber: number): number => {
    const totalScore: number = this.player!.scores.sort(
      (a: IScore, b: IScore) => a.fieldOrder! - b.fieldOrder!
    )
      .filter((item) => item.fieldOrder! <= roundNumber)
      .reduce((sum, current) => {
        return current.value === 0
          ? Number(((sum + current.initialScore) / 2).toFixed(0))
          : current.value !== undefined
          ? (sum += Number(current.value) + current.initialScore)
          : (sum += current.initialScore);
      }, 0);

    return totalScore;
  };

  roundScore = (): number => {
    return this.player.scores.reduce((sum, current) => {
      return current.value === 0
        ? Number(((sum + current.initialScore) / 2).toFixed(0))
        : current.value !== undefined
        ? (sum += Number(current.value) + current.initialScore)
        : (sum += current.initialScore);
    }, 0);
  };

  addScore = (score: IScore): IScore[] => {
    
    let point: number = 0;
    if (score.field === "D" || score.field === "T") point = +score.value!;

    if (score.field === "B") point = +score.value! * 25;

    if (score.field === 41) point = +score.value! > 0 ? 41 : 0;

    if (Number(score.field) >= 13 && Number(score.field) <= 20) {
      point = +score.value! * Number(score.field);
    }

    var updatedItem = this.player.scores.find((element) => {
      return element.field === score.field;
    });

    if (updatedItem !== undefined) {
      updatedItem.value = point;
    }
    return this.player.scores;
  };

  initScores = (useFullBermuda: boolean, use41InBermuda: boolean, startScore:number): IScore[] => {
    this.player.scores = [];
    console.log("Current players startscore:",this.player.startScore, "Incoming StartScore:", startScore);
    if (useFullBermuda) {
      this.player.scores.push(this.getInitScore(13,null, startScore));
      this.player.scores.push(this.getInitScore(14, null));
      this.player.scores.push(this.getInitScore("D", null));
      this.player.scores.push(this.getInitScore(15, null));
      this.player.scores.push(this.getInitScore(16, null));
      this.player.scores.push(this.getInitScore("T", null));
      this.player.scores.push(this.getInitScore(17, null));
      this.player.scores.push(this.getInitScore(18, null));

      if (use41InBermuda) this.player.scores.push(this.getInitScore(41, null));

      this.player.scores.push(this.getInitScore(19, null));
      this.player.scores.push(this.getInitScore(20, null));
      this.player.scores.push(this.getInitScore("B", null));
    } else {
      this.player.scores.push(this.getInitScore(15, null, startScore));
      this.player.scores.push(this.getInitScore(16, null));
      this.player.scores.push(this.getInitScore("D", null));
      this.player.scores.push(this.getInitScore(17, null));
      this.player.scores.push(this.getInitScore("T", null));
      this.player.scores.push(this.getInitScore(18, null));
      this.player.scores.push(this.getInitScore(19, null));
      this.player.scores.push(this.getInitScore(41, null));
      this.player.scores.push(this.getInitScore(20, null));
      this.player.scores.push(this.getInitScore("B", null));
    }

    return this.player.scores;
  };

  getInitScore = (
    field: FieldType,
    value: number | null,
    initialValue: number|undefined = undefined
  ) => {
    const score = new Score(field, value, initialValue);
    score.fieldOrder = this.player.scores.length + 1;
    return score;
  };
}
