import React, { useState } from "react";
import Timer from "../Timer";
import { Button, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import { Checkout } from "../../common/Checkouts";

export interface ICheckoutTrainingProps {

}

const CheckoutTraining:React.FC<ICheckoutTrainingProps> = (props) => {

    const [time, setTime] = useState<number>(30*60);
    const [minTarget, setMinTarget] = useState<number>(20);
    const [maxTarget, setMaxTarget] = useState<number>(90);
    const [currentTarget, setCurrentTarget] = useState<number>(0);
    const [running, startTraininig] = useState<boolean>(false);
    const [checkoutSuggestion, setCheckoutSuggestion] = useState<string>("");
    const [hits, setHits] = useState<number[]>([]);

    const checkouts = new Checkout();

    const getNextTarget = ():void=> {
        setHits([]);
        const newTarget =  Math.floor(Math.random() * (maxTarget - minTarget +1) + minTarget);
        setCurrentTarget(newTarget);
        getCheckoutSuggestions();
    }

    const getRemaining  = ():number => {
        if (hits.length > 0) {
          const remaining =
            currentTarget -
            hits.reduce((sum, curent) => {
              return sum + curent;
            });
          return remaining;
        }
        return currentTarget;
    }

    const getCheckoutSuggestions = ():void => {
        const remaining = getRemaining(); 
        
        console.log(hits.length, remaining);

        var checkout =  checkouts.getCheckOutByNoOfDarts(3-hits.length, remaining);
        console.log(checkout);

        if (checkout)
            setCheckoutSuggestion(checkout);

        setCheckoutSuggestion("");
    }

    const TrainingSetup = () => {

        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <InputGroup>
                            <InputGroup.Text>Minutes</InputGroup.Text>
                            <FormControl type="number" value={time} onChange={(e) => {
                                if (e.currentTarget) {
                                    setTime(+e.currentTarget.value* 60)}
                                }
                                
                            } 
                            min={5} max={30}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Min. checkout value</InputGroup.Text>
                            <FormControl type="number" value={minTarget} onChange={(e) => {
                                if (e.currentTarget) {
                                    setMinTarget(+e.currentTarget.value)}
                                }
                            } 
                            min={21} max={170}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Max. checkout value</InputGroup.Text>
                            <FormControl type="number" value={maxTarget} onChange={(e) => {
                                if (e.currentTarget) {
                                    setMaxTarget(+e.currentTarget.value)}
                                }
                            } 
                            min={21} max={170}
                            />
                        </InputGroup>
                    </Row>
                    <Row>
                        <Button onClick={() =>  { 
                            getNextTarget();
                            startTraininig(true);
                        }}
                         variant="success" className="p-5 rounded">Start training</Button>
                    </Row>
                </Container>
            </React.Fragment>
        )

    }


    const Target = () => {

        const onKeyPressHandler = (event:React.KeyboardEvent) => {
      
            if (event.key === "Enter") {
                console.log("entered hit");

              event.preventDefault();
              const { value } = event.currentTarget as HTMLInputElement;
              if (value) {
                const currentHits = hits;
                hits.push(+value);
                setHits(currentHits);
                getCheckoutSuggestions();
              }
            }
          }



        return (
          <React.Fragment>
            <Container>
              <Row>
                <Timer
                  expiresIn={time}
                  OnComplete={() => startTraininig(false)}
                />
              </Row>
              <Row>
                <Col>
                  <h1 className="text-success display-1">{currentTarget}</h1>
                </Col>
                <Col>
                  <h1 className="text-info display-3">{getRemaining()}</h1>
                </Col>
                <Col>
                  <h1 className="text-info h1">{checkoutSuggestion}</h1>
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col>
                  <FormControl
                    style={{ fontSize: "1.8rem" }}
                    type="number"
                    onKeyPress={(e) => onKeyPressHandler(e)}
                    defaultValue={hits ? hits[0] : 0}
                  />
                </Col>
                <Col>
                  <FormControl
                    style={{ fontSize: "1.8rem" }}
                    type="number"
                    onKeyPress={(e) => onKeyPressHandler(e)}
                    defaultValue={hits ? hits[1] : 0}
                  />
                </Col>
                <Col>
                  <FormControl
                    style={{ fontSize: "1.8rem" }}
                    type="number"
                    onKeyPress={(e) => onKeyPressHandler(e)}
                    defaultValue={hits ? hits[2] : 0}
                  />
                </Col>
                <Col>
                  <Button onClick={getNextTarget}>Hit!</Button>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        );

    }


    return(
        <React.Fragment>
            { running ? <Target /> : <TrainingSetup /> }
        </React.Fragment>
    );
}

export default CheckoutTraining;