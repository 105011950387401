import { Status } from "../../../common/Status";
import { PlayerService } from "../components/PlayerService";
import { IPlayer, Player } from "../model/Player";
import { IScore } from "../model/Score";
import  {
    ActionType,
    AddPlayer1,
    AddPlayer2,
    AddScore,
    BermudaGameActions,
    SetActivePlayer,
    StartGame,
    StartNextRound,
    ResetGame,
    NewGame, 
    UndoScore
} from "./actions"

import { IBermudaGameState, initialBermudaGameState } from "./state";

export function bermudaGameReduce (
    state: IBermudaGameState,
    action:BermudaGameActions
): IBermudaGameState {

    switch (action.type) {
      case ActionType.AddPlayer1:
        state.player1 = action.payload;
        return { ...state };

      case ActionType.AddPlayer2:
        state.player2 = action.payload;
        return { ...state };

      case ActionType.SetActivePlayer:
        state.activePlayerId = action.payload;
        return { ...state };

      case ActionType.AddScore:
        let score = action.payload;
        const addScoreState: IBermudaGameState = { ...state };

        
        const addScoreNewPlayer =
        state.activePlayerId === 1
        ? { ...addScoreState.player1! }
        : { ...addScoreState.player2! };
        
        const player1Service = new PlayerService(addScoreNewPlayer);
        addScoreNewPlayer.scores = player1Service.addScore(score);

      const addScoreNewPlayer2 =
      state.activePlayerId !== 1
      ? { ...addScoreState.player1! }
      : { ...addScoreState.player2! };
      
          const player2Service = new PlayerService(addScoreNewPlayer2);

        if (score.value === 0) {
          addScoreNewPlayer.zeroScoreCount++;
        } else {
            addScoreNewPlayer.zeroScoreCount = 0;
        }

        if (state.activePlayerId === 2)
          addScoreState.activeField++;

        if (addScoreState.activeField > addScoreNewPlayer.scores.length || addScoreNewPlayer.zeroScoreCount === 3)
        {
          if (player1Service.roundScore() > player2Service.roundScore() && addScoreNewPlayer.zeroScoreCount !== 3) 
          {
            addScoreNewPlayer.wonSets++;
          }

          if (player2Service.roundScore() > player1Service.roundScore() || addScoreNewPlayer.zeroScoreCount === 3) 
          {
            addScoreNewPlayer2.wonSets++;
          }

          if (player1Service.roundScore() === player2Service.roundScore() && addScoreNewPlayer.zeroScoreCount)
          {
            addScoreNewPlayer2.wonSets++;
            addScoreNewPlayer.wonSets++;
          }

        }

        if (state.activePlayerId === 1) {
          addScoreState.player1 = { ...addScoreNewPlayer };
          addScoreState.player2 = { ...addScoreNewPlayer2 };
          addScoreState.activePlayerId = 2;
        } else {
          addScoreState.player2 = { ...addScoreNewPlayer };
          addScoreState.player1 = { ...addScoreNewPlayer2 };          
          addScoreState.activePlayerId = 1;
        }
 
        if (addScoreState.activeField > addScoreNewPlayer.scores.length || addScoreNewPlayer.zeroScoreCount === 3){

            addScoreState.GameStatus = Status.FInished;
        }

        return addScoreState;

      case ActionType.StartGame:
        state.include41InBermudaRules = action.payload.Include41InBermuda;
        state.useFullBermudaRules = action.payload.UseFullBermudaRules;
        state.useRunningTotals = action.payload.UseRunningTotals;
        state.rounds = action.payload.Rounds;
        const startGameplayer1Service = new PlayerService(state.player1!);
        const startGameplayer2Service = new PlayerService(state.player2!);
        state.player1!.scores = startGameplayer1Service.initScores(state.useFullBermudaRules, state.include41InBermudaRules,0);
        state.player2!.scores = startGameplayer2Service.initScores(state.useFullBermudaRules, state.include41InBermudaRules,0);
        state.GameStatus = Status.InProgress;
        return { ...state };

      case ActionType.StartNextRound:
      

      const newPlayer1 = new Player(1, state.player2!.name);
        newPlayer1.wonSets = state.player2!.wonSets;
        newPlayer1.startScore = 0;
        
        const newPlayer2 = new Player(2, state.player1!.name);
        newPlayer2.wonSets = state.player1!.wonSets;
        newPlayer2.startScore = 0;
        const startNextRoundPlayer1Service = new PlayerService(state.player1!);
        const startNextRoundPlayer2Service = new PlayerService(state.player2!);
        
        if (state.useRunningTotals) {
          console.log("loading startscores in reducer...");
          newPlayer2.startScore = startNextRoundPlayer1Service.roundScore();
          newPlayer1.startScore = startNextRoundPlayer2Service.roundScore();
          console.log("Player StartScores:", newPlayer1.startScore, newPlayer2.startScore);
        }
        console.log("Initializing next rounds scores...");
        newPlayer1.scores = startNextRoundPlayer1Service.initScores(state.useFullBermudaRules, state.include41InBermudaRules,newPlayer1.startScore);
        newPlayer2.scores = startNextRoundPlayer2Service.initScores(state.useFullBermudaRules, state.include41InBermudaRules,newPlayer2.startScore);

        const nextRoundState: IBermudaGameState = { ...state };

        nextRoundState.player1 = newPlayer1;
        nextRoundState.player2 = newPlayer2;
        nextRoundState.activeField = 1;
        nextRoundState.activePlayerId = 1;
        nextRoundState.activeRound++;
        nextRoundState.GameStatus = Status.InProgress;

        return { ...nextRoundState };

      case ActionType.NewGame:
        const newGameState =  { ...initialBermudaGameState };
        newGameState.player1=null;
        newGameState.player2=null;

        return newGameState;

      case ActionType.ResetGame:
        const resetGamePlayer1 = new Player(1, state.player2!.name);
        const resetGamePlayer2 = new Player(2, state.player1!.name);
        const resetGameState: IBermudaGameState = { ...initialBermudaGameState };
        const resetGamePlayer1Service = new PlayerService(state.player1!);
        const resetGamePlayer2Service = new PlayerService(state.player2!);
        resetGamePlayer1.scores = resetGamePlayer1Service.initScores(state.useFullBermudaRules, state.include41InBermudaRules,0);
        resetGamePlayer2.scores = resetGamePlayer2Service.initScores(state.useFullBermudaRules, state.include41InBermudaRules,0);        
        resetGameState.player1 = resetGamePlayer1;
        resetGameState.player2 = resetGamePlayer2;
        resetGameState.GameStatus = Status.InProgress;
        return resetGameState;

      case ActionType.UndoScore:
        if (state.activePlayerId === 1 && state.activeField ===1)
          return state;

        const undoScoreState = {...state}
        
        if (state.activePlayerId === 2)
        {
            undoScoreState.activePlayerId = 1;
        } else {
          undoScoreState.activePlayerId = 2;
          undoScoreState.activeField--;
        }

        return undoScoreState;


      default:
        const gameState = { ...initialBermudaGameState };
        return gameState;
    }
}


// Public helper functions

export const setPlayer = (player:IPlayer) : SetActivePlayer => ({
    type:ActionType.SetActivePlayer,
    payload:player.id
});

export const addPlayer1 = (player:IPlayer) : AddPlayer1 => ({
    type:ActionType.AddPlayer1,
    payload:player
});

export const addPlayer2 = (player:IPlayer) : AddPlayer2 => ({
    type:ActionType.AddPlayer2,
    payload:player
});

export const addScore = (score:IScore) : AddScore => ({
    type:ActionType.AddScore,
    payload: score
});

export const startGame = (Rounds:number, UseRunningTotals:boolean, UseFullBermudaRules:boolean, Include41InBermuda:boolean) : StartGame => ({
    type:ActionType.StartGame,
    payload: { Rounds, UseRunningTotals, UseFullBermudaRules, Include41InBermuda }
});

export const startNextRound = () : StartNextRound => ({
    type:ActionType.StartNextRound
});

export const resetGame = () : ResetGame => ({
    type:ActionType.ResetGame
});

export const startNewGame = () : NewGame => ({
    type:ActionType.NewGame
});

export const undoScore = () : UndoScore => ({
    type:ActionType.UndoScore
});