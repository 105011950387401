import { TargetTypeEnum } from "./DartBoardMatrix";

export const HiresMatrix = [
  { x: 33, y: 1, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 34, y: 1, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 35, y: 1, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 36, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 37, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 38, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 39, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 40, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 41, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 42, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 43, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 44, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 45, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 46, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 47, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 48, y: 1, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 49, y: 1, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 50, y: 1, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 30, y: 2, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 31, y: 2, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 32, y: 2, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 33, y: 2, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 34, y: 2, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 35, y: 2, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 36, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 37, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 38, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 39, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 40, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 41, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 42, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 43, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 44, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 45, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 46, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 47, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 48, y: 2, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 49, y: 2, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 50, y: 2, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 51, y: 2, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 52, y: 2, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 53, y: 2, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 54, y: 2, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 27, y: 3, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 28, y: 3, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 29, y: 3, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 30, y: 3, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 31, y: 3, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 32, y: 3, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 33, y: 3, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 34, y: 3, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 35, y: 3, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 36, y: 3, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 37, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 38, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 3, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 48, y: 3, value: "D20", targetType: TargetTypeEnum.Double, score: 20 },
  { x: 49, y: 3, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 50, y: 3, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 51, y: 3, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 52, y: 3, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 53, y: 3, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 54, y: 3, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 55, y: 3, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 56, y: 3, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 57, y: 3, value: "D1", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 24, y: 4, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 25, y: 4, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 26, y: 4, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 27, y: 4, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 28, y: 4, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 29, y: 4, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 30, y: 4, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 31, y: 4, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 32, y: 4, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 4, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 4, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 4, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 37, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 38, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 48, y: 4, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 49, y: 4, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 4, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 4, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 4, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 4, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 4, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 4, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 4, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 4, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 58, y: 4, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 59, y: 4, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 60, y: 4, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 22, y: 5, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 23, y: 5, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 24, y: 5, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 25, y: 5, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 26, y: 5, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 27, y: 5, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 28, y: 5, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 29, y: 5, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 30, y: 5, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 5, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 5, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 5, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 5, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 5, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 37, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 38, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 48, y: 5, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 49, y: 5, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 5, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 5, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 5, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 5, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 5, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 5, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 5, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 5, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 58, y: 5, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 59, y: 5, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 60, y: 5, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 61, y: 5, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 62, y: 5, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 21, y: 6, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 22, y: 6, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 23, y: 6, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 24, y: 6, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 25, y: 6, value: "D5", targetType: TargetTypeEnum.Double, score: 5 },
  { x: 26, y: 6, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 27, y: 6, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 28, y: 6, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 29, y: 6, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 30, y: 6, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 6, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 6, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 6, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 6, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 6, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 37, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 38, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 48, y: 6, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 49, y: 6, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 6, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 6, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 6, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 6, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 6, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 6, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 6, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 6, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 58, y: 6, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 59, y: 6, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 60, y: 6, value: "D1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 61, y: 6, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 62, y: 6, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 63, y: 6, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 64, y: 6, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 19, y: 7, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 20, y: 7, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 21, y: 7, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 22, y: 7, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 23, y: 7, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 24, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 25, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 26, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 27, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 28, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 29, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 30, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 7, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 37, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 38, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 48, y: 7, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 49, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 58, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 59, y: 7, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 60, y: 7, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 7, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 62, y: 7, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 63, y: 7, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 64, y: 7, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 65, y: 7, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 18, y: 8, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 19, y: 8, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 20, y: 8, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 21, y: 8, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 22, y: 8, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 23, y: 8, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 8, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 26, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 27, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 28, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 29, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 30, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 8, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 38, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 8, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 48, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 58, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 59, y: 8, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 60, y: 8, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 8, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 8, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 8, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 64, y: 8, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 65, y: 8, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 66, y: 8, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 16, y: 9, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 17, y: 9, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 18, y: 9, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 19, y: 9, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 20, y: 9, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 21, y: 9, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 9, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 9, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 9, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 9, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 26, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 27, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 28, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 29, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 30, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 9, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 38, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 9, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 48, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 58, y: 9, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 59, y: 9, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 9, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 9, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 9, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 9, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 9, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 65, y: 9, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 66, y: 9, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 67, y: 9, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 68, y: 9, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 15, y: 10, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 16, y: 10, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 17, y: 10, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 18, y: 10, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 19, y: 10, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 21, y: 10, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 10, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 10, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 10, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 10, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 28, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 29, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 30, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 10, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 10, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 10, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 10, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 10, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 10, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 10, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 10, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 10, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 10, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 58, y: 10, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 59, y: 10, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 10, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 10, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 10, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 10, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 10, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 65, y: 10, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 66, y: 10, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 67, y: 10, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 68, y: 10, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 69, y: 10, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 14, y: 11, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 15, y: 11, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 16, y: 11, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 17, y: 11, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 18, y: 11, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 19, y: 11, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 20, y: 11, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 21, y: 11, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 11, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 11, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 11, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 11, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 28, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 29, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 30, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 11, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 11, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 11, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 11, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 11, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 11, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 11, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 11, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 11, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 11, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 11, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 58, y: 11, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 59, y: 11, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 11, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 11, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 11, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 11, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 11, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 65, y: 11, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 66, y: 11, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 67, y: 11, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 68, y: 11, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 69, y: 11, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 70, y: 11, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 13, y: 12, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 14, y: 12, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 15, y: 12, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 16, y: 12, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 17, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 18, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 19, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 20, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 21, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 26, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 12, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 12, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 29, y: 12, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 30, y: 12, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 12, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 12, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 12, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 12, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 12, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 12, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 12, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 12, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 12, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 12, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 12, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 12, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 12, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 12, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 12, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 12, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 12, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 58, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 59, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 65, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 66, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 67, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 68, y: 12, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 69, y: 12, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 70, y: 12, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 71, y: 12, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 12, y: 13, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 13, y: 13, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 14, y: 13, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 15, y: 13, value: "D12", targetType: TargetTypeEnum.Double, score: 12 },
  { x: 16, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 17, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 18, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 19, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 20, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 21, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 26, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 13, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 29, y: 13, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 30, y: 13, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 13, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 13, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 13, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 13, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 13, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 13, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 13, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 13, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 13, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 13, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 13, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 13, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 13, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 13, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 13, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 13, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 13, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 13, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 13, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 13, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 13, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 13, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 13, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 13, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 13, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 13, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 58, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 59, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 65, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 66, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 67, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 68, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 69, y: 13, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 70, y: 13, value: "D18", targetType: TargetTypeEnum.Double, score: 18 },
  { x: 71, y: 13, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 72, y: 13, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 12, y: 14, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 13, y: 14, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 14, y: 14, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 15, y: 14, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 17, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 18, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 19, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 20, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 21, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 26, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 14, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 29, y: 14, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 30, y: 14, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 14, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 14, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 14, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 14, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 14, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 14, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 14, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 14, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 39, y: 14, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 14, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 14, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 14, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 14, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 14, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 14, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 14, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 14, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 14, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 14, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 14, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 14, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 14, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 14, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 14, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 14, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 14, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 57, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 58, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 59, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 65, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 66, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 67, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 68, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 69, y: 14, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 70, y: 14, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 14, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 72, y: 14, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 73, y: 14, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 11, y: 15, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 12, y: 15, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 13, y: 15, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 14, y: 15, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 15, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 15, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 18, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 19, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 20, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 21, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 26, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 29, y: 15, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 30, y: 15, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 15, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 15, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 15, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 15, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 15, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 15, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 15, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 15, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 15, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 15, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 15, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 42, y: 15, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 43, y: 15, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 44, y: 15, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 45, y: 15, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 15, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 15, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 15, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 15, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 15, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 15, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 15, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 15, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 15, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 15, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 57, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 58, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 59, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 65, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 66, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 67, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 68, y: 15, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 69, y: 15, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 15, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 15, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 15, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 73, y: 15, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 74, y: 15, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 10, y: 16, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 11, y: 16, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 12, y: 16, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 13, y: 16, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 14, y: 16, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 16, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 16, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 16, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 19, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 20, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 21, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 26, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 29, y: 16, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 30, y: 16, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 31, y: 16, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 16, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 33, y: 16, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 16, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 16, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 36, y: 16, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 37, y: 16, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 38, y: 16, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 39, y: 16, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 40, y: 16, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 41, y: 16, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 42, y: 16, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 43, y: 16, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 44, y: 16, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 45, y: 16, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 46, y: 16, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 47, y: 16, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 48, y: 16, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 49, y: 16, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 50, y: 16, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 51, y: 16, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 16, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 16, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 54, y: 16, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 16, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 56, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 57, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 58, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 59, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 65, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 66, y: 16, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 67, y: 16, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 16, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 16, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 16, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 16, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 16, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 73, y: 16, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 74, y: 16, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 75, y: 16, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 9, y: 17, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 10, y: 17, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 11, y: 17, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 12, y: 17, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 13, y: 17, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 14, y: 17, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 17, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 17, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 17, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 17, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 20, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 21, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 26, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 29, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 30, y: 17, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 31, y: 17, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 32, y: 17, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 33, y: 17, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 34, y: 17, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 35, y: 17, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 36, y: 17, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 37, y: 17, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 38, y: 17, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 39, y: 17, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 40, y: 17, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 41, y: 17, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 42, y: 17, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 43, y: 17, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 44, y: 17, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 45, y: 17, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 46, y: 17, value: "T20", targetType: TargetTypeEnum.Triple, score: 20 },
  { x: 47, y: 17, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 48, y: 17, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 49, y: 17, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 50, y: 17, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 51, y: 17, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 52, y: 17, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 53, y: 17, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 54, y: 17, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 55, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 56, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 57, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 58, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 59, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 65, y: 17, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 66, y: 17, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 17, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 17, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 17, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 17, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 17, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 17, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 73, y: 17, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 74, y: 17, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 75, y: 17, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 76, y: 17, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 8, y: 18, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 9, y: 18, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 10, y: 18, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 11, y: 18, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 12, y: 18, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 13, y: 18, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 14, y: 18, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 18, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 18, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 18, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 18, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 18, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 20, y: 18, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 21, y: 18, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 18, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 18, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 18, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 18, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 26, y: 18, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 18, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 18, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 29, y: 18, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 30, y: 18, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 31, y: 18, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 32, y: 18, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 33, y: 18, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 34, y: 18, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 35, y: 18, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 36, y: 18, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 18, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 18, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 18, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 18, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 18, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 18, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 18, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 18, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 18, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 18, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 18, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 18, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 18, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 18, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 51, y: 18, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 52, y: 18, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 53, y: 18, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 54, y: 18, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 55, y: 18, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 56, y: 18, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 57, y: 18, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 58, y: 18, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 59, y: 18, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 18, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 18, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 18, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 18, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 18, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 65, y: 18, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 66, y: 18, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 18, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 18, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 18, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 18, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 18, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 18, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 73, y: 18, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 74, y: 18, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 75, y: 18, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 76, y: 18, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 8, y: 19, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 9, y: 19, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 10, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 11, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 12, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 13, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 14, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 20, y: 19, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 21, y: 19, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 22, y: 19, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 19, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 19, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 19, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 26, y: 19, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 19, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 19, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 29, y: 19, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 30, y: 19, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 31, y: 19, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 32, y: 19, value: "T5", targetType: TargetTypeEnum.Triple, score: 5 },
  { x: 33, y: 19, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 19, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 19, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 19, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 19, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 19, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 19, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 40, y: 19, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 19, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 19, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 19, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 19, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 19, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 19, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 47, y: 19, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 19, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 19, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 19, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 19, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 19, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 19, value: "T1", targetType: TargetTypeEnum.Triple, score: 1 },
  { x: 54, y: 19, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 55, y: 19, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 56, y: 19, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 57, y: 19, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 58, y: 19, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 59, y: 19, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 19, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 19, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 19, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 19, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 64, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 65, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 66, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 73, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 74, y: 19, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 75, y: 19, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 76, y: 19, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 77, y: 19, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 7, y: 20, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 8, y: 20, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 9, y: 20, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 10, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 11, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 12, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 13, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 14, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 20, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 21, y: 20, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 22, y: 20, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 23, y: 20, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 20, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 20, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 26, y: 20, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 27, y: 20, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 28, y: 20, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 29, y: 20, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 30, y: 20, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 31, y: 20, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 32, y: 20, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 33, y: 20, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 20, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 20, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 20, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 20, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 20, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 20, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 20, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 20, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 20, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 20, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 20, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 20, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 20, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 20, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 20, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 20, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 20, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 20, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 20, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 20, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 54, y: 20, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 55, y: 20, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 56, y: 20, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 57, y: 20, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 58, y: 20, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 59, y: 20, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 60, y: 20, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 20, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 20, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 63, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 64, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 65, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 66, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 73, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 74, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 75, y: 20, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 76, y: 20, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 77, y: 20, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 78, y: 20, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 6, y: 21, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 7, y: 21, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 8, y: 21, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 9, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 10, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 11, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 12, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 13, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 14, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 20, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 21, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 22, y: 21, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 23, y: 21, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 24, y: 21, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 21, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 26, y: 21, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 27, y: 21, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 28, y: 21, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 29, y: 21, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 30, y: 21, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 31, y: 21, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 32, y: 21, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 33, y: 21, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 34, y: 21, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 21, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 21, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 21, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 21, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 21, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 21, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 21, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 21, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 21, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 21, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 21, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 21, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 21, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 21, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 21, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 21, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 21, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 21, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 53, y: 21, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 54, y: 21, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 55, y: 21, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 56, y: 21, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 57, y: 21, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 58, y: 21, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 59, y: 21, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 60, y: 21, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 61, y: 21, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 62, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 63, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 64, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 65, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 66, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 73, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 74, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 75, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 76, y: 21, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 77, y: 21, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 78, y: 21, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 79, y: 21, value: "D4", targetType: TargetTypeEnum.Double, score: 4 },
  { x: 5, y: 22, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 6, y: 22, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 7, y: 22, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 8, y: 22, value: "D9", targetType: TargetTypeEnum.Double, score: 9 },
  { x: 9, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 10, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 11, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 12, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 13, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 14, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 20, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 21, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 22, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 23, y: 22, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 24, y: 22, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 25, y: 22, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 26, y: 22, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 27, y: 22, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 28, y: 22, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 29, y: 22, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 30, y: 22, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 31, y: 22, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 32, y: 22, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 33, y: 22, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 34, y: 22, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 22, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 22, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 22, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 22, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 22, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 22, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 22, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 22, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 22, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 22, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 22, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 22, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 22, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 22, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 22, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 22, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 22, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 22, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 53, y: 22, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 54, y: 22, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 55, y: 22, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 56, y: 22, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 57, y: 22, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 58, y: 22, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 59, y: 22, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 60, y: 22, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 61, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 62, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 63, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 64, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 65, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 66, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 73, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 74, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 75, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 76, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 77, y: 22, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 78, y: 22, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 79, y: 22, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 5, y: 23, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 6, y: 23, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 7, y: 23, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 8, y: 23, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 10, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 11, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 12, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 13, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 14, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 20, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 21, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 22, y: 23, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 23, y: 23, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 24, y: 23, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 25, y: 23, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 26, y: 23, value: "T12", targetType: TargetTypeEnum.Triple, score: 12 },
  { x: 27, y: 23, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 23, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 29, y: 23, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 30, y: 23, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 31, y: 23, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 32, y: 23, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 33, y: 23, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 34, y: 23, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 35, y: 23, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 23, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 23, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 23, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 23, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 23, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 41, y: 23, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 23, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 23, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 23, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 23, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 46, y: 23, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 23, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 23, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 23, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 23, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 23, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 52, y: 23, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 53, y: 23, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 54, y: 23, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 55, y: 23, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 56, y: 23, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 57, y: 23, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 58, y: 23, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 59, y: 23, value: "T18", targetType: TargetTypeEnum.Triple, score: 18 },
  { x: 60, y: 23, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 61, y: 23, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 62, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 63, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 64, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 65, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 66, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 73, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 74, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 75, y: 23, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 76, y: 23, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 23, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 23, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 79, y: 23, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 80, y: 23, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 5, y: 24, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 6, y: 24, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 7, y: 24, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 8, y: 24, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 24, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 24, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 12, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 13, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 14, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 20, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 21, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 22, y: 24, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 23, y: 24, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 24, y: 24, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 25, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 26, y: 24, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 27, y: 24, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 24, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 29, y: 24, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 30, y: 24, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 31, y: 24, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 32, y: 24, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 33, y: 24, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 34, y: 24, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 35, y: 24, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 24, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 24, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 24, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 24, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 24, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 24, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 24, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 24, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 24, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 24, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 24, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 24, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 24, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 24, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 24, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 24, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 52, y: 24, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 53, y: 24, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 54, y: 24, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 55, y: 24, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 56, y: 24, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 57, y: 24, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 58, y: 24, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 59, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 60, y: 24, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 61, y: 24, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 62, y: 24, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 63, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 64, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 65, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 66, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 73, y: 24, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 74, y: 24, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 24, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 24, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 24, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 24, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 79, y: 24, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 80, y: 24, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 4, y: 25, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 5, y: 25, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 6, y: 25, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 7, y: 25, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 8, y: 25, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 25, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 25, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 25, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 12, y: 25, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 13, y: 25, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 25, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 15, y: 25, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 16, y: 25, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 25, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 25, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 25, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 20, y: 25, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 21, y: 25, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 22, y: 25, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 23, y: 25, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 24, y: 25, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 25, y: 25, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 26, y: 25, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 27, y: 25, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 28, y: 25, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 29, y: 25, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 30, y: 25, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 31, y: 25, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 32, y: 25, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 33, y: 25, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 34, y: 25, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 35, y: 25, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 36, y: 25, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 25, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 25, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 25, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 25, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 25, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 25, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 25, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 25, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 25, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 25, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 25, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 25, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 25, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 25, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 51, y: 25, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 52, y: 25, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 53, y: 25, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 54, y: 25, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 55, y: 25, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 56, y: 25, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 57, y: 25, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 58, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 59, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 60, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 61, y: 25, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 62, y: 25, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 63, y: 25, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 64, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 65, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 66, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 71, y: 25, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 72, y: 25, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 25, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 25, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 25, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 25, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 25, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 25, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 79, y: 25, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 80, y: 25, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 4, y: 26, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 5, y: 26, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 6, y: 26, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 7, y: 26, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 8, y: 26, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 26, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 26, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 26, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 12, y: 26, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 13, y: 26, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 26, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 15, y: 26, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 16, y: 26, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 17, y: 26, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 18, y: 26, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 26, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 20, y: 26, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 21, y: 26, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 22, y: 26, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 23, y: 26, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 24, y: 26, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 25, y: 26, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 26, y: 26, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 27, y: 26, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 28, y: 26, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 29, y: 26, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 30, y: 26, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 31, y: 26, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 32, y: 26, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 33, y: 26, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 34, y: 26, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 35, y: 26, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 36, y: 26, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 26, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 26, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 26, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 26, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 26, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 26, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 26, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 26, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 26, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 26, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 26, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 26, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 26, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 26, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 51, y: 26, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 52, y: 26, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 53, y: 26, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 54, y: 26, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 55, y: 26, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 56, y: 26, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 57, y: 26, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 58, y: 26, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 59, y: 26, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 60, y: 26, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 61, y: 26, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 62, y: 26, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 63, y: 26, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 64, y: 26, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 65, y: 26, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 66, y: 26, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 26, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 26, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 69, y: 26, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 70, y: 26, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 71, y: 26, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 72, y: 26, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 26, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 26, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 26, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 26, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 26, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 26, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 79, y: 26, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 80, y: 26, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 81, y: 26, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 4, y: 27, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 5, y: 27, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 6, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 7, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 8, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 12, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 13, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 15, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 16, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 17, y: 27, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 18, y: 27, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 19, y: 27, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 20, y: 27, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 21, y: 27, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 22, y: 27, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 23, y: 27, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 24, y: 27, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 25, y: 27, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 26, y: 27, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 27, y: 27, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 28, y: 27, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 29, y: 27, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 30, y: 27, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 31, y: 27, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 32, y: 27, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 33, y: 27, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 34, y: 27, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 35, y: 27, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 36, y: 27, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 37, y: 27, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 27, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 27, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 27, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 27, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 27, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 27, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 27, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 27, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 27, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 27, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 27, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 27, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 50, y: 27, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 51, y: 27, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 52, y: 27, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 53, y: 27, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 54, y: 27, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 55, y: 27, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 56, y: 27, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 57, y: 27, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 58, y: 27, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 59, y: 27, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 60, y: 27, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 61, y: 27, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 62, y: 27, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 63, y: 27, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 64, y: 27, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 65, y: 27, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 66, y: 27, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 67, y: 27, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 68, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 69, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 70, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 71, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 72, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 79, y: 27, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 80, y: 27, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 81, y: 27, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 3, y: 28, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 4, y: 28, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 5, y: 28, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 6, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 7, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 8, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 12, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 13, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 15, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 16, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 17, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 18, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 19, y: 28, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 20, y: 28, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 21, y: 28, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 22, y: 28, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 23, y: 28, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 24, y: 28, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 25, y: 28, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 26, y: 28, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 27, y: 28, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 28, y: 28, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 29, y: 28, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 30, y: 28, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 32, y: 28, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 33, y: 28, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 34, y: 28, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 35, y: 28, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 36, y: 28, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 37, y: 28, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 28, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 28, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 28, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 28, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 28, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 28, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 28, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 28, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 28, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 28, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 28, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 28, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 50, y: 28, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 51, y: 28, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 52, y: 28, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 53, y: 28, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 54, y: 28, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 55, y: 28, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 56, y: 28, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 57, y: 28, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 58, y: 28, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 59, y: 28, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 60, y: 28, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 61, y: 28, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 62, y: 28, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 63, y: 28, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 64, y: 28, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 65, y: 28, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 66, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 67, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 68, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 69, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 70, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 71, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 72, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 79, y: 28, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 80, y: 28, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 81, y: 28, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 3, y: 29, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 4, y: 29, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 5, y: 29, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 6, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 7, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 8, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 12, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 13, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 15, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 16, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 17, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 18, y: 29, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 19, y: 29, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 20, y: 29, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 21, y: 29, value: "T9", targetType: TargetTypeEnum.Triple, score: 9 },
  { x: 22, y: 29, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 23, y: 29, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 24, y: 29, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 25, y: 29, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 26, y: 29, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 27, y: 29, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 28, y: 29, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 29, y: 29, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 30, y: 29, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 31, y: 29, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 32, y: 29, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 33, y: 29, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 34, y: 29, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 35, y: 29, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 36, y: 29, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 37, y: 29, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 38, y: 29, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 29, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 29, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 29, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 42, y: 29, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 29, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 29, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 45, y: 29, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 29, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 29, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 29, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 49, y: 29, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 50, y: 29, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 51, y: 29, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 52, y: 29, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 53, y: 29, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 54, y: 29, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 55, y: 29, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 56, y: 29, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 57, y: 29, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 58, y: 29, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 59, y: 29, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 60, y: 29, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 61, y: 29, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 62, y: 29, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 63, y: 29, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 64, y: 29, value: "T4", targetType: TargetTypeEnum.Triple, score: 4 },
  { x: 65, y: 29, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 66, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 67, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 68, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 69, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 70, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 71, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 72, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 79, y: 29, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 80, y: 29, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 81, y: 29, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 82, y: 29, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 3, y: 30, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 4, y: 30, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 5, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 6, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 7, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 8, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 12, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 13, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 15, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 16, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 17, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 18, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 19, y: 30, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 20, y: 30, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 21, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 22, y: 30, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 23, y: 30, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 24, y: 30, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 25, y: 30, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 26, y: 30, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 27, y: 30, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 28, y: 30, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 29, y: 30, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 30, y: 30, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 31, y: 30, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 32, y: 30, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 33, y: 30, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 34, y: 30, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 35, y: 30, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 36, y: 30, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 37, y: 30, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 38, y: 30, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 30, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 30, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 30, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 42, y: 30, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 30, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 30, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 45, y: 30, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 30, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 30, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 48, y: 30, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 49, y: 30, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 50, y: 30, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 51, y: 30, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 52, y: 30, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 53, y: 30, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 54, y: 30, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 55, y: 30, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 56, y: 30, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 57, y: 30, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 58, y: 30, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 59, y: 30, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 60, y: 30, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 61, y: 30, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 62, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 63, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 64, y: 30, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 65, y: 30, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 66, y: 30, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 67, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 68, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 69, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 70, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 71, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 72, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 79, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 80, y: 30, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 81, y: 30, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 82, y: 30, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 2, y: 31, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 3, y: 31, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 4, y: 31, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 5, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 6, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 7, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 8, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 12, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 13, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 15, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 16, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 17, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 18, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 19, y: 31, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 20, y: 31, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 21, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 22, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 23, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 24, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 25, y: 31, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 26, y: 31, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 27, y: 31, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 28, y: 31, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 29, y: 31, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 30, y: 31, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 31, y: 31, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 32, y: 31, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 33, y: 31, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 34, y: 31, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 35, y: 31, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 36, y: 31, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 37, y: 31, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 38, y: 31, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 39, y: 31, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 31, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 31, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 42, y: 31, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 31, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 31, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 45, y: 31, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 31, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 31, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 48, y: 31, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 49, y: 31, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 50, y: 31, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 51, y: 31, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 52, y: 31, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 53, y: 31, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 54, y: 31, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 55, y: 31, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 56, y: 31, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 57, y: 31, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 58, y: 31, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 59, y: 31, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 61, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 62, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 63, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 64, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 65, y: 31, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 66, y: 31, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 67, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 68, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 69, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 70, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 71, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 72, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 79, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 80, y: 31, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 81, y: 31, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 82, y: 31, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 2, y: 32, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 3, y: 32, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 4, y: 32, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 5, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 6, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 7, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 8, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 12, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 13, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 15, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 16, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 17, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 18, y: 32, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 19, y: 32, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 20, y: 32, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 21, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 22, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 23, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 24, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 25, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 26, y: 32, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 27, y: 32, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 28, y: 32, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 29, y: 32, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 30, y: 32, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 31, y: 32, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 32, y: 32, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 33, y: 32, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 34, y: 32, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 35, y: 32, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 36, y: 32, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 37, y: 32, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 38, y: 32, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 39, y: 32, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 40, y: 32, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 32, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 42, y: 32, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 32, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 32, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 45, y: 32, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 32, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 47, y: 32, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 48, y: 32, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 49, y: 32, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 50, y: 32, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 51, y: 32, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 52, y: 32, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 53, y: 32, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 54, y: 32, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 55, y: 32, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 56, y: 32, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 57, y: 32, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 58, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 59, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 60, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 61, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 62, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 63, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 64, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 65, y: 32, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 66, y: 32, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 67, y: 32, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 68, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 69, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 70, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 71, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 72, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 79, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 80, y: 32, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 81, y: 32, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 82, y: 32, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 83, y: 32, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 2, y: 33, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 33, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 4, y: 33, value: "D14", targetType: TargetTypeEnum.Double, score: 14 },
  { x: 5, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 6, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 7, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 8, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 12, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 13, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 15, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 16, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 17, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 18, y: 33, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 19, y: 33, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 20, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 21, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 22, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 23, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 24, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 25, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 26, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 27, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 28, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 29, y: 33, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 30, y: 33, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 31, y: 33, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 32, y: 33, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 33, y: 33, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 34, y: 33, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 35, y: 33, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 37, y: 33, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 38, y: 33, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 39, y: 33, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 40, y: 33, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 33, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 42, y: 33, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 33, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 33, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 45, y: 33, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 33, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 47, y: 33, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 48, y: 33, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 49, y: 33, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 50, y: 33, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 51, y: 33, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 52, y: 33, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 53, y: 33, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 54, y: 33, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 55, y: 33, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 56, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 57, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 58, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 59, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 60, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 61, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 62, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 63, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 64, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 65, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 66, y: 33, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 67, y: 33, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 68, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 69, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 70, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 71, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 72, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 79, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 80, y: 33, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 81, y: 33, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 82, y: 33, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 83, y: 33, value: "D13", targetType: TargetTypeEnum.Double, score: 13 },
  { x: 2, y: 34, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 34, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 34, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 34, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 34, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 8, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 9, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 10, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 11, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 12, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 13, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 15, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 16, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 17, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 18, y: 34, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 19, y: 34, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 20, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 21, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 22, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 23, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 24, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 25, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 26, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 27, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 28, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 29, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 30, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 31, y: 34, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 32, y: 34, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 33, y: 34, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 34, y: 34, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 35, y: 34, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 36, y: 34, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 37, y: 34, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 38, y: 34, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 39, y: 34, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 40, y: 34, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 41, y: 34, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 42, y: 34, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 34, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 34, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 45, y: 34, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 46, y: 34, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 47, y: 34, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 48, y: 34, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 49, y: 34, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 50, y: 34, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 51, y: 34, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 52, y: 34, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 53, y: 34, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 54, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 55, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 56, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 57, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 58, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 59, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 60, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 61, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 62, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 63, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 64, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 65, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 66, y: 34, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 67, y: 34, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 68, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 69, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 70, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 71, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 72, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 75, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 76, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 77, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 78, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 79, y: 34, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 80, y: 34, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 34, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 34, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 34, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 2, y: 35, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 35, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 35, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 35, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 35, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 35, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 8, y: 35, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 9, y: 35, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 10, y: 35, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 11, y: 35, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 12, y: 35, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 13, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 14, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 15, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 16, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 17, y: 35, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 18, y: 35, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 19, y: 35, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 20, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 21, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 22, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 23, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 24, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 25, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 26, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 27, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 28, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 29, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 30, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 31, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 32, y: 35, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 33, y: 35, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 34, y: 35, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 35, y: 35, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 36, y: 35, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 37, y: 35, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 38, y: 35, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 39, y: 35, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 40, y: 35, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 41, y: 35, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 42, y: 35, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 43, y: 35, value: "20", targetType: TargetTypeEnum.Single, score: 20 },
  { x: 44, y: 35, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 45, y: 35, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 46, y: 35, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 47, y: 35, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 48, y: 35, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 49, y: 35, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 50, y: 35, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 51, y: 35, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 52, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 53, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 54, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 55, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 56, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 57, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 58, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 59, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 60, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 61, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 62, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 63, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 64, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 65, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 66, y: 35, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 67, y: 35, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 68, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 69, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 70, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 71, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 72, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 73, y: 35, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 74, y: 35, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 75, y: 35, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 76, y: 35, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 77, y: 35, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 78, y: 35, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 79, y: 35, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 80, y: 35, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 35, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 35, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 35, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 1, y: 36, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 2, y: 36, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 36, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 8, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 9, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 10, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 11, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 12, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 13, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 14, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 15, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 16, y: 36, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 17, y: 36, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 18, y: 36, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 19, y: 36, value: "T14", targetType: TargetTypeEnum.Triple, score: 14 },
  { x: 20, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 21, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 22, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 23, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 24, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 25, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 26, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 27, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 28, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 29, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 30, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 31, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 32, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 33, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 34, y: 36, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 35, y: 36, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 36, y: 36, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 37, y: 36, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 38, y: 36, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 39, y: 36, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 40, y: 36, value: "12", targetType: TargetTypeEnum.Single, score: 12 },
  { x: 41, y: 36, value: "5", targetType: TargetTypeEnum.Single, score: 5 },
  { x: 42, y: 36, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 43, y: 36, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 44, y: 36, value: "1", targetType: TargetTypeEnum.Single, score: 1 },
  { x: 45, y: 36, value: "18", targetType: TargetTypeEnum.Single, score: 18 },
  { x: 46, y: 36, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 47, y: 36, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 48, y: 36, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 49, y: 36, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 50, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 51, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 52, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 53, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 54, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 55, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 56, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 57, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 58, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 59, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 60, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 61, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 62, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 63, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 64, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 65, y: 36, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 66, y: 36, value: "T13", targetType: TargetTypeEnum.Triple, score: 13 },
  { x: 67, y: 36, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 68, y: 36, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 69, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 70, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 71, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 72, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 73, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 74, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 75, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 76, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 77, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 78, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 79, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 80, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 36, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 36, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 36, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 84, y: 36, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 1, y: 37, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 2, y: 37, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 37, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 8, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 9, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 10, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 11, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 12, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 13, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 14, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 15, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 16, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 17, y: 37, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 18, y: 37, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 19, y: 37, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 20, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 21, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 22, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 23, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 24, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 25, y: 37, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 26, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 27, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 28, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 29, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 30, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 31, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 32, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 33, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 34, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 35, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 36, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 37, y: 37, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 38, y: 37, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 39, y: 37, value: "9", targetType: TargetTypeEnum.Single, score: 9 },
  { x: 40, y: 37, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 41, y: 37, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 42, y: 37, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 43, y: 37, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 44, y: 37, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 45, y: 37, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 46, y: 37, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 47, y: 37, value: "4", targetType: TargetTypeEnum.Single, score: 4 },
  { x: 48, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 49, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 50, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 51, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 52, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 53, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 54, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 55, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 56, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 57, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 58, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 59, y: 37, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 60, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 61, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 62, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 63, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 64, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 65, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 66, y: 37, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 67, y: 37, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 68, y: 37, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 69, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 70, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 71, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 72, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 73, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 74, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 75, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 76, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 77, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 78, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 79, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 80, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 37, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 37, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 37, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 84, y: 37, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 1, y: 38, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 2, y: 38, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 38, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 8, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 9, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 10, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 11, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 12, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 13, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 14, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 15, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 16, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 17, y: 38, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 18, y: 38, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 19, y: 38, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 20, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 21, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 22, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 23, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 24, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 25, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 26, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 27, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 28, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 29, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 30, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 31, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 32, y: 38, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 33, y: 38, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 34, y: 38, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 35, y: 38, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 36, y: 38, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 37, y: 38, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 38, y: 38, value: "14", targetType: TargetTypeEnum.Single, score: 14 },
  { x: 39, y: 38, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 40, y: 38, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 41, y: 38, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 42, y: 38, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 43, y: 38, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 44, y: 38, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 45, y: 38, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 46, y: 38, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 47, y: 38, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 48, y: 38, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 49, y: 38, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 50, y: 38, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 51, y: 38, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 52, y: 38, value: "13", targetType: TargetTypeEnum.Single, score: 13 },
  { x: 53, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 54, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 55, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 56, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 57, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 58, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 59, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 60, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 61, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 62, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 63, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 64, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 65, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 66, y: 38, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 67, y: 38, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 68, y: 38, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 69, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 70, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 71, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 72, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 73, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 74, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 75, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 76, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 77, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 78, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 79, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 80, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 38, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 38, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 38, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 84, y: 38, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 1, y: 39, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 2, y: 39, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 39, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 8, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 9, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 10, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 11, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 12, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 13, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 14, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 15, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 16, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 17, y: 39, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 18, y: 39, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 19, y: 39, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 20, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 21, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 22, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 23, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 24, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 25, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 26, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 27, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 28, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 29, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 30, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 31, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 32, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 33, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 34, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 35, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 36, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 37, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 38, y: 39, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 39, y: 39, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 40, y: 39, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 41, y: 39, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 42, y: 39, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 43, y: 39, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 44, y: 39, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 45, y: 39, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 46, y: 39, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 47, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 48, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 49, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 50, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 51, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 52, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 53, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 54, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 55, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 56, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 57, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 58, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 59, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 60, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 61, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 62, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 63, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 64, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 65, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 66, y: 39, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 67, y: 39, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 68, y: 39, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 69, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 70, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 71, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 72, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 73, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 74, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 75, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 76, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 77, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 78, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 79, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 80, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 39, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 39, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 39, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 84, y: 39, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 1, y: 40, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 2, y: 40, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 40, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 8, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 9, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 10, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 11, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 12, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 13, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 14, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 15, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 16, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 17, y: 40, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 18, y: 40, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 19, y: 40, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 20, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 21, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 22, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 23, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 24, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 25, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 26, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 27, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 28, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 29, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 30, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 31, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 32, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 33, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 34, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 35, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 36, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 37, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 38, y: 40, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 39, y: 40, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 40, y: 40, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 41, y: 40, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 42, y: 40, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 43, y: 40, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 44, y: 40, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 45, y: 40, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 46, y: 40, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 47, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 48, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 49, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 50, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 51, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 52, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 53, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 54, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 55, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 56, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 57, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 58, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 59, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 60, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 61, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 62, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 63, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 64, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 65, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 66, y: 40, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 67, y: 40, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 68, y: 40, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 69, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 70, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 71, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 72, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 73, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 74, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 75, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 76, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 77, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 78, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 79, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 80, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 40, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 40, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 40, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 84, y: 40, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 1, y: 41, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 2, y: 41, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 41, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 8, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 9, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 10, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 11, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 12, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 13, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 14, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 15, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 16, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 17, y: 41, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 18, y: 41, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 19, y: 41, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 20, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 21, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 22, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 23, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 24, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 25, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 26, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 27, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 28, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 29, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 30, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 31, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 32, y: 41, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 33, y: 41, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 34, y: 41, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 35, y: 41, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 36, y: 41, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 37, y: 41, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 38, y: 41, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 39, y: 41, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 40, y: 41, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 41, y: 41, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 42, y: 41, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 43, y: 41, value: "DBULL", targetType: TargetTypeEnum.Double, score: 50 },
  { x: 44, y: 41, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 45, y: 41, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 46, y: 41, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 47, y: 41, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 48, y: 41, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 49, y: 41, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 50, y: 41, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 51, y: 41, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 52, y: 41, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 53, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 54, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 55, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 56, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 57, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 58, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 59, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 60, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 61, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 62, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 63, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 64, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 65, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 66, y: 41, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 67, y: 41, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 68, y: 41, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 69, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 70, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 71, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 72, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 73, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 74, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 75, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 76, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 77, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 78, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 79, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 80, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 41, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 41, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 41, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 84, y: 41, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 1, y: 42, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 2, y: 42, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 42, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 8, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 9, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 10, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 11, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 12, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 13, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 14, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 15, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 16, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 17, y: 42, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 18, y: 42, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 19, y: 42, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 20, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 21, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 22, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 23, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 24, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 25, y: 42, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 26, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 27, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 28, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 29, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 30, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 31, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 32, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 33, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 34, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 35, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 36, y: 42, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 37, y: 42, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 38, y: 42, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 39, y: 42, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 40, y: 42, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 41, y: 42, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 42, y: 42, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 43, y: 42, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 44, y: 42, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 45, y: 42, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 46, y: 42, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 47, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 48, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 49, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 50, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 51, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 52, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 53, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 54, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 55, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 56, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 57, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 58, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 59, y: 42, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 60, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 61, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 62, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 63, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 64, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 65, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 66, y: 42, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 67, y: 42, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 68, y: 42, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 69, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 70, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 71, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 72, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 73, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 74, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 75, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 76, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 77, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 78, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 79, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 80, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 42, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 42, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 42, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 84, y: 42, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 2, y: 43, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 43, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 8, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 9, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 10, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 11, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 12, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 13, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 14, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 15, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 16, y: 43, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 17, y: 43, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 18, y: 43, value: "T11", targetType: TargetTypeEnum.Triple, score: 11 },
  { x: 19, y: 43, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 20, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 21, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 22, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 23, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 24, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 25, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 26, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 27, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 28, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 29, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 30, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 31, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 32, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 33, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 34, y: 43, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 35, y: 43, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 36, y: 43, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 37, y: 43, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 38, y: 43, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 39, y: 43, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 40, y: 43, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 41, y: 43, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 42, y: 43, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 43, y: 43, value: "BULL", targetType: TargetTypeEnum.Single, score: 25 },
  { x: 44, y: 43, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 45, y: 43, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 46, y: 43, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 47, y: 43, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 48, y: 43, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 49, y: 43, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 50, y: 43, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 51, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 52, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 53, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 54, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 55, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 56, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 57, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 58, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 59, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 60, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 61, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 62, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 63, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 64, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 65, y: 43, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 66, y: 43, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 67, y: 43, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 68, y: 43, value: "T6", targetType: TargetTypeEnum.Triple, score: 6 },
  { x: 69, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 70, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 71, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 72, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 73, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 74, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 75, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 76, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 77, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 78, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 79, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 80, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 43, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 43, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 43, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 2, y: 44, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 44, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 4, y: 44, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 5, y: 44, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 6, y: 44, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 7, y: 44, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 8, y: 44, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 9, y: 44, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 10, y: 44, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 11, y: 44, value: "11", targetType: TargetTypeEnum.Single, score: 11 },
  { x: 12, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 14, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 15, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 16, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 17, y: 44, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 18, y: 44, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 19, y: 44, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 20, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 21, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 22, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 23, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 24, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 25, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 26, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 27, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 28, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 29, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 30, y: 44, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 33, y: 44, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 34, y: 44, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 35, y: 44, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 36, y: 44, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 37, y: 44, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 38, y: 44, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 39, y: 44, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 40, y: 44, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 41, y: 44, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 42, y: 44, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 44, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 44, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 45, y: 44, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 46, y: 44, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 47, y: 44, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 48, y: 44, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 49, y: 44, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 50, y: 44, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 51, y: 44, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 52, y: 44, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 53, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 54, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 55, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 56, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 57, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 58, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 59, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 60, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 61, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 62, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 63, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 64, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 65, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 66, y: 44, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 67, y: 44, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 68, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 69, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 70, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 71, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 72, y: 44, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 73, y: 44, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 74, y: 44, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 75, y: 44, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 76, y: 44, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 77, y: 44, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 78, y: 44, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 79, y: 44, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 80, y: 44, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 81, y: 44, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 44, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 44, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 2, y: 45, value: "D11", targetType: TargetTypeEnum.Double, score: 11 },
  { x: 3, y: 45, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 4, y: 45, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 5, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 6, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 7, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 8, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 12, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 14, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 15, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 16, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 17, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 18, y: 45, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 19, y: 45, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 20, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 21, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 22, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 23, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 24, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 25, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 26, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 27, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 28, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 29, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 30, y: 45, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 31, y: 45, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 32, y: 45, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 33, y: 45, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 34, y: 45, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 35, y: 45, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 36, y: 45, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 37, y: 45, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 38, y: 45, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 39, y: 45, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 40, y: 45, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 41, y: 45, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 42, y: 45, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 45, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 45, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 45, y: 45, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 46, y: 45, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 47, y: 45, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 48, y: 45, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 49, y: 45, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 50, y: 45, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 51, y: 45, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 52, y: 45, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 53, y: 45, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 54, y: 45, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 55, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 56, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 57, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 58, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 59, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 60, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 61, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 62, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 63, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 64, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 65, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 66, y: 45, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 67, y: 45, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 68, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 69, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 70, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 71, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 72, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 73, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 74, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 75, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 79, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 80, y: 45, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 81, y: 45, value: "6", targetType: TargetTypeEnum.Single, score: 6 },
  { x: 82, y: 45, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 83, y: 45, value: "D6", targetType: TargetTypeEnum.Double, score: 6 },
  { x: 2, y: 46, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 3, y: 46, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 4, y: 46, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 5, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 6, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 7, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 8, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 12, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 14, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 15, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 16, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 17, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 18, y: 46, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 19, y: 46, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 20, y: 46, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 21, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 22, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 23, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 24, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 25, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 26, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 27, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 28, y: 46, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 29, y: 46, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 30, y: 46, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 31, y: 46, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 32, y: 46, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 33, y: 46, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 34, y: 46, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 35, y: 46, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 36, y: 46, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 37, y: 46, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 38, y: 46, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 39, y: 46, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 40, y: 46, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 41, y: 46, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 42, y: 46, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 46, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 46, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 45, y: 46, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 46, y: 46, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 47, y: 46, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 48, y: 46, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 49, y: 46, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 50, y: 46, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 51, y: 46, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 52, y: 46, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 53, y: 46, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 54, y: 46, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 55, y: 46, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 56, y: 46, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 57, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 58, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 59, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 60, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 61, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 62, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 63, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 64, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 65, y: 46, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 66, y: 46, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 67, y: 46, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 68, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 69, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 70, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 71, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 72, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 73, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 74, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 75, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 79, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 80, y: 46, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 81, y: 46, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 82, y: 46, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 83, y: 46, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 2, y: 47, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 3, y: 47, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 4, y: 47, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 5, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 6, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 7, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 8, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 12, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 14, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 15, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 16, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 17, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 18, y: 47, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 19, y: 47, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 20, y: 47, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 21, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 22, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 23, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 24, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 25, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 26, y: 47, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 27, y: 47, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 28, y: 47, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 29, y: 47, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 30, y: 47, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 31, y: 47, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 32, y: 47, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 33, y: 47, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 34, y: 47, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 35, y: 47, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 36, y: 47, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 37, y: 47, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 38, y: 47, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 39, y: 47, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 47, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 41, y: 47, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 42, y: 47, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 47, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 47, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 45, y: 47, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 46, y: 47, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 47, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 48, y: 47, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 49, y: 47, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 50, y: 47, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 51, y: 47, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 52, y: 47, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 53, y: 47, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 54, y: 47, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 55, y: 47, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 56, y: 47, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 57, y: 47, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 58, y: 47, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 59, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 60, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 61, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 62, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 63, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 64, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 65, y: 47, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 66, y: 47, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 67, y: 47, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 68, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 69, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 70, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 71, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 72, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 73, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 74, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 75, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 79, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 80, y: 47, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 81, y: 47, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 82, y: 47, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 83, y: 47, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 2, y: 48, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 3, y: 48, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 4, y: 48, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 5, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 6, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 7, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 8, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 12, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 14, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 15, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 16, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 17, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 18, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 19, y: 48, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 20, y: 48, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 21, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 22, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 23, y: 48, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 24, y: 48, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 25, y: 48, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 26, y: 48, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 27, y: 48, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 28, y: 48, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 29, y: 48, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 30, y: 48, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 31, y: 48, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 32, y: 48, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 33, y: 48, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 34, y: 48, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 35, y: 48, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 36, y: 48, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 37, y: 48, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 38, y: 48, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 48, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 48, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 41, y: 48, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 42, y: 48, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 48, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 48, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 45, y: 48, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 46, y: 48, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 48, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 48, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 49, y: 48, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 50, y: 48, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 51, y: 48, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 52, y: 48, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 53, y: 48, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 54, y: 48, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 55, y: 48, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 56, y: 48, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 57, y: 48, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 58, y: 48, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 59, y: 48, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 60, y: 48, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 61, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 62, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 63, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 64, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 65, y: 48, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 66, y: 48, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 67, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 68, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 69, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 70, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 71, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 72, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 73, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 74, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 75, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 79, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 80, y: 48, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 81, y: 48, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 82, y: 48, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 3, y: 49, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 4, y: 49, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 5, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 6, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 7, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 8, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 12, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 14, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 15, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 16, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 17, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 18, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 19, y: 49, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 20, y: 49, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 21, y: 49, value: "T8", targetType: TargetTypeEnum.Triple, score: 8 },
  { x: 22, y: 49, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 23, y: 49, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 24, y: 49, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 25, y: 49, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 26, y: 49, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 27, y: 49, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 28, y: 49, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 29, y: 49, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 30, y: 49, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 31, y: 49, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 32, y: 49, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 33, y: 49, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 34, y: 49, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 35, y: 49, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 36, y: 49, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 37, y: 49, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 38, y: 49, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 49, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 49, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 41, y: 49, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 49, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 49, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 49, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 49, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 46, y: 49, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 49, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 49, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 49, y: 49, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 50, y: 49, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 51, y: 49, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 52, y: 49, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 53, y: 49, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 54, y: 49, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 55, y: 49, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 56, y: 49, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 57, y: 49, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 58, y: 49, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 59, y: 49, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 60, y: 49, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 61, y: 49, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 62, y: 49, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 63, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 64, y: 49, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 65, y: 49, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 66, y: 49, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 67, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 68, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 69, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 70, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 71, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 72, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 73, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 74, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 75, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 79, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 80, y: 49, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 81, y: 49, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 82, y: 49, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 3, y: 50, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 4, y: 50, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 5, y: 50, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 6, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 7, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 8, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 12, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 14, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 15, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 16, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 17, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 18, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 19, y: 50, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 20, y: 50, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 21, y: 50, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 22, y: 50, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 23, y: 50, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 24, y: 50, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 25, y: 50, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 26, y: 50, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 27, y: 50, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 28, y: 50, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 29, y: 50, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 30, y: 50, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 31, y: 50, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 32, y: 50, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 33, y: 50, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 34, y: 50, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 35, y: 50, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 36, y: 50, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 37, y: 50, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 50, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 50, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 50, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 41, y: 50, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 50, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 50, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 50, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 50, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 46, y: 50, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 50, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 50, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 50, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 50, y: 50, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 51, y: 50, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 52, y: 50, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 53, y: 50, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 54, y: 50, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 55, y: 50, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 56, y: 50, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 57, y: 50, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 58, y: 50, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 59, y: 50, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 60, y: 50, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 61, y: 50, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 62, y: 50, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 63, y: 50, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 64, y: 50, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 65, y: 50, value: "T10", targetType: TargetTypeEnum.Triple, score: 10 },
  { x: 66, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 67, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 68, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 69, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 70, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 71, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 72, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 73, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 74, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 75, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 79, y: 50, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 80, y: 50, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 81, y: 50, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 82, y: 50, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 3, y: 51, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 4, y: 51, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 5, y: 51, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 6, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 7, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 8, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 12, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 14, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 15, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 16, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 17, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 18, y: 51, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 19, y: 51, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 51, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 21, y: 51, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 22, y: 51, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 23, y: 51, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 24, y: 51, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 25, y: 51, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 26, y: 51, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 27, y: 51, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 28, y: 51, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 29, y: 51, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 30, y: 51, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 31, y: 51, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 32, y: 51, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 33, y: 51, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 34, y: 51, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 35, y: 51, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 36, y: 51, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 37, y: 51, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 51, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 51, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 51, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 41, y: 51, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 51, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 51, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 51, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 51, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 46, y: 51, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 51, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 51, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 51, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 50, y: 51, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 51, y: 51, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 52, y: 51, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 53, y: 51, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 54, y: 51, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 55, y: 51, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 56, y: 51, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 57, y: 51, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 58, y: 51, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 59, y: 51, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 60, y: 51, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 61, y: 51, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 62, y: 51, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 63, y: 51, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 64, y: 51, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 65, y: 51, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 66, y: 51, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 68, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 69, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 70, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 71, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 72, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 73, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 74, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 75, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 79, y: 51, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 80, y: 51, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 81, y: 51, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 82, y: 51, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 3, y: 52, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 4, y: 52, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 5, y: 52, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 6, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 7, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 8, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 12, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 14, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 15, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 16, y: 52, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 17, y: 52, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 52, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 52, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 52, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 21, y: 52, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 22, y: 52, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 23, y: 52, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 24, y: 52, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 25, y: 52, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 26, y: 52, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 27, y: 52, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 28, y: 52, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 29, y: 52, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 30, y: 52, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 31, y: 52, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 32, y: 52, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 33, y: 52, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 34, y: 52, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 35, y: 52, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 36, y: 52, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 52, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 52, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 52, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 52, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 41, y: 52, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 52, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 52, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 52, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 52, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 46, y: 52, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 52, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 52, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 52, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 52, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 51, y: 52, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 52, y: 52, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 53, y: 52, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 54, y: 52, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 55, y: 52, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 56, y: 52, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 57, y: 52, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 58, y: 52, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 59, y: 52, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 60, y: 52, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 61, y: 52, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 62, y: 52, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 63, y: 52, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 64, y: 52, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 65, y: 52, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 66, y: 52, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 52, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 52, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 70, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 71, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 72, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 73, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 74, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 75, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 79, y: 52, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 80, y: 52, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 81, y: 52, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 4, y: 53, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 5, y: 53, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 6, y: 53, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 7, y: 53, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 8, y: 53, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 53, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 53, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 53, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 12, y: 53, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 53, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 14, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 21, y: 53, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 22, y: 53, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 23, y: 53, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 24, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 25, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 26, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 27, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 28, y: 53, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 29, y: 53, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 30, y: 53, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 31, y: 53, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 32, y: 53, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 33, y: 53, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 34, y: 53, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 35, y: 53, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 36, y: 53, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 53, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 53, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 53, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 53, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 41, y: 53, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 53, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 53, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 53, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 53, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 46, y: 53, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 53, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 53, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 53, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 53, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 53, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 52, y: 53, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 53, y: 53, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 54, y: 53, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 55, y: 53, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 56, y: 53, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 57, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 58, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 59, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 60, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 61, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 62, y: 53, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 63, y: 53, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 64, y: 53, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 65, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 66, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 53, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 53, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 72, y: 53, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 73, y: 53, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 74, y: 53, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 75, y: 53, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 53, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 53, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 53, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 79, y: 53, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 80, y: 53, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 81, y: 53, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 4, y: 54, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 5, y: 54, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 6, y: 54, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 7, y: 54, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 8, y: 54, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 54, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 54, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 54, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 12, y: 54, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 13, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 14, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 21, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 22, y: 54, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 23, y: 54, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 24, y: 54, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 25, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 26, y: 54, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 27, y: 54, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 54, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 29, y: 54, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 30, y: 54, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 31, y: 54, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 32, y: 54, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 33, y: 54, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 34, y: 54, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 35, y: 54, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 54, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 54, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 54, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 54, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 54, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 41, y: 54, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 54, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 54, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 54, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 54, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 46, y: 54, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 54, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 54, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 54, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 54, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 54, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 52, y: 54, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 53, y: 54, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 54, y: 54, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 55, y: 54, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 56, y: 54, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 57, y: 54, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 58, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 59, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 60, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 61, y: 54, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 62, y: 54, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 63, y: 54, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 64, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 65, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 66, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 54, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 73, y: 54, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 74, y: 54, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 75, y: 54, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 54, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 54, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 54, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 79, y: 54, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 80, y: 54, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 5, y: 55, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 6, y: 55, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 7, y: 55, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 8, y: 55, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 55, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 10, y: 55, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 11, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 12, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 13, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 14, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 21, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 22, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 23, y: 55, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 24, y: 55, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 25, y: 55, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 26, y: 55, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 55, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 55, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 29, y: 55, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 30, y: 55, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 31, y: 55, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 32, y: 55, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 33, y: 55, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 34, y: 55, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 35, y: 55, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 55, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 55, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 55, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 55, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 55, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 55, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 55, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 55, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 55, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 55, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 55, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 55, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 55, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 55, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 55, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 55, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 55, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 53, y: 55, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 54, y: 55, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 55, y: 55, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 56, y: 55, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 57, y: 55, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 58, y: 55, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 59, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 60, y: 55, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 61, y: 55, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 62, y: 55, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 63, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 64, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 65, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 66, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 73, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 74, y: 55, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 75, y: 55, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 76, y: 55, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 77, y: 55, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 55, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 79, y: 55, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 80, y: 55, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 5, y: 56, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 6, y: 56, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 7, y: 56, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 8, y: 56, value: "8", targetType: TargetTypeEnum.Single, score: 8 },
  { x: 9, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 10, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 11, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 12, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 13, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 14, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 21, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 22, y: 56, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 23, y: 56, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 24, y: 56, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 25, y: 56, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 26, y: 56, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 27, y: 56, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 56, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 29, y: 56, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 30, y: 56, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 31, y: 56, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 32, y: 56, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 33, y: 56, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 34, y: 56, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 56, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 56, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 56, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 56, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 56, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 56, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 56, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 56, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 56, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 56, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 56, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 56, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 56, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 56, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 56, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 56, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 56, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 56, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 53, y: 56, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 54, y: 56, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 55, y: 56, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 56, y: 56, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 57, y: 56, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 58, y: 56, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 59, y: 56, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 60, y: 56, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 61, y: 56, value: "T15", targetType: TargetTypeEnum.Triple, score: 15 },
  { x: 62, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 63, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 64, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 65, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 66, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 73, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 74, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 75, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 76, y: 56, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 77, y: 56, value: "10", targetType: TargetTypeEnum.Single, score: 10 },
  { x: 78, y: 56, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 79, y: 56, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 80, y: 56, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 5, y: 57, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 6, y: 57, value: "D8", targetType: TargetTypeEnum.Double, score: 8 },
  { x: 7, y: 57, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 8, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 9, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 10, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 11, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 12, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 13, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 14, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 21, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 22, y: 57, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 23, y: 57, value: "T16", targetType: TargetTypeEnum.Triple, score: 16 },
  { x: 24, y: 57, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 25, y: 57, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 26, y: 57, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 27, y: 57, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 28, y: 57, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 29, y: 57, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 30, y: 57, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 31, y: 57, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 32, y: 57, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 33, y: 57, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 57, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 57, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 57, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 57, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 57, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 57, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 57, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 57, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 57, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 57, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 57, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 57, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 57, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 57, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 57, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 57, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 57, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 57, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 57, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 57, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 54, y: 57, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 55, y: 57, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 56, y: 57, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 57, y: 57, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 58, y: 57, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 59, y: 57, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 60, y: 57, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 61, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 62, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 63, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 64, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 65, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 66, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 73, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 74, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 75, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 76, y: 57, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 77, y: 57, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 78, y: 57, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 79, y: 57, value: "D10", targetType: TargetTypeEnum.Double, score: 10 },
  { x: 6, y: 58, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 7, y: 58, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 8, y: 58, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 9, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 10, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 11, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 12, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 13, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 14, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 21, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 22, y: 58, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 23, y: 58, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 58, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 58, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 26, y: 58, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 27, y: 58, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 28, y: 58, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 29, y: 58, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 30, y: 58, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 31, y: 58, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 32, y: 58, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 33, y: 58, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 58, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 58, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 58, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 58, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 58, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 58, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 58, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 58, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 58, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 58, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 58, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 58, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 58, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 58, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 58, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 58, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 58, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 58, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 58, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 58, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 54, y: 58, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 55, y: 58, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 56, y: 58, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 57, y: 58, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 58, y: 58, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 59, y: 58, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 60, y: 58, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 61, y: 58, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 63, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 64, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 65, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 66, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 73, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 74, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 75, y: 58, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 76, y: 58, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 77, y: 58, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 78, y: 58, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 6, y: 59, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 7, y: 59, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 8, y: 59, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 9, y: 59, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 10, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 11, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 12, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 13, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 14, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 21, y: 59, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 22, y: 59, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 59, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 59, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 59, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 59, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 59, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 28, y: 59, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 29, y: 59, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 30, y: 59, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 31, y: 59, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 32, y: 59, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 59, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 59, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 59, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 59, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 59, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 59, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 59, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 40, y: 59, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 59, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 59, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 59, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 59, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 59, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 59, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 47, y: 59, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 59, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 59, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 59, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 59, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 59, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 59, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 54, y: 59, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 55, y: 59, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 56, y: 59, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 57, y: 59, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 58, y: 59, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 59, y: 59, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 59, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 59, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 59, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 64, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 65, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 66, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 73, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 74, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 75, y: 59, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 76, y: 59, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 77, y: 59, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 78, y: 59, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 7, y: 60, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 8, y: 60, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 9, y: 60, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 10, y: 60, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 11, y: 60, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 12, y: 60, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 13, y: 60, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 14, y: 60, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 60, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 60, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 60, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 60, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 60, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 60, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 21, y: 60, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 22, y: 60, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 60, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 60, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 60, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 60, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 60, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 60, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 29, y: 60, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 30, y: 60, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 31, y: 60, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 32, y: 60, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 33, y: 60, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 34, y: 60, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 35, y: 60, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 60, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 60, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 60, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 60, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 60, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 60, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 60, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 60, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 60, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 60, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 60, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 60, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 60, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 60, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 60, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 60, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 52, y: 60, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 53, y: 60, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 54, y: 60, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 55, y: 60, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 56, y: 60, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 57, y: 60, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 58, y: 60, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 59, y: 60, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 60, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 60, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 60, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 60, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 65, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 66, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 73, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 74, y: 60, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 75, y: 60, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 76, y: 60, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 77, y: 60, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 8, y: 61, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 9, y: 61, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 10, y: 61, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 11, y: 61, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 12, y: 61, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 13, y: 61, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 14, y: 61, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 61, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 61, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 61, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 61, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 61, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 20, y: 61, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 21, y: 61, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 22, y: 61, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 61, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 61, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 61, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 61, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 61, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 61, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 29, y: 61, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 30, y: 61, value: "T7", targetType: TargetTypeEnum.Triple, score: 7 },
  { x: 31, y: 61, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 32, y: 61, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 33, y: 61, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 34, y: 61, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 35, y: 61, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 36, y: 61, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 37, y: 61, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 61, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 61, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 61, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 61, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 61, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 61, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 61, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 61, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 61, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 61, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 61, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 61, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 50, y: 61, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 51, y: 61, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 52, y: 61, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 53, y: 61, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 54, y: 61, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 55, y: 61, value: "T2", targetType: TargetTypeEnum.Triple, score: 2 },
  { x: 56, y: 61, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 57, y: 61, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 58, y: 61, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 59, y: 61, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 61, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 61, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 61, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 61, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 61, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 65, y: 61, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 66, y: 61, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 61, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 61, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 61, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 61, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 61, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 61, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 73, y: 61, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 74, y: 61, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 75, y: 61, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 76, y: 61, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 9, y: 62, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 10, y: 62, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 11, y: 62, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 12, y: 62, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 13, y: 62, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 14, y: 62, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 62, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 62, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 62, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 62, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 19, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 20, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 21, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 22, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 29, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 30, y: 62, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 31, y: 62, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 62, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 62, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 34, y: 62, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 35, y: 62, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 36, y: 62, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 37, y: 62, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 38, y: 62, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 39, y: 62, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 40, y: 62, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 41, y: 62, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 42, y: 62, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 43, y: 62, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 44, y: 62, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 45, y: 62, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 46, y: 62, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 47, y: 62, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 48, y: 62, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 49, y: 62, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 50, y: 62, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 51, y: 62, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 52, y: 62, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 53, y: 62, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 62, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 56, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 57, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 58, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 59, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 65, y: 62, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 66, y: 62, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 67, y: 62, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 62, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 62, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 62, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 62, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 62, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 73, y: 62, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 74, y: 62, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 75, y: 62, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 76, y: 62, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 9, y: 63, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 10, y: 63, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 11, y: 63, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 12, y: 63, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 13, y: 63, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 14, y: 63, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 63, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 63, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 63, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 18, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 19, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 20, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 21, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 22, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 29, y: 63, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 30, y: 63, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 63, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 63, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 63, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 63, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 63, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 36, y: 63, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 37, y: 63, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 38, y: 63, value: "T19", targetType: TargetTypeEnum.Triple, score: 19 },
  { x: 39, y: 63, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 40, y: 63, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 41, y: 63, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 42, y: 63, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 43, y: 63, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 44, y: 63, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 45, y: 63, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 46, y: 63, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 47, y: 63, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 48, y: 63, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 49, y: 63, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 50, y: 63, value: "T17", targetType: TargetTypeEnum.Triple, score: 17 },
  { x: 51, y: 63, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 63, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 63, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 63, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 63, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 57, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 58, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 59, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 65, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 66, y: 63, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 67, y: 63, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 68, y: 63, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 63, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 63, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 63, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 63, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 73, y: 63, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 74, y: 63, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 75, y: 63, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 10, y: 64, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 11, y: 64, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 12, y: 64, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 13, y: 64, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 14, y: 64, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 15, y: 64, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 64, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 17, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 18, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 19, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 20, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 21, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 22, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 29, y: 64, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 30, y: 64, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 64, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 64, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 64, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 64, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 64, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 64, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 64, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 64, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 64, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 64, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 64, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 42, y: 64, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 43, y: 64, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 44, y: 64, value: "T3", targetType: TargetTypeEnum.Triple, score: 3 },
  { x: 45, y: 64, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 64, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 64, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 64, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 64, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 64, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 64, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 64, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 64, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 64, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 64, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 57, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 58, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 59, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 65, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 66, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 67, y: 64, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 68, y: 64, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 69, y: 64, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 70, y: 64, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 64, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 72, y: 64, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 73, y: 64, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 74, y: 64, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 11, y: 65, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 12, y: 65, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 13, y: 65, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 14, y: 65, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 15, y: 65, value: "16", targetType: TargetTypeEnum.Single, score: 16 },
  { x: 16, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 17, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 18, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 19, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 20, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 21, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 22, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 65, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 29, y: 65, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 30, y: 65, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 65, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 65, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 65, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 65, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 65, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 65, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 65, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 65, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 65, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 65, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 65, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 65, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 65, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 65, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 65, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 65, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 65, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 65, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 65, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 65, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 65, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 65, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 65, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 65, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 65, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 65, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 57, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 58, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 59, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 65, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 66, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 67, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 68, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 69, y: 65, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 70, y: 65, value: "15", targetType: TargetTypeEnum.Single, score: 15 },
  { x: 71, y: 65, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 72, y: 65, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 73, y: 65, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 12, y: 66, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 13, y: 66, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 14, y: 66, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 15, y: 66, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 16, y: 66, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 17, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 18, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 19, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 20, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 21, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 22, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 66, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 29, y: 66, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 30, y: 66, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 66, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 66, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 66, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 66, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 66, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 66, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 66, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 66, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 66, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 66, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 66, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 66, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 66, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 66, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 66, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 66, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 66, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 66, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 66, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 66, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 66, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 66, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 66, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 66, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 66, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 66, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 57, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 58, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 59, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 65, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 66, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 67, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 68, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 69, y: 66, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 70, y: 66, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 71, y: 66, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 72, y: 66, value: "D15", targetType: TargetTypeEnum.Double, score: 15 },
  { x: 13, y: 67, value: "D16", targetType: TargetTypeEnum.Double, score: 16 },
  { x: 14, y: 67, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 15, y: 67, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 16, y: 67, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 17, y: 67, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 18, y: 67, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 19, y: 67, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 20, y: 67, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 21, y: 67, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 22, y: 67, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 67, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 67, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 67, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 67, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 67, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 29, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 30, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 67, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 39, y: 67, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 67, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 67, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 67, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 67, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 67, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 67, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 67, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 48, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 57, y: 67, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 58, y: 67, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 59, y: 67, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 67, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 67, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 67, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 67, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 67, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 65, y: 67, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 66, y: 67, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 67, y: 67, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 68, y: 67, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 69, y: 67, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 70, y: 67, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 71, y: 67, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 14, y: 68, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 15, y: 68, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 16, y: 68, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 17, y: 68, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 18, y: 68, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 19, y: 68, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 20, y: 68, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 21, y: 68, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 22, y: 68, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 68, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 68, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 68, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 68, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 68, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 28, y: 68, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 29, y: 68, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 30, y: 68, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 68, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 68, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 68, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 68, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 68, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 68, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 68, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 68, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 39, y: 68, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 68, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 68, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 68, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 68, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 68, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 68, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 68, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 68, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 48, y: 68, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 68, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 68, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 68, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 68, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 68, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 68, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 68, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 68, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 57, y: 68, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 58, y: 68, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 59, y: 68, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 68, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 68, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 68, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 68, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 68, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 65, y: 68, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 66, y: 68, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 67, y: 68, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 68, y: 68, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 69, y: 68, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 70, y: 68, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 15, y: 69, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 16, y: 69, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 17, y: 69, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 18, y: 69, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 19, y: 69, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 20, y: 69, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 21, y: 69, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 22, y: 69, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 69, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 69, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 69, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 69, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 27, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 28, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 29, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 30, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 69, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 69, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 39, y: 69, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 69, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 69, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 69, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 69, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 69, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 69, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 69, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 69, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 48, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 57, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 58, y: 69, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 59, y: 69, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 69, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 69, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 69, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 69, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 69, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 65, y: 69, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 66, y: 69, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 67, y: 69, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 68, y: 69, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 69, y: 69, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 17, y: 70, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 18, y: 70, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 19, y: 70, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 20, y: 70, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 21, y: 70, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 22, y: 70, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 23, y: 70, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 24, y: 70, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 70, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 27, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 28, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 29, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 30, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 70, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 70, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 39, y: 70, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 70, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 70, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 70, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 70, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 70, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 70, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 70, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 70, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 48, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 57, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 58, y: 70, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 59, y: 70, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 60, y: 70, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 70, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 70, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 70, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 64, y: 70, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 65, y: 70, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 66, y: 70, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 67, y: 70, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 18, y: 71, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 19, y: 71, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 20, y: 71, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 21, y: 71, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 22, y: 71, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 23, y: 71, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 24, y: 71, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 25, y: 71, value: "7", targetType: TargetTypeEnum.Single, score: 7 },
  { x: 26, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 27, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 28, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 29, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 30, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 71, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 38, y: 71, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 39, y: 71, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 71, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 71, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 71, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 71, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 71, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 71, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 71, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 71, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 48, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 49, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 57, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 58, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 59, y: 71, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 60, y: 71, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 61, y: 71, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 62, y: 71, value: "2", targetType: TargetTypeEnum.Single, score: 2 },
  { x: 63, y: 71, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 64, y: 71, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 65, y: 71, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 66, y: 71, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 20, y: 72, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 21, y: 72, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 22, y: 72, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 23, y: 72, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 24, y: 72, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 25, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 26, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 27, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 28, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 29, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 30, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 72, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 38, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 39, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 48, y: 72, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 49, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 57, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 58, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 59, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 60, y: 72, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 61, y: 72, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 62, y: 72, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 63, y: 72, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 64, y: 72, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 22, y: 73, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 23, y: 73, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 24, y: 73, value: "D7", targetType: TargetTypeEnum.Double, score: 7 },
  { x: 25, y: 73, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 26, y: 73, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 27, y: 73, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 28, y: 73, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 29, y: 73, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 30, y: 73, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 73, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 73, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 73, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 73, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 73, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 73, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 38, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 39, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 48, y: 73, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 49, y: 73, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 73, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 73, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 73, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 73, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 73, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 73, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 73, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 57, y: 73, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 58, y: 73, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 59, y: 73, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 60, y: 73, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 61, y: 73, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 62, y: 73, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 63, y: 73, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 24, y: 74, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 25, y: 74, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 26, y: 74, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 27, y: 74, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 28, y: 74, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 29, y: 74, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 30, y: 74, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 31, y: 74, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 32, y: 74, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 33, y: 74, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 74, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 74, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 74, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 38, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 39, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 48, y: 74, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 49, y: 74, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 74, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 74, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 74, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 74, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 54, y: 74, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 55, y: 74, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 56, y: 74, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 57, y: 74, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 58, y: 74, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 59, y: 74, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 60, y: 74, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 61, y: 74, value: "D2", targetType: TargetTypeEnum.Double, score: 2 },
  { x: 24, y: 75, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 25, y: 75, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 26, y: 75, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 27, y: 75, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 28, y: 75, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 29, y: 75, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 30, y: 75, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 31, y: 75, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 32, y: 75, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 33, y: 75, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 34, y: 75, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 35, y: 75, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 36, y: 75, value: "19", targetType: TargetTypeEnum.Single, score: 19 },
  { x: 37, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 38, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 39, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 48, y: 75, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 49, y: 75, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 50, y: 75, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 51, y: 75, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 52, y: 75, value: "17", targetType: TargetTypeEnum.Single, score: 17 },
  { x: 53, y: 75, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 54, y: 75, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 55, y: 75, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 56, y: 75, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 57, y: 75, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 58, y: 75, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 59, y: 75, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 27, y: 76, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 28, y: 76, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 29, y: 76, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 30, y: 76, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 31, y: 76, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 32, y: 76, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 33, y: 76, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 34, y: 76, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 35, y: 76, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 36, y: 76, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 37, y: 76, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 38, y: 76, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 39, y: 76, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 40, y: 76, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 41, y: 76, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 42, y: 76, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 43, y: 76, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 44, y: 76, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 45, y: 76, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 46, y: 76, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 47, y: 76, value: "3", targetType: TargetTypeEnum.Single, score: 3 },
  { x: 48, y: 76, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 49, y: 76, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 50, y: 76, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 51, y: 76, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 52, y: 76, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 53, y: 76, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 54, y: 76, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 55, y: 76, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 56, y: 76, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 57, y: 76, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 30, y: 77, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 31, y: 77, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 32, y: 77, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 33, y: 77, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 34, y: 77, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 35, y: 77, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 36, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 37, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 38, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 39, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 40, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 41, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 42, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 43, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 44, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 45, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 46, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 47, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 48, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 49, y: 77, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 50, y: 77, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 51, y: 77, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 52, y: 77, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 53, y: 77, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 54, y: 77, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
  { x: 34, y: 78, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 35, y: 78, value: "D19", targetType: TargetTypeEnum.Double, score: 19 },
  { x: 36, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 37, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 38, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 39, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 40, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 41, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 42, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 43, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 44, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 45, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 46, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 47, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 48, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 49, y: 78, value: "D3", targetType: TargetTypeEnum.Double, score: 3 },
  { x: 50, y: 78, value: "D17", targetType: TargetTypeEnum.Double, score: 17 },
];
