// import {  ref, set, get } from "firebase/database";
import { set,  ref, get, query } from "firebase/database";
import { IFive0OneGameState } from "../games/501/state/state";
import Dataservice  from "./DataService";

interface IX01GameStatesByDate {
    Date: Date;
    X01Games: IFive0OneGameState[]
}

export class Five0OneService extends Dataservice<IFive0OneGameState> {


    // constructor(mainCOllection:string) {
    //     super(mainCOllection);
    // }

    async create(item: IFive0OneGameState): Promise<IFive0OneGameState> {

        const dbRef = ref(this.DB,`/${this.mainCollection}/five0onegames/${new Date().toDateString()}/${item.sessionId}`);

        await set(dbRef,item)
        .then(() => {
            console.log("Game saved");
            return item;
        })
        .catch((error: string | undefined) => {
            console.log(error);
            throw new Error(error);
        });

        return item;
    }

    async getAll(): Promise<IFive0OneGameState[]> {

        const dbRef = ref(this.DB,`/${this.mainCollection}/five0onegames/`);
        
        const x01gamesSnapshot = await get(query(dbRef))
            .then(result => {
                return result.toJSON() as IX01GameStatesByDate[]
            });
        
        let snapshot:IFive0OneGameState[] = []; 
        
        x01gamesSnapshot.forEach((item: { X01Games: any[]; }) => {
            let games:IFive0OneGameState[] = [];
            games = item.X01Games.map((game: any) => {
                return game;
            });

            games.forEach(i=> {
                snapshot.push(i);
            });
        });
        return snapshot as IFive0OneGameState[];

        // onValue(query(dbRef), snapshot => {
        //     console.log(snapshot.val());
        // });

    }

    update(item: IFive0OneGameState): Promise<IFive0OneGameState> {
        throw new Error("Method not implemented.");
    }
    delete(key: string): void {
        throw new Error("Method not implemented.");
    }
    deleteAll(): void {
        throw new Error("Method not implemented.");
    }
    
}