import React, { useContext } from "react";
import { IHit, ITarget } from "../model/Target";
import * as Icons from "react-bootstrap-icons";
import UserTarget from "./UserTarget";
import { targetHit, removeHit } from "../state/reducer";
import { MickyMouseContext } from "../state/context";
interface IUserTargetsProps {
  target: ITarget;
  hitsPrTarget?: number;
  playerId: number;
  placement: "LEFT" | "RIGHT";
  isActive:boolean
}

interface IHitButtonsProps {
  isAddButtonActive: boolean;
  isRemoveButtonActive: boolean;
  hit: IHit;
  isActive:boolean
}

const HitButtons = (props: IHitButtonsProps) => {
  const { dispatch } = useContext(MickyMouseContext);
  const { isAddButtonActive, isRemoveButtonActive, hit , isActive} = props;

  const addColor = isActive ? "Lime" : "grey";
  const removeColor = isActive ? "#F00" : "grey";

  return (
    <div className="justify-content-center col-auto px-1">
      <button
        type="button"
        className="btn" style={{color:addColor}}
        disabled={isAddButtonActive}
        onClick={() => dispatch(targetHit(hit))}
      >
        <Icons.PlusCircle size={32} />
      </button>
      <button
        type="button"
        className="btn" style={{color:removeColor}}
        disabled={isRemoveButtonActive}
        onClick={() => dispatch(removeHit(hit))}
      >
        <Icons.DashCircle size={32} />
      </button>
    </div>
  );
};

const UserTargets: React.FC<IUserTargetsProps> = (props) => {
  const { state } = useContext(MickyMouseContext);
  let { hitsPrTarget, target, playerId, isActive } = props;

  if (!hitsPrTarget) hitsPrTarget = 3;

  const targetButtons: any[] = [];
  const isAddHitButtonActive =
    !(target.count > 0) && state.ActivePlayer === playerId;

  const isremoveHitButtonActive =
    !(target.count < hitsPrTarget) && state.ActivePlayer === playerId;
  const hit: IHit = {
    playerid: playerId,
    targetid: target.id,
  };

  for (let index = hitsPrTarget; index > 0; index--) {
    targetButtons.push(
      <UserTarget
        key={index}
        active={target.count >= index}
        text={props.target.value.toString()}
      ></UserTarget>
    );
  }

  return (
    <React.Fragment>
      {props.placement === "RIGHT" ? (
        <div className="d-flex justify-content-start" style={{ marginTop: 18 }}>
            <HitButtons
              hit={hit}
              isAddButtonActive={isAddHitButtonActive}
              isRemoveButtonActive={isremoveHitButtonActive}
              isActive={isActive}
            />
          <div className="col-auto" style={{ padding: 0, paddingBottom:3 }}>
            {targetButtons}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-end" style={{ marginTop: 18 }}>
          <div className="col-auto" style={{ padding: 0, paddingBottom:3 }}>
            {targetButtons}
          </div>
            <HitButtons
              hit={hit}
              isAddButtonActive={isAddHitButtonActive}
              isRemoveButtonActive={isremoveHitButtonActive}
              isActive={isActive}
            />
        </div>
      )}
    </React.Fragment>
  );
};

export default UserTargets;
