import { IPlayer } from "../model/Player";
import { IHit, ITarget } from "../model/Target";

export enum ActionType {
    SetPlayer1,
    SetPlayer2,
    StartGame,
    SetGameSettings,
    SetPlayerTarget,
    HitPlayerTarget,
    RemovePlayerHit,
    ResetGame,
    NewGame,
    SwitchPlayer
}

export interface SetPlayer1 {
    type: ActionType.SetPlayer1;
    payload:IPlayer;
}

export interface SetPlayer2 {
    type: ActionType.SetPlayer2;
    payload:IPlayer;
}

// This starts the game with o sets pr player and a full set of targets for each player
export interface StartGame {
    type: ActionType.StartGame;
    payload:number
}

export interface SetGameSettings {
    type: ActionType.SetGameSettings,
    payload:number
}

export interface SetPlayerTarget {
    type: ActionType.SetPlayerTarget,
    payload: ITarget[]
}

export interface HitPlayerTarget {
    type: ActionType.HitPlayerTarget,
    payload:IHit
}

export interface RemoveTargetHit {
    type: ActionType.RemovePlayerHit,
    payload:IHit
}

// This adds a point to the winner and reset the targets for each player
export interface ResetGame {
    type:ActionType.ResetGame,
    payload:{roundWinner:IPlayer}
}

export interface SwitchPlayer {
    type:ActionType.SwitchPlayer,
    payload:number
}

export interface NewGame {
    type:ActionType.NewGame,
    payload:number
}

export type MickeyMouseGameActions = SetPlayer1 | SetPlayer2 | StartGame | SetPlayerTarget | HitPlayerTarget | RemoveTargetHit| ResetGame | SetGameSettings | NewGame |SwitchPlayer;