import { Status } from "../../../common/Status";
import { IPlayer } from "../model/Player";
import { IScore } from "../model/Score";

export interface IBermudaGameState {
    player1: IPlayer | null,
    player2: IPlayer | null,
    rounds: number,
    activeRound:number,
    activePlayerId:number|null,
    activeField:number,
    totalFields:10|11|12,
    GameStatus:Status,
    useRunningTotals:boolean,
    useFullBermudaRules:boolean,
    include41InBermudaRules:boolean

}

export const initialBermudaGameState:IBermudaGameState = {
    player1:null,
    player2:null,
    rounds:3,
    activeRound:1,
    activePlayerId:1,
    activeField:1,
    totalFields:10,
    useRunningTotals:true,
    useFullBermudaRules:false,
    include41InBermudaRules:true,
    GameStatus:Status.NotStarted
}

export interface IBermudaSaveGameObject {
    player1: {
        id: number,
        name: string,
        wonSets:number        
        scores: IScore[]
            // {
            //     value: number|undefined,
            //     field : 13|14|15|16|17|18|19|20|41|"T"|"D"|"B";
            //     fieldOrder:number|undefined;
            //     initialScore:number;
            //     min:number|undefined;
            //     max:number|undefined;
            //     fieldHint:string;
            // }
        ,
        startScore:number,
        zeroScoreCount: number,
    },
    player2: {
        id: number,
        name: string,
        wonSets:number        
        scores: IScore[],
        //     {
        //         value: number|undefined,
        //         field : 13|14|15|16|17|18|19|20|41|"T"|"D"|"B";
        //         fieldOrder:number|undefined;
        //         initialScore:number;
        //         min:number|undefined;
        //         max:number|undefined;
        //         fieldHint:string;
        //     }
        // ],
        startScore:number,
        zeroScoreCount: number,
    }
    rounds: number,
    activeRound:number,
    activePlayerId:number|null,
    activeField:number,
    totalFields:10|11|12,
    GameStatus:Status,
    useRunningTotals:boolean,
    useFullBermudaRules:boolean,
    include41InBermudaRules:boolean
}