import React from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { Container, Nav, NavDropdown, Offcanvas } from "react-bootstrap";
import * as Icons from "react-bootstrap-icons";
import MickeyMouse from "./games/MickeyMouse/components/Mickeymouse";
import Five0One from "./games/501/components/Five0One";
import Bermuda from "./games/bermuda/components/Bermuda";
import TwentiesTraining from "./training/20/Game";
import CheckoutTraining from "./training/checkout/CheckOut";
import OneThreeOneGame from "./training/131/Game";
import DartGames from "./common/DartGames";
import About from "./About";
import Test from "./Test";
import "./App.css";

const App: React.FC = () => {
  const [show, setShow] = React.useState<boolean>(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Router>
      <header>
        <nav className="navbar navbar-dark bg-dark">
          <button
            className="btn btn-default btn-sm"
            onClick={() => setShow(true)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link to="/" className="btn btn-default btn-sm">
            <Icons.House size={24} color="#FFF" />{" "}
          </Link>
        </nav>
      </header>
      <Container fluid>
        <Offcanvas
          show={show}
          onHide={handleClose}
          backdropClassName="bg-black"
        >
          {/* <Offcanvas.Header closeButton className="bg-dark text-light" color="#fff" >
            <Offcanvas.Title>
              <h1>Available Games</h1>
            </Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body className="bg-dark text-light">
            <h3>Available games</h3>
            <Nav defaultActiveKey="/" className="flex-column">
              <Nav.Link href="/mickeymouse" eventKey="link-1" className="text-light">
                <h5>
                  Mickey Mouse
                </h5>
              </Nav.Link>
              <Nav.Link href="/game501" eventKey="link-2" className="text-light">
                <h5>
                  501
                </h5>
              </Nav.Link>
              <Nav.Link href="/bermuda" eventKey="link-3" className="text-light">
                <h5>
                  Skovhugger
                </h5>
              </Nav.Link>
              <NavDropdown title="Training" id="nav-training" menuVariant="dark" bsPrefix="h5 m-3 my-5 text-light text-decoration-none">
                  <NavDropdown.Item href="/training/twenty"  eventKey="link-4" className="text-light" >
                    <h4>Twenties training</h4>
                    <p className="small">30 minutes focus on hitting 20's</p>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/training/checkout"  eventKey="link-5" className="text-light">
                    <h4>Checkout Training</h4>

                  </NavDropdown.Item>
                  <NavDropdown.Item href="/training/doubles" eventKey="link-6" className="text-light">
                    <h4>Doubles</h4>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/training/131" eventKey="link-7" className="text-light">
                    <h4>131</h4>
                  </NavDropdown.Item>
              </NavDropdown>

            </Nav>
          </Offcanvas.Body>
        </Offcanvas>

        <Switch>
          <Route exact path="/">
            <DartGames />
          </Route>

          <Route path="/mickeymouse">
            <MickeyMouse />
          </Route>

          <Route path="/game501">
            <Five0One />
          </Route>

          <Route path="/bermuda">
            <Bermuda />
          </Route>

          <Route path="/training/twenty">
            <TwentiesTraining />
          </Route>

          <Route path="/training/checkout">
            <CheckoutTraining />
          </Route>
          
          <Route path="/training/131">
            <OneThreeOneGame />
          </Route>

          <Route path="/about">
            <About />
          </Route>

          <Route path="/test">
            <Test />
          </Route>

        </Switch>
      <footer className="fixed-bottom mt-auto py-0 bg-dark text-secondary small">
        <div className="container fluid d-flex justify-content-around w-100">

          <div className="col-6 text-start">©Martin Moesby</div>
          <div className="col-6 text-end">
            <Link to="/" className="btn btn-link btn-sm text-white small">Rules</Link>
            <Link to="/privacy" className="btn btn-link btn-sm text-white small">Privacy</Link>
            <Link to="/contact" className="btn btn-link btn-sm text-white small">Contact</Link>
            <Link to="/about" className="btn btn-link btn-sm text-white small">About</Link>
          </div>
              
        </div>
      </footer>
      </Container>
    </Router>
  );
};

export default App;
