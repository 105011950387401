import React from "react";

const About = () => {
  return (
    <React.Fragment>
      <div className="container text-light">
        <div className="row mt-5">
          <h1>Dart Games</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            varius arcu ut nunc imperdiet, et cursus orci finibus. Aliquam
            gravida libero ante, nec sodales felis ultrices sit amet. Nulla sem
            dolor, sagittis ac dui eget, mollis feugiat nisl. Suspendisse
            convallis interdum justo, ut bibendum justo rhoncus at. Suspendisse
            fringilla, justo quis luctus sodales, ipsum lacus semper erat, eget
            tempus elit mi in orci. Phasellus et eros lectus. Curabitur
            condimentum purus mattis augue ornare malesuada. Fusce sed arcu
            commodo, rutrum ante commodo, scelerisque mauris. Etiam metus velit,
            eleifend eget augue quis, ultricies bibendum massa. Donec quis
            pretium enim. In luctus rutrum nulla quis tincidunt. Donec euismod,
            risus et molestie lobortis, erat erat rhoncus lacus, vitae malesuada
            nunc lectus vel felis. Nunc sed purus quis dui egestas pellentesque
            ut eu nunc. Proin ut dui at elit facilisis suscipit eu sit amet
            augue.
          </p>
          <h3>Lorem ipsum</h3>
          <p>
            Curabitur nisl magna, eleifend id vehicula vitae, ornare vitae
            lacus. Quisque rutrum, libero nec dictum vestibulum, augue erat
            eleifend leo, at sodales velit velit vitae sapien. Donec ut euismod
            nisl. Proin varius ultricies tempus. Integer tempor vulputate dolor,
            quis finibus libero luctus maximus. Fusce blandit euismod laoreet.
            Morbi molestie condimentum pharetra. Nullam commodo augue lorem. In
            hac habitasse platea dictumst. Sed molestie arcu turpis, tempus
            faucibus diam sollicitudin sed. Proin at augue sed ipsum convallis
            lacinia a vitae augue. Morbi at malesuada lacus, et rhoncus quam.
          </p>
          <h3>Disclaimer</h3>
          <p>
            Aenean metus risus, tincidunt non lacus vel, tincidunt aliquet quam.
            Pellentesque accumsan aliquam sagittis. Pellentesque habitant morbi
            tristique senectus et netus et malesuada fames ac turpis egestas. In
            eu sem tristique, porta nisl sit amet, dapibus nulla. Sed quis augue
            ullamcorper, ultrices orci id, consequat quam. Mauris congue erat
            sit amet urna commodo, in consequat orci commodo. Pellentesque ac
            semper massa. Pellentesque rhoncus elit id dolor vehicula ornare.
            Vestibulum venenatis est nulla, ut convallis augue convallis quis.
            Sed fermentum sem eget viverra convallis. Vivamus vel massa rutrum,
            molestie purus et, sollicitudin justo. Integer congue tortor in quam
            ullamcorper scelerisque. Duis blandit, felis id volutpat varius,
            dolor leo pellentesque eros, vel euismod enim lorem eu augue.
            Quisque in hendrerit mi.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default About;
