import { IPlayer } from "./Player";
import { IScore } from "./Score";

export class GameStats {

    private allScores: IScore[];
    private opponentsScores: IScore[];
    private gameScore:170|301|501|701|1001;

    private getScoreGroup = (queryLable: ScoreLable): ScoreGroup => {

        let s: number = 0;

        switch (queryLable) {
            case "0-19":
                s = this.allScores.filter(flt => {
                    return flt.value < 20;
                }).length;
                break;
            case "20-39":
                s = this.allScores.filter(flt => {
                    return flt.value >= 20 && flt.value < 40;
                }).length;
                break;
            case "40-59":
                s = this.allScores.filter(flt => {
                    return flt.value >= 40 && flt.value < 60;
                }).length;
                break;
            case "60-79":
                s = this.allScores.filter(flt => {
                    return flt.value >= 60 && flt.value < 80;
                }).length;
                break;
            case "80-99":
                s = this.allScores.filter(flt => {
                    return flt.value >= 80 && flt.value < 100;
                }).length;
                break;
            case "100-119":
                const s1 = this.allScores.filter(flt => {
                    return flt.value > 99 && flt.value < 120;
                });
                s = s1.length;
                break;
            case "120-139":
                s = this.allScores.filter(flt => {
                    return flt.value >= 120 && flt.value < 140;
                }).length;
                break;
            case "140-159":
                s = this.allScores.filter(flt => {
                    return flt.value >= 140 && flt.value < 160;
                }).length;
                break;
            case "160-180":
                s = this.allScores.filter(flt => {
                    return flt.value >= 160;
                }).length;
                break;
            default:
                break;
        }

        const result = { ScoresCount: s, Lable: queryLable }

        return result;

    }

    constructor(public player: IPlayer, opponentsScores: IScore[], gameScore: 170|301|501|701|1001) {
        this.player = player;
        this.allScores = player.scores;
        this.opponentsScores = opponentsScores;
        this.gameScore = gameScore;
     }

    getAllDarts = ():{set:number, darts:number}[] => {

        const distinctSets = [...new Set(this.allScores.map(x => x.setId))];
        const result: {set:number, darts:number}[] = [];

        for (let index = 0; index < distinctSets.length; index++) {
            
            const element = this.allScores.filter(flt => {
                return flt.setId === distinctSets[index];
            }).reduce((sum, current) => {return sum + current.darts},0);
            
            result.push({set:index+1, darts:element});
            
        }
        return result;
    }

    getScore = () => {
        return this.allScores.reduce((sum, current) => { return sum + current.value }, 0);
    }

    getScorePerLeg = (leg:number):number => {
        return this.allScores.filter(flt => {
                return flt.setId === leg;
            }).reduce((sum, current) => { return sum + current.value }, 0);
    }

    /// Calculate the average used darts for won sets in a match.....
    averageCheckoutDartsUsed = () => {
        
    }


    getCheckoutDarts = () :number[]  => {
        const closingSets:IScore[] = this.allScores.filter(flt => {
            return flt.isClosing
        });
        const distinctSets = [...new Set(closingSets.map(x=> x.setId))];
        let wonSets:number[] = [];
        for (const iterator of distinctSets) {
            const s = this.allScores.filter(flt =>  { return flt.setId === iterator}).reduce((sum,current) => {return sum + current.darts},0);
            wonSets.push(s);
        }
        return wonSets;
    }

    getOpponentsCheckoutScore = () :number[]  => {
        const closingSets:IScore[] = this.allScores.filter(flt => {
            return flt.isClosing
        });
        const distinctSets = [...new Set(closingSets.map(x=> x.setId))];
        let checkoutScore:number[] = [];

        for (const iterator of distinctSets) {
            // const s = this.allScores.filter(flt =>  { return flt.setId === iterator}).reduce((sum,current) => {return sum + current.darts},0);
            const opScore = this.opponentsScores.filter(flt => { return flt.setId === iterator}).reduce((sum,current) => {return sum + current.value},0);
            console.log("GameScore remaining",this.gameScore - opScore, " - set:", iterator);

            checkoutScore.push(this.gameScore - opScore);
        }
        return checkoutScore;
    }

    getAveragerCheckoutDarts = () :string => {
        const darts = this.getCheckoutDarts();
        console.log(darts);

        if (darts.length === 0)
            return "";
        const x = darts.length;
        const sumAllDarts = darts.reduce((sum, current) => { return sum + current}, 0);

        return( sumAllDarts/x).toFixed(2);
    }

    getDarts = (): number => {

        return this.allScores.reduce((sum, current) => { return sum + current.darts }, 0);
    }

    getClosings = (): IScore[] => {
        return this.allScores
            .filter(flt => { return flt.isClosing })
            ;
    }

    getTon = (): number => {

        return this.allScores.filter(flt => {
            return flt.value === 100
        }).length;
    }

    getPlusTon = (): number => {

        return this.allScores.filter(flt => {
            return flt.value > 100 && flt.value < 180
        }).length;
    }

    get180s = (): number => {

        return this.allScores.filter(flt => {
            return flt.value === 180
        }).length;
    }

    getScoreGroups = (): ScoreGroup[] => {
        const groups: ScoreGroup[] = [];

        groups.push(this.getScoreGroup("0-19"));
        groups.push(this.getScoreGroup("20-39"));
        groups.push(this.getScoreGroup("40-59"));
        groups.push(this.getScoreGroup("60-79"));
        groups.push(this.getScoreGroup("80-99"));
        groups.push(this.getScoreGroup("100-119"));
        groups.push(this.getScoreGroup("120-139"));
        groups.push(this.getScoreGroup("140-159"));
        groups.push(this.getScoreGroup("160-180"));

        return groups;
    }

    getAverageScorePerLeg = (leg:number) :number => {
        const dartsUsed =  this.allScores.filter(flt => {
            return flt.setId === leg;
        }).reduce((sum, current) => { return sum + current.darts }, 0);

        const score:number = this.getScorePerLeg(leg);

        if (dartsUsed===(null||undefined||0) || score === (null||undefined||0) )
        return 0;

        return Number((score / dartsUsed*3).toFixed(2));
    }

    getAverageScore = () :number => {
        const dartsUsed:number =  this.allScores.reduce((sum, current) => { return sum + current.darts }, 0);
        const score:number = this.getScore()
        if (dartsUsed===(null||undefined||0) ||  score === (null||undefined||0) )
            return 0;

        return Number((score / dartsUsed*3 ).toFixed(2));
    }
}

type ScoreGroup = {
    Lable:ScoreLable,
    ScoresCount:number
}

type ScoreLable = "0-19" | "20-39" | "40-59" | "60-79" | "80-99" | "100-119" | "120-139" | "140-159" | "160-180";

