import * as React from 'react';
import * as Icons from 'react-bootstrap-icons';

import { GameStats } from '../model/GameStats';
import { IPlayer } from '../model/Player';
import { IScore } from '../model/Score';

interface IFive0OneGameStatsProps {
    player: IPlayer,
    selectedSet: number,
    isWinner: boolean,
    opponentScore: IScore[],
    gameScore: 170 | 301 | 501 | 701 | 1001
}



const Five0OneGameStats: React.FunctionComponent<IFive0OneGameStatsProps> = (props) => {

    const { player } = props;
    const { name, wonSets } = player;


    const stats = new GameStats(props.player, props.opponentScore, props.gameScore );

    const StatColumn = (props: { label: string, value: string, enhance?: boolean | null }) => {


        const { label, value, enhance } = props;
        return (
            <div className="col-4">
                <div className="row py-1">
                    <div className="col-6 py-1 text-end">
                        <h5>{label}</h5>
                    </div>
                    <div className="col-4 text-white bg-dark rounded h5 text-center py-1">
                        {enhance ?
                            <span className="text-warning">{value}</span>
                            :
                            <span>{value}</span>
                        }
                    </div>
                </div>
            </div>);
    }

    const OtherStats = () => {
        return (<React.Fragment>
            <StatColumn label="Avg. darts / leg" value={stats.getAveragerCheckoutDarts()} />
            <StatColumn label="Avg. score" value={(+avgScoreprDart * 3).toFixed(2)} />
            <StatColumn label="Darts" value={setDarts.toString()} />
        </React.Fragment>);
    }

    const TonPlusTon180s = () => {
        return (<React.Fragment>
            <StatColumn label="Ton" value={stats.getTon().toString()} />
            <StatColumn label="+Ton" value={stats.getPlusTon().toString()} />
            <StatColumn label="180's" value={stats.get180s().toString()} enhance />
        </React.Fragment>
        );
    }

    const Badge = (props:{value:string}) => {
        return (<React.Fragment>
            <span className="badge bg-danger text-light border border-dark text-center" style={{paddingLeft:0, paddingRight:0, width: 75 }}>{props.value}</span>
        </React.Fragment>);
    }

    const MiniBadge = (props:{value:string}) => {
        return (<React.Fragment>
            <span className="badge bg-warning text-dark border border-dark text-center" style={{paddingLeft:0, paddingRight:0, width: 75 }}>{props.value}</span>
        </React.Fragment>);
    }

    const setDartBadges = [];
    const setClosingBadges = [];
    const setOpponetSCoreBadges = [];
    const setScoreGroups = [];

    const setScore: number = stats.getScore();
    const setDarts: number = stats.getDarts();
    const avgScoreprDart: string = (setScore / setDarts).toFixed(2);

    for (const iterator of stats.getCheckoutDarts()) {
        setDartBadges.push(<Badge value={iterator.toString()} /> )
    }
    
    for (const iterator of stats.getOpponentsCheckoutScore()) {
        setOpponetSCoreBadges.push(<MiniBadge value={iterator.toString()} /> )
    }

    for (const iterator of stats.getClosings()) {
        setClosingBadges.push(<Badge value={iterator.value.toString()} />);
    }

    for (const iterator of stats.getScoreGroups()) {
        setScoreGroups.push(<span className="bg-warning text-dark px-2 py-2 mx-1 rounded" >{iterator.Lable} : {iterator.ScoresCount}</span>)
    }

    return (
        <React.Fragment>
            <div className="container p-2 border rounded border-danger mb-3 text-light">

                <div className="row text-start">

                    <div className="col-2">

                        <div className="row justify-content-between">
                            <div className="col-auto h1">{name}</div>
                        </div>

                        <div className="row">
                            <div className="position-relative">
                                <div className="col-12">
                                    {props.isWinner ?
                                        <Icons.TrophyFill size={142} color="gold"/>
                                        : <Icons.TrophyFill size={142} color="silver" />
                                    }
                                </div>
                                <div className="position-absolute text-black text-center" style={{top:0, left:0, width:165, fontSize:"3.5rem", fontWeight:"bold"}}>
                                    {wonSets}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-10">

                        <div className="row text-start pb-3 mb-1 pt-2">
                            <div className="row mb-2">
                                <div className="col-3 text-end h1">Darts</div>
                                <div className="col-9 text-start h1">{setDartBadges}</div>
                            </div>
                            <div className="row">
                                <div className="col-3 text-end h1">Check Out</div>
                                <div className="col-9 text-start h1">{setClosingBadges}</div>
                            </div>
                            <div className="row">
                                <div className="col-3 text-end h3">Opponent Score</div>
                                <div className="col-9 text-start h3">{setOpponetSCoreBadges}</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="row">
                                <TonPlusTon180s />
                            </div>

                            <div className="row py-2">
                                <OtherStats />
                            </div>
                        </div>

                    </div>

                </div>

                {/* <div className="row mt-1 pt-2">
                    <div className="col-2"></div>
                    <div className="col-10 text-start">{setScoreGroups}</div>
                </div> */}
            </div>
        </React.Fragment>
    );

};

export default Five0OneGameStats;

