import React, { useContext } from 'react';
import Curtain from '../../../common/Curtain';
import { BermudaCOntext } from '../state/context';
import { FieldType, IScore, Score } from '../model/Score';
import { Col, FormControl, Row } from 'react-bootstrap';
import { addScore, undoScore } from '../state/reducer';
import { PlayerService } from './PlayerService';

export interface IPlayerScoresProps {
    playerid:number,
    active:boolean,
   
}

const PlayerScores: React.FC<IPlayerScoresProps> = (props) => {

    const {
      state: { player1 },
      state: { player2 },
      state: { activeField },
      dispatch
    } = useContext(BermudaCOntext);

    const { playerid, active } = props;

    const player = playerid === 1 ? player1 : player2;

    const playerService = new PlayerService(player!);

    const createScore = ( fieldNumber: FieldType, enteredValue:number) => {
  
      if ((fieldNumber === "D"|| fieldNumber === "T") )
        return new Score(fieldNumber, enteredValue > 180 ? 180: enteredValue);
      else if (fieldNumber === 41)
        return new Score(fieldNumber, enteredValue > 0 ? 1 : 0);
      else if (fieldNumber === "B" )
        return new Score(fieldNumber, enteredValue > 6 ? 6: enteredValue);

      return new Score(fieldNumber, enteredValue > 9 ? 9 : enteredValue);
 
    }


    const onKeyPressHandler = (event:React.KeyboardEvent,  fieldNumber: FieldType ) => {
      if ((event.key === "Enter" || event.key ==="Tab") && fieldNumber !== null) {
          event.preventDefault();
            
            const { value } = (event.currentTarget as HTMLInputElement);
            let scoreValue:number;

            if (value === "" || value === undefined)
              scoreValue = 0;
            else 
              scoreValue = parseInt(value);

            const score:IScore = createScore(fieldNumber,scoreValue);
            dispatch(addScore(score));

        }
        
        if (event.key === "/" && fieldNumber!==null) {
            event.preventDefault();
            dispatch(undoScore());
        }
    }

    return (
      <React.Fragment>
        <div className="position-relative bg-black rounded px-1 h1">
          <Curtain bg="#FFF" opacity={0.135} visible={active} />

          <div
            className="row justify-content-between"
            style={{ margin: 0, padding: 10, marginBottom:20, borderBottom:"1px solid lime" }}
          >
            <div className="col-8 col-sm-6">
              <h1 style={active ? { color: "lime" } : { color: "#CCC" }}>
                {player?.name}
              </h1>
            </div>
            <div className="col-2 col-sm-3 text-start" title="Nuværende score" style={{display:"flex", flexDirection:"column", flex:"1", justifyContent:"space-between"}}>
              <h5 style={active ? { color: "green" } : { color: "#CCC" }}>
                Won legs: {player!.wonSets}
              </h5>
              <h6 style={{ color: "lightyellow" }}>
                Opening Score: {player!.startScore}
              </h6>
            </div>
          </div>

          <div>
            {player!.scores
              .sort((a: IScore, b: IScore) => a.fieldOrder! - b.fieldOrder!)
              .map((targetObj: IScore, index: number) => {
                if (targetObj.fieldOrder === activeField && active) 
                {
                return (
                  <Row key={index} className="mb-2">
                    <Col>
                      <h2 className="rounded border p-2 bg-info border-info text-dark text-center mx-3">
                        {targetObj.field}
                      </h2>
                    </Col>
                    <Col>
                      <FormControl
                        className="form-control text-center"
                        style={{ fontSize: "1.8rem" }}
                        type="number"
                        max={targetObj.max === null ? undefined : targetObj.max}
                        min={0}
                        id={targetObj.fieldOrder.toString()}
                        onKeyPress={(e) =>
                          onKeyPressHandler(e, targetObj.field)
                        } 
                        autoFocus
                      />
                    </Col>
                    <Col>
                      <p
                        style={{
                          color: "yellow",
                          fontSize: "2.0rem",
                          verticalAlign: "end",
                          textAlign: "center",
                        }}
                      >
                        {index + 1 < activeField ||
                        (index + 1 === activeField &&
                          player!.scores[index].value !== null)
                          ? playerService.getTotalScore(index + 1)
                          : null}
                      </p>
                    </Col>
                  </Row>
                )
            }
                 else {
                    return (
                      <Row key={index} className="mb-2">
                        <Col>
                          <h2 className="rounded border p-2 bg-secondary border-secondary text-dark text-center mx-3">
                            {targetObj.field}
                          </h2>
                        </Col>
                        <Col>
                          <p
                            style={{
                              color: "lime",
                              fontSize: "2.0rem",
                              verticalAlign: "end",
                              textAlign: "center",
                            }}
                          >
                            {targetObj.value}
                          </p>
                        </Col>
                        <Col>
                          <p
                            style={{
                              color: "yellow",
                              fontSize: "2.0rem",
                              verticalAlign: "end",
                              textAlign: "center",
                            }}
                          >
                            {index + 1 < activeField ||
                            (index + 1 === activeField &&
                              player!.scores[index].value !== null)
                              ? playerService.getTotalScore(index + 1)
                              : null}
                          </p>
                        </Col>
                      </Row>
                    );
      
                }
              })}
          </div>
          <Row className='text-center'>
              {player!.zeroScoreCount > 0 ? <h3 className='text-info'>Halfings: {player!.zeroScoreCount}</h3> : null}
          </Row>
        </div>
      </React.Fragment>
    );
}
export default PlayerScores;