import { IPlayer } from "../model/Player";
import { Status } from '../../../common/Status';

export interface IMickeyMouseGameState {
    player1 : IPlayer | null,
    player2 : IPlayer | null,
    winner : IPlayer | null,
    TotalRounds : number,
    GameStatus: Status
    CurrentRound: number
    LastRoundWinner: IPlayer | null;
    ActivePlayer:number
}

export const initialMickeyMouseGameState: IMickeyMouseGameState = {
    player1: null,
    player2: null,
    winner: null,
    LastRoundWinner : null,
    TotalRounds: 1,
    CurrentRound: 1,
    GameStatus : Status.NotStarted,
    ActivePlayer:1
}