import { IPlayerBase } from "../../../models/IPlayerBase";
import { IScore } from "./Score";

export interface IPlayer extends IPlayerBase {
    scores: IScore[],
    startScore:number,
    zeroScoreCount: number,
    // addScore(score:IScore) : void,
    // totalScore(roundNumber:number) : number,
    // initScores(useFullBermuda:boolean, use42InBermuda:boolean) : void,
    // roundScore():number
}

export class Player implements IPlayer {

    id:number;
    name: string;
    startScore: number = 0;
    wonSets: number=0;
    scores :IScore[] = [];
    zeroScoreCount: number = 0;
    
    constructor(id:number,name:string) {
        this.id = id;
        this.name = name;
        // this.initScores();
    }

    // initScores = (useFullBermuda:boolean, use41InBermuda:boolean):void => {

    //     this.scores =[];

    //     if (useFullBermuda) {

    //         this.scores.push(this.getInitScore(13,undefined,this.startScore));
    //         this.scores.push(this.getInitScore(14,undefined));
    //         this.scores.push(this.getInitScore("D",undefined));
    //         this.scores.push(this.getInitScore(15,undefined));
    //         this.scores.push(this.getInitScore(16,undefined));
    //         this.scores.push(this.getInitScore("T",undefined));
    //         this.scores.push(this.getInitScore(17,undefined));
    //         this.scores.push(this.getInitScore(18,undefined));

    //         if (use41InBermuda)
    //             this.scores.push(this.getInitScore(41,undefined));

    //         this.scores.push(this.getInitScore(19,undefined));
    //         this.scores.push(this.getInitScore(20,undefined));
    //         this.scores.push(this.getInitScore("B",undefined));

    //     } else {

            
    //         this.scores.push(this.getInitScore(15,undefined,this.startScore));
    //         this.scores.push(this.getInitScore(16,undefined));
    //         this.scores.push(this.getInitScore("D",undefined));
    //         this.scores.push(this.getInitScore(17,undefined));
    //         this.scores.push(this.getInitScore("T",undefined));
    //         this.scores.push(this.getInitScore(18,undefined));
    //         this.scores.push(this.getInitScore(19,undefined));
    //         this.scores.push(this.getInitScore(41,undefined));
    //         this.scores.push(this.getInitScore(20,undefined));
    //         this.scores.push(this.getInitScore("B",undefined));
            
    //     }
    // }

    // getInitScore = (field:FieldType,value:number|undefined,initialValue:number|undefined = undefined) => {
    //      const score = new Score(field, value, initialValue);
    //      score.fieldOrder = this.scores.length+1;
    //      return score;

    // }

    // addScore = (score:IScore):void => {
    //     let point:number = 0;

    //     if (score.field === "D" || score.field ==="T")
    //         point = +score.value!;

    //     if (score.field === "B")
    //         point = +score.value! * 25;

    //     if (score.field === 41)
    //         point = +score.value! > 0 ? 41 : 0;

    //     if (Number(score.field) >= 13 && Number(score.field) <= 20)
    //     {
    //         point = +score.value!*Number(score.field);
    //     }

    //     var updatedItem = this.scores.find((element) => { return element.field === score.field});

    //     if (updatedItem !== undefined) {
    //         updatedItem.value = point;
    //     }

    //     if (score.value === 0) {
    //         this.zeroScoreCount++;
    //     }
    //     else  
    //     {
    //         this.zeroScoreCount = 0;
    //     }
        
    // }

    // roundScore = (): number => {

    //     return this.scores
    //         .reduce((sum, current) => {
    //             return current.value === 0 ? 
    //                 Number(((sum+current.initialScore)/2).toFixed(0)) : 
    //                 current.value !== undefined ?
    //                     sum += current.value + current.initialScore :
    //                     sum += current.initialScore;

    //         },0);
        
    // }

    // totalScore = (roundNumber:number):number  => {

    //     const totalScore: number = this.scores
    //         .sort((a:IScore, b:IScore) => a.fieldOrder! - b.fieldOrder!)
    //         .filter(item => item.fieldOrder! <= roundNumber)
    //         .reduce((sum, current) => {
    //             return current.value === 0 ? 
    //                 Number(((sum+current.initialScore)/2).toFixed(0)) : 
    //                 current.value !== undefined ?
    //                     sum += current.value + current.initialScore :
    //                     sum += current.initialScore;

    //         },0);
        
        
    //     // .map((targetObj:IScore, index:number) => {
    //     //     if (index <= roundNumber) {
    //     //         if (targetObj.value === 0) {
    //     //             sum = Number((sum/2).toFixed(0));
    //     //         } else {
    //     //             sum += targetObj.value;
    //     //         } 
    //     //     }
    //     // });
    //     return totalScore;
    // }
}