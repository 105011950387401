import React, { useEffect, useState } from "react";
import * as Icons from 'react-bootstrap-icons';
import { DataStoreService } from "../services/DataStoreService";
import { Five0OneService } from "../services/Five0OneService";
import { Button, Col, Container, Row } from "react-bootstrap";
import { GameType } from "./GameTypes";

interface SaveGameButtonProps {
    game:any;
    gameType:GameType;
    onGameSaved(gameSaved:boolean):void
}

interface NewGameButtonProps {
    OnClick():void
}

interface NextGameButtonProps {
    OnClick():void
}

export const SaveGameButton:React.FC<SaveGameButtonProps> = (props) => {

    const {game, gameType, onGameSaved } = props;

    const [joinCode, setJoinCode] = useState<string>('');
    const [alertMessage, setAlertMessage] = useState<string|undefined>(undefined);
    
    useEffect(()=> {
        const code = localStorage.getItem("DartGames:Club:JoinCode");
        if (code !== null) {
            setJoinCode(code);
        }
    },[]);
  
      const handleOnJoincodeChanged: React.ChangeEventHandler<
        HTMLInputElement
      > = (ev) => {
        ev.preventDefault();
        const joinCode = ev.currentTarget.value;
        localStorage.setItem("DartGames:Club:JoinCode", joinCode);
        setAlertMessage(undefined);
        setJoinCode(joinCode);
      };
  
    const saveGame = async () => {
  
      if (joinCode === "") {
        setAlertMessage("Please enter your Clubs joincode to save data");
        return;
      }

      let dataService;

      switch (gameType) {
        case GameType.X01games:
          dataService = new Five0OneService(joinCode);
          break;
        case GameType.Bermuda:
        case GameType.MickeyMouse:
        case GameType.TrainingGame131:
        case GameType.TrainingGame20ies:
        default:
          dataService = undefined;
      }

      if (dataService) {
        dataService.create(game).then(() => {
          setAlertMessage(undefined);
          onGameSaved(true);
        });
      }

      const storeService = new DataStoreService(joinCode, gameType);
      storeService.create(game).then((result) => {
        setAlertMessage(undefined);
        onGameSaved(true);
      });
  
        // .then(() => {
        //   console.log("Game saved...");
        // })
        // .catch((error) => {
        //   console.error(error);
        // });
    };


    return (
        <Container>
            <Row>
                <Button variant="primary" onClick={saveGame} className="p-4 w-100">
                    <Row>
                        <Col sm={4}>
                            <Icons.Save size={48} />
                        </Col>
                        <Col sm={8}>
                            <h1>Save</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="small muted">no personal information is stored</p>
                        </Col>
                    </Row>
                </Button>
            </Row>

            <Row className="text-muted small text-center mb-2">
                <Col sm={12}>
                    <input type="text" onChange={handleOnJoincodeChanged} placeholder="Enter your clubs joinCode" value={joinCode} className="rounded p-2 w-100" />
                    {alertMessage}
                </Col>
            </Row>

        </Container>
    )
}

export const NewGameButton:React.FC<NewGameButtonProps> = (props) => {

    const { OnClick} = props;

    return (
        <Button variant="danger" onClick={OnClick} className="p-4 w-100" >
            <Row>
                <Col sm={4}>
                    <Icons.ArrowLeftCircle size={48} />
                </Col>
                <Col sm={8}>
                    <h1>New</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="small muted">with new contestants</p>
                </Col>
            </Row>
      </Button>
    );
}

export const NextGameButton:React.FC<NextGameButtonProps> = (props) => {

    const { OnClick} = props;

    return (
        <Button variant="success" onClick={OnClick} className="p-4 w-100" >
            <Row>
                <Col sm={8}>
                    <h1>Next</h1>
                </Col>
                <Col sm={4}>
                    <Icons.ArrowRightCircle size={48} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="small muted">with same contestants</p>
                </Col>
            </Row>
      </Button>
    );
}