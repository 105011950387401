import React from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import AdobeStock3 from "../images/AdobeStock_453518764.jpeg";

const DartGames: React.FC = () => {


  return (
    <React.Fragment>
      <Container className="text-white">
        <Row>
          <Col xs={12}>
            <img className="d-block" src={AdobeStock3} alt="Fifth slide" />
          </Col>
          <Col>
            <h1>Dart Games</h1>
          </Col>
        </Row>
        <Row>
          <Tabs
            defaultActiveKey="mickeymouse"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="mickeymouse" title="Mickey Mouse" tabClassName="text-white bg-transparent">
              <Link to="/mickeymouse" className="btn btn-link text-white">
                <h3>Mickey Mouse</h3>
              </Link>
              <p>
                In Mickey Mouse dart, you must close out the numbers from twelve
                to twenty, as well as, any three doubles, triples, and the
                bullseye. To close out a number means to hit it three times. The
                hits do not need to be in the same round. Each player will take
                turns throwing their three darts in an attempt to close out all
                the numbers. Any dart that lands in a double or triple of an
                open number may be used to close out that number or used towards
                closing doubles and triples. If a double or triple is used to
                help close out a number, it is worth two or three points
                respectively. Play continues until one of the players closes out
                all of their numbers
              </p>
            </Tab>
            <Tab eventKey="501" title="501" tabClassName="text-white bg-transparent">
              <Link to="/game501" className="btn btn-link text-white">
                <h3>501</h3>
              </Link>
              <p>
                To play 501 darts the rules are simple, both players or teams
                start with a score of 501 points. Each player then takes
                alternating turns at throwing their darts at the dartboard. The
                points scored are removed from the total, and then the opposing
                player/team does the same. The first to reach zero wins the
                game. To complete the game you must double-out. This adds a bit
                more skill to the game as well as strategy when it comes to
                planning a finish. Usually, a coin is tossed to determine the
                starting order. Alternatively, you can each throw a dart at the
                board and whoever has the closest dart to the bullseye starts
                first.
              </p>
              <p>
                To win the game, you have to double-out. When doubling-in, you
                are free to hit any double target on the board to get things
                started. When doubling out, you need to hit a double that will
                land you precisely on zero points to win the game. It is
                essential that you do not hit more points than what you have
                remaining, this will result in a bust, and your turn will be
                over. The center bull is the highest target you can double-out
                on, being worth 50 points. After that would be the double 20
                with is worth 40 points.
              </p>
            </Tab>
            <Tab eventKey="Halfing" title="Skovhugger" tabClassName="text-white bg-transparent">
              <Link to="/bermuda" className="btn btn-link text-white">
                <h3>Skovhugger / Bermuda</h3>
              </Link>
              <p>
                This game is for 2 or more players. To play the game, each player must in succession hit the fields 15, 16, any double, 17, any tripple, 18, 19, then score 41 with exactly 3 darts, then the fields 20 and finally bull.
                <br/>For Bermuda-variation, the fields range from 13 to 20, with the dooubleand tripple between 14/15 and 17/18.
                <br/>An option for the use of 41-score is also available in the Bermuda variation.
                <br/>Scoring only counts for darts in the current field. If a field/value is not hit, the player halfs the score accumulated.
                <br/>If Halfing happens 3 times in a row, the round is lost.
                <br/>The winner is the player, that has accumulated the highest score.
              </p>
            </Tab>
            <Tab eventKey="traininggames" title="Training games" tabClassName="text-white bg-transparent ">
              <Link to="/training/131" className="btn btn-link text-white">
                <h3>131</h3>
                </Link>
              <p>131 is a closing training game. With 12 darts, you have to close the current score. <br/>If successfull, the score goes UP with 8 + the remaining darts. If unsuccessful, the score goes down by 8.</p>
              <Link to="/training/twenty" title="20-ies training"  className="btn btn-link text-white">
                <h3>20'es</h3>
                </Link>
              <p>20's training is focused training on 20's alone in a set amount of time.<br/>Each score gives points ranging from 7 to 1, based on the score made. After the set time, the total score is shown</p>
            </Tab>
          </Tabs>
        </Row>
      </Container>

    </React.Fragment>
  );
};

export default DartGames;
