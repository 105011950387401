import React from "react";
import { MickyMouseContext } from "../state/context";
import MickeyMouseLegend from "./MickeyMouseLegend";
import UserTargetList from "./UserTargetList";

const MickeyMouseGame = () => {
  const { state } = React.useContext(MickyMouseContext);

  return (
    <React.Fragment>
      <div className="d-flex flex-row justify-content-center">
        <div className="">
          <UserTargetList playerid={1} active={state.ActivePlayer === 1} />
        </div>
        <div className="px-0">
        <MickeyMouseLegend />
        </div>
        <div className="">
          <UserTargetList playerid={2} active={state.ActivePlayer === 2} />
        </div>

      </div>
    </React.Fragment>
  );
};

export default MickeyMouseGame;
