import React, { useReducer, useState } from "react";
import * as Boot from "react-bootstrap";
import { Status } from "../../../common/Status";
import { Five0OneContext } from "../state/context";
import { five0OneGameReducer, nextGame, newGame  } from "../state/reducer";
import { initialFive0OneGameState } from "../state/state";
import Five0OneGame from "./Five0OneGame";
import Five0OneGameSettings from "./Five0OneGameSettings";
import Five0OneGameStats from "./Five0OneGameStatistics";

import { SaveGameButton, NewGameButton, NextGameButton } from "../../../common/GameButtons";
import { GameType } from "../../../common/GameTypes";

// const GamesList:React.FC<{previousGames:IX01GameStatesByDate[]|null}> = (props) => {

//   const list:any = [];
//   const { previousGames } = props;
//   console.log("Prev. games data:", previousGames?.length, previousGames);


//   const games = previousGames?.map(item => {
//     console.log(item.X01Games, item.X01Games?.length);
//     return item.X01Games as IFive0OneGameState[]
//   });

//   console.log(games);

//   if (previousGames) {
//       previousGames?.forEach((item, index) => {
//       list.push(
//         <Boot.Row key={index}>
//           <h5>{item.Date}</h5>
//           {item.X01Games.forEach((item2, index2) => {
//             return (
//               <Boot.Row key={index2}>
//                 <Boot.Col>{item2.gameScore}</Boot.Col>
//                 <Boot.Col>{item2.player1?.name}</Boot.Col>
//                 <Boot.Col>{item2.player2?.name}</Boot.Col>
//                 <Boot.Col>
//                   {item2.winner === 1
//                     ? item2.player1?.name
//                     : item2.player2?.name}
//                 </Boot.Col>
//               </Boot.Row>
//             );
//           })}
//         </Boot.Row>
//       );
//     });
//   }
  
//   return (
//     <>
//       {list}
//     </>)
// }

export interface IFive0OneProps {}

const Five0One = (_: IFive0OneProps) => {
  const [state, dispatch] = useReducer(
    five0OneGameReducer,
    initialFive0OneGameState
  );
  const [selectedSet, setSelectedSet] = useState<number>(1);
  const [gameSaved, setGameSaved] = useState<boolean>(false);
  // const [previousGames, setPrevoiusGames] = useState<IX01GameStatesByDate[]|null>(null);



  // const loadGames = async () => {
  //   if (joinCode==="")
  //   {
  //     setAlertMessage("Please enter your Clubs joincode to save data");
  //     return;
  //   }
  //   const dataService = new Five0OneService(joinCode);
  //   dataService.getAllByDate().then(result => {
  //     console.log(result);
  //     setPrevoiusGames(result);
  //     console.log(previousGames);
  //   });

  // }



  const setButtons = [];

  for (let index = 0; index < state.totalSets; index++) {
    setButtons.push(
      <button
        key={index}
        className="btn btn-secondary btn px-3"
        onClick={() => setSelectedSet(index + 1)}
      >
        {index + 1}
      </button>
    );
  }

  return (
    <Five0OneContext.Provider value={{ state, dispatch }}>
      <Boot.Container fluid={true}>
        <Boot.Row className="mt-3">
          <Boot.Col xs={12}>
            {state.status === Status.InProgress ? (
              <Five0OneGame />
            ) : state.status === Status.NotStarted ? (
              <React.Fragment>
                <Five0OneGameSettings />
                {/* <Boot.Container>

                <Boot.Row>
                  <h5 className="text-white">Previous games:</h5>
                </Boot.Row>

                <Boot.Row className="d-flex justify-content-between">
                  <input type="text" value={joinCode} onChange={handleOnJoincodeChanged} />
                  {alertMessage ? <p className="text-danger">{alertMessage}</p> : null}
                  <button onClick={loadGames} className="btn btn-primary p-2">Load previous games</button>
                </Boot.Row>
                {previousGames ? <GamesList previousGames={previousGames} /> : null } 

                </Boot.Container> */}
              </React.Fragment>
            ) : (
              <div className="text-center">
                <Five0OneGameStats
                  player={state.player1!}
                  selectedSet={selectedSet}
                  isWinner={state.winner === 1}
                  opponentScore={state.player2!.scores}
                  gameScore={state.gameScore}
                />
                <Five0OneGameStats
                  player={state.player2!}
                  selectedSet={selectedSet}
                  isWinner={state.winner === 2}
                  opponentScore={state.player1!.scores}
                  gameScore={state.gameScore}
                />
                <Boot.Container>
                  <Boot.Row className="d-flex justify-content-between" style={{ gap: "5px" }}>
                    <Boot.Col sm={4}>
                      <NewGameButton OnClick={() => {
                        setGameSaved(false);
                        dispatch(newGame())
                      }
                      } />
                    </Boot.Col>
                    {!gameSaved ?
                      <Boot.Col>
                        <SaveGameButton onGameSaved={setGameSaved} game={state} gameType={GameType.X01games} />
                      </Boot.Col>
                      : null
                    }
                    <Boot.Col sm={4}>
                      <NextGameButton OnClick={() => {
                        setGameSaved(false);
                        dispatch(nextGame())
                      }
                      } />
                    </Boot.Col>
                  </Boot.Row>
                </Boot.Container>
              </div>

            )}
          </Boot.Col>
        </Boot.Row>
      </Boot.Container>
    </Five0OneContext.Provider>
  );
};

export default Five0One;
