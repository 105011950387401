import React, { useReducer } from 'react';
import * as Icons from 'react-bootstrap-icons';
import MickeyMouseGameSettings from './MickeyMouseGameSettings';
import { mickeyMousGameReducer, newGame } from '../state/reducer';
import { initialMickeyMouseGameState } from '../state/state';
import { Status } from '../../../common/Status';
import { MickyMouseContext } from '../state/context';
import { Col, Container, Row } from 'react-bootstrap';
import MickeyMouseGame from './MickeyMouseGame';

export interface IMickeyMouseProps {
    noOfhits: number,
    noOfSets: number,
    canStart: boolean,
    currentSet: number,
    targetsPlayer1: { value: string, count: number }[],
    targetsPlayer2: { value: string, count: number }[],
}

const MickeyMouse: React.FC = () => {

    const [state, dispatch] = useReducer(mickeyMousGameReducer, initialMickeyMouseGameState);


    return (
        <MickyMouseContext.Provider value={{ state, dispatch }} >
                <Container fluid>
                    <Row className="mt-0">
                        <Col xs={12}>
                            {state.GameStatus === Status.InProgress ?
                                <MickeyMouseGame />
                            :
                            state.GameStatus === Status.NotStarted ?
                                <MickeyMouseGameSettings />
                            :
                            <React.Fragment>
                                <div className="col-12 text-center my-5 py-5 text-light"> 
                                    <Icons.TrophyFill size="128" color="Gold" />
                                    <h1 className="mt-4">Winner: {state.winner!.name}</h1>
                                </div>
                                <div className="text-center">
                                    <button onClick={() => dispatch(newGame(state.TotalRounds))} className="btn btn-success btn-lg m-4 p-4">New game</button>
                                </div>
                            </React.Fragment>
                            }
                        </Col>
                    </Row>
                    
                </Container>
        </MickyMouseContext.Provider>
    );
}

export default MickeyMouse;
