import { TargetTypeEnum } from './DartBoardMatrix';
import { HiresMatrix } from './DartBoardMatrixHires';

interface IDartboardCoordinate {
    x:number,
    y:number,
    value:string,
    targetType:TargetTypeEnum,
    score:number
}

export interface IDartScore {
    score: number,
    fieldType: TargetTypeEnum,
    fieldHit:string,
    fieldTargeted:string
}

interface IDartplayer {
    getScore(target:string, targetType:TargetTypeEnum): IDartScore | null;
}

export class DartPlayer implements IDartplayer {
    private dartboardCoordinates:IDartboardCoordinate[] = HiresMatrix;
    private playerPrecision:number = 4;
    playerAverageScore:number;

    constructor(playerlevel:2|3|4|5|6|7|8|10|12|14) {
        this.playerAverageScore = playerlevel*10;

        const calcPrecsion = Math.floor(Math.sqrt(180 / (this.playerAverageScore * this.playerAverageScore) * Math.PI)*20);
        this.playerPrecision  = calcPrecsion;

        // if (playerAverage >= 100) {
        //     this.playerPrecision = 5;
        // }
        // if (playerAverage < 100) {
        //     this.playerPrecision = 2;
        // }        
        // if (playerAverage < 80) {
        //     this.playerPrecision = 3;
        // }
        // if (playerAverage < 70) {
        //     this.playerPrecision = 4;
        // }
        // if (playerAverage <= 80) {
        //     this.playerPrecision = 5;
        // }
        // if (playerAverage <= 60) {
        //     this.playerPrecision = ;
        // }
        // if (playerAverage <= 40) {
        //     this.playerPrecision = 10;
        // }
        // if (playerAverage < 30) {
        //     this.playerPrecision = 15;
        // }
        // if (playerAverage < 20) {
        //     this.playerPrecision = 20;
        // }        
    }

    getScore(target: string, targetType: TargetTypeEnum): IDartScore | null {

        const primaryTargets: IDartboardCoordinate[]  = this.dartboardCoordinates.filter(flt => { return flt.value.includes(target)  && flt.targetType === targetType });
        // console.log("Found primary Targets:", primaryTargets);
        let primaryTarget:IDartboardCoordinate|undefined = undefined;
        let retryCount:number = 0;
        while (primaryTarget === undefined || retryCount < 10)
        {
            primaryTarget = primaryTargets.length === 1 ? primaryTargets[0] : primaryTargets[Math.floor(Math.random() * primaryTargets.length)]
            retryCount++;
        }

        if (primaryTarget === undefined)
            return null;

        let minX = primaryTarget.x - this.playerPrecision;
        let minY = primaryTarget.y - this.playerPrecision;
        let maxX = primaryTarget.x + this.playerPrecision;
        let maxY = primaryTarget.y + this.playerPrecision;

        //console.log("Target Area Coords:", minX, minY, maxX, maxY);

        const randomX = Math.floor(Math.random()*(maxX - minX))+ minX;        
        const randomY = Math.floor(Math.random()*(maxY - minY))+ minY;

        //console.log("Picked Target at coordiante :", randomX, randomY);

        const possibleTargets = this.dartboardCoordinates.filter(item => {
            return item.x === randomX && item.y === randomY
        });

        if (possibleTargets.length === 0) {
        //    console.log("No Score - hit off the board")
            return null;
        }

        //console.log("Found possible targets:", possibleTargets);

        const selectedCoordinate = possibleTargets[0];// [Math.floor(Math.random() * possibleTargets.length)];
        //console.log("Found hit target at: ",selectedCoordinate);

        if (selectedCoordinate.value === "DBULL")
            return {score : 50, fieldType:TargetTypeEnum.Double, fieldHit:selectedCoordinate.value, fieldTargeted:target };

        if (selectedCoordinate.targetType === TargetTypeEnum.Double)
            return {score : selectedCoordinate.score * 2, fieldType:TargetTypeEnum.Double , fieldHit:selectedCoordinate.value, fieldTargeted:target};

        if (selectedCoordinate.targetType === TargetTypeEnum.Triple)
            return {score : selectedCoordinate.score * 3, fieldType:TargetTypeEnum.Triple, fieldHit:selectedCoordinate.value, fieldTargeted:target };

        return {score : selectedCoordinate.score, fieldType:TargetTypeEnum.Single , fieldHit:selectedCoordinate.value, fieldTargeted:target};

    }

    
}