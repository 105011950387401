import React from "react";
import { BermudaGameActions } from "./actions";
import { IBermudaGameState, initialBermudaGameState } from "./state";

export const BermudaCOntext = React.createContext<{
    state:IBermudaGameState,
    dispatch: React.Dispatch<BermudaGameActions>
}>({
        state:initialBermudaGameState, 
        dispatch:() => undefined
});
