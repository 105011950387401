import React, { useState, useEffect } from "react";
import { IOneThreeOneRound } from "./model";
import { Checkout } from "../../common/Checkouts"
import { Col, Row } from "react-bootstrap";
import ActiveRoundInput from "./ActiveRoundInput";


export interface IActiveRoundProps {
    Target:number;
    SubmitRound(roundResult:IOneThreeOneRound):void;
}
const checkoutClass = new Checkout();

const getCheckoutText = (value:number):string => {
    const checkOutText = checkoutClass.getCheckOut(value);
    if (checkOutText)
        return checkOutText;
    return "No Checkout";
}

const ActiveRound:React.FC<IActiveRoundProps> = (props) => {
    
    const { Target, SubmitRound } = props;
    const initialCoutText = getCheckoutText(Target);
    const [currentTurn, setCurrentTurn] = useState<number>(1);
    const [checkoutText, setCheckputText] = useState<string>(initialCoutText);

    const intialScores = {
        target:Target,
        round:1,
        scores: [
            {score:null, darts:null},
            {score:null, darts:null},
            {score:null, darts:null},
            {score:null, darts:null}
        ]
    }

    const [roundScores, setRoundScores] = useState<IOneThreeOneRound>(intialScores);

    const handleSubmitScore = (scoreId:number, score:number, darts:number ):void => {

        const newScores:IOneThreeOneRound = { target:Target, round:scoreId, scores:[...roundScores.scores] }
        newScores.scores[scoreId-1].score = score;
        newScores.scores[scoreId-1]!.darts = darts;

        setRoundScores(newScores);

        setCheckputText(getCheckoutText(score));

        if (scoreId === 4 || score === 0) {
            SubmitRound(roundScores);
            setCurrentTurn(1);
            setRoundScores(intialScores);
        } else {
            setCurrentTurn(currentTurn+1);
        }
    }

    const handleRollbackScore = (scoreId:number) => {
      if (scoreId === 1)
        return;
      const newScores:IOneThreeOneRound = { target:Target, round:scoreId, scores:[...roundScores.scores] }
      newScores.scores[scoreId-1].score = null;
      newScores.scores[scoreId-1]!.darts = null;
      setCurrentTurn(currentTurn-1);

    }

    useEffect(()=> {
        setCheckputText(getCheckoutText(Target));
    },[Target]);

    return (
      <React.Fragment>
        <Row>
          <Col sm={2} className="p-2 text-center display-4">{Target}</Col>
          <Col sm={2}>
            <ActiveRoundInput
              InputScoreId={1}
              IsDisabled={currentTurn !== 1}
              SubmitScore={handleSubmitScore}
              InputRoundScore={roundScores.scores[0]}
              RollbackScore={handleRollbackScore}
              Target={Target}
            />
          </Col>
          <Col sm={2}>
            <ActiveRoundInput
              InputScoreId={2}
              IsDisabled={currentTurn !== 2}
              SubmitScore={handleSubmitScore}
              InputRoundScore={roundScores.scores[1]}
              RollbackScore={handleRollbackScore}
              Target={roundScores.scores[0].score}
            />
          </Col>
          <Col sm={2}>
            <ActiveRoundInput
              InputScoreId={3}
              IsDisabled={currentTurn !== 3}
              SubmitScore={handleSubmitScore}
              InputRoundScore={roundScores.scores[2]}
              RollbackScore={handleRollbackScore}
              Target={roundScores.scores[1].score}
            />
          </Col>
          <Col sm={2}>
            <ActiveRoundInput
              InputScoreId={4}
              IsDisabled={currentTurn !== 4}
              SubmitScore={handleSubmitScore}
              InputRoundScore={roundScores.scores[3]}
              RollbackScore={handleRollbackScore}
              Target={roundScores.scores[2].score}
            />
          </Col>
        </Row>
        <Row className="h5 pt-2 text-secondary">
          <Col sm={2} className="text-end" >
            Scored
          </Col>
          <Col sm={2} className="text-center">
            {roundScores.scores[0].score ? Target - roundScores.scores[0].score : null}          
          </Col>
          <Col sm={2} className="text-center ">
            {roundScores.scores[1].score ? roundScores.scores[0].score! - roundScores.scores[1].score : null}
          </Col>
          <Col sm={2} className="text-center ">
            {roundScores.scores[2].score ? roundScores.scores[1].score! - roundScores.scores[2].score : null}
          </Col>
          <Col sm={2} className="text-center ">
            {roundScores.scores[3].score ? roundScores.scores[2].score! - roundScores.scores[3].score : null}
          </Col>
        </Row>
        <Row>
        <h5 className="text-muted text-center my-4">Enter the remaining score or the scored value as as negative value 
        <br/>Enter *1, *2 or *3 to close in 1, 2 or 3 darts
        <br/>To undo the last entered score, press '/'
        </h5>

        </Row>
        <Row className="mb-4">
            <Col sm={2}></Col>
            <Col sm={8} className="text-info border border-info rounded p-3 text-center display-3">
                {checkoutText}
            </Col>
        </Row>
      </React.Fragment>
    );
}

export default ActiveRound;
