import React, { useReducer} from "react";
// import * as icons from 'react-bootstrap-icons';
import { BermudaCOntext } from "../state/context";
import { bermudaGameReduce } from "../state/reducer";
import { initialBermudaGameState } from "../state/state";
import { Col, Container, Row } from "react-bootstrap";
import { Status } from "../../../common/Status";
import GameSettings from "./GameSettings";
import BermudaGame from "./BermudaGame";

export interface IBermudaProps {

}

const Bermuda:React.FC = () => {

    const [state, dispatch] = useReducer(bermudaGameReduce, initialBermudaGameState);

    return (
        <BermudaCOntext.Provider value={{ state, dispatch}}>
            <Container fluid>
                <Row className="mt-0">
                    <Col xs={12}>
                    {state.GameStatus !== (Status.NotStarted ) ?
                        <BermudaGame />
                    : 
                        <GameSettings />
                    }
                    </Col>
                </Row>
            </Container>
        </BermudaCOntext.Provider>
    );
}

export default Bermuda;