import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BermudaCOntext } from "../state/context";
import { startNextRound, resetGame, startNewGame } from "../state/reducer";
import { NewGameButton, NextGameButton, SaveGameButton } from "../../../common/GameButtons";
import { Status } from "../../../common/Status";
import PlayerScores from "./PlayerScores";
import RoundStatus from "./RoundStatus";
import { GameType } from "../../../common/GameTypes";


const BermudaGame = () => {

  const { state, dispatch } = React.useContext(BermudaCOntext);
  const [showLastRound, setShowLastRound] = useState<boolean>(true);
  const [gameSaved, setGameSaved] = useState<boolean>(false);

  const handleNextRoundClick = () => {
    dispatch(startNextRound());
  };

  const handleNewGame = () => {
    dispatch(startNewGame());
  }

  const handleResetGame = () => {
    dispatch(resetGame());
  }

  const handleshowHideLastRound = () => {
    setShowLastRound(!showLastRound);
  }


  const handleGameSaved = () => {
    setGameSaved(true);
  }

  const getFieldText = (fieldId: number) => {

    const playerScores = state.activePlayerId === 1 ? { ...state.player1!.scores } : { ...state.player2!.scores }
    return playerScores[fieldId - 1].fieldHint;

  }

  const NextRound = () => {
    return (
      <Row>
        <Col xs={12} className="text-center">
          <Button
            variant="success"
            className="p-3 mt-5"
            onClick={handleNextRoundClick}
            autoFocus
          >
            <h1>Start next round</h1>
          </Button>
        </Col>
      </Row>
    )
  }

  const GameOver = () => {

    return (
      <Row className="mt-4">
        <Col>
          <NewGameButton OnClick={handleResetGame} />
        </Col>
        <Col>
          {!gameSaved ? <SaveGameButton onGameSaved={handleGameSaved} game={state} gameType={GameType.Bermuda} /> : null }
        </Col>
        <Col className="text-end">
          <NextGameButton OnClick={handleNewGame} />
        </Col>
      </Row>
    )
  }

  const ActiveGame = () => {

    return (
      <React.Fragment>
        <Row>
          <h3 className="text-info text-center">
            Leg {state.activeRound} of {state.rounds} legs
          </h3>
        </Row>
        <Row>
          <Col>
            <PlayerScores playerid={1} active={state.activePlayerId === 1} />
          </Col>
          <Col>
            <PlayerScores playerid={2} active={state.activePlayerId === 2} />
          </Col>
        </Row>
        <Row className="text-success text-center">
          {state.GameStatus === Status.InProgress ? <h1>{getFieldText(state.activeField)} </h1> : null}
          {state.GameStatus === Status.InProgress && (state.activeField > 1 || (state.activeField === 1 && state.activePlayerId === 2)) ? <span className="text-muted h5">Press '/' to undo last score</span> : null}
        </Row>

      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {state.GameStatus === Status.FInished ?
        <Row className="text-center">
          <Button variant="success" className="" onClick={handleshowHideLastRound} >{showLastRound ? "Show Round result " : "Show Round details"}</Button>
        </Row> :
        null
      }

      {state.GameStatus === Status.FInished ?
        state.activeRound < state.rounds ?
          <React.Fragment>
            {showLastRound ? <ActiveGame /> : <RoundStatus currentRound={state.activeRound} />}
            <NextRound />
          </React.Fragment>
          :
          <React.Fragment>
            {showLastRound ? <ActiveGame /> : <RoundStatus currentRound={state.activeRound} />}
            <GameOver />
          </React.Fragment>
        :
        <ActiveGame />
      }
    </React.Fragment>
  );
};
  
  export default BermudaGame;