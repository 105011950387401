import { IPlayer } from "../model/Player";

export enum ActionType {
    SetPlayer,
    StartGame,
    SetGameRounds,
    SetGameSettings,
    SwitchPlayer,
    AddPlayerScore,
    NextRound,
    NextGame,
    NewGame,
    RemoveLastScore
}

export interface SetPlayer {
    type:ActionType.SetPlayer,
    payload: IPlayer
}

export interface AddGameScore {
    type:ActionType.AddPlayerScore,
    payload: { playerId:number, score:number | "*1" | "*2" |"*3" }
}

export interface SetGameRounds {
    type:ActionType.SetGameRounds,
    payload: number
}

export interface SetGameSettings {
    type:ActionType.SetGameSettings,
    payload:{ score : 170|301|501|701|1001, rounds:number, tiebreak:number, tiebreakAllROunds:boolean, roundsType:number, startPlayerId:number, showSuggestions:boolean }
}

export interface NewRound {
    type:ActionType.NextRound,
    payload:IPlayer
}

export interface StartGame {
    type:ActionType.StartGame
}

export interface SwitchPlayers {
    type: ActionType.SwitchPlayer
}

export interface NewGame {
    type: ActionType.NewGame
}

export interface NextGame {
    type: ActionType.NextGame
}

export interface RemoveLastScore {
    type:ActionType.RemoveLastScore,
}

export type Five0OneGameActions = SetPlayer | AddGameScore | SetGameSettings | NewRound | StartGame | SwitchPlayers | NextGame |NewGame | RemoveLastScore;