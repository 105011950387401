import { IPlayer } from "../model/Player";
import { IHit, ITarget } from "../model/Target";
import { ActionType, HitPlayerTarget, MickeyMouseGameActions, RemoveTargetHit, SetPlayer1, SetPlayer2 , SetGameSettings, ResetGame, NewGame, SwitchPlayer, StartGame } from "./actions";
import { IMickeyMouseGameState, initialMickeyMouseGameState } from "./state";
import { Status } from '../../../common/Status';

export function mickeyMousGameReducer(state: IMickeyMouseGameState, action: MickeyMouseGameActions): IMickeyMouseGameState {


    switch (action.type) {

        case ActionType.SwitchPlayer:
          return {...state,
            ActivePlayer: action.payload
          }

        case ActionType.NewGame:
            state = {...initialMickeyMouseGameState};
            state.player1 = null;
            state.player2 = null;
            return {...state}

        case ActionType.ResetGame:
            state.CurrentRound++;

            return {...state}

        case ActionType.SetPlayer1:
            state.player1 = action.payload;
            state.player1.targets = initPlayerTargets(state.player1);
            return {...state}
            
        case ActionType.SetPlayer2:
            state.player2 = action.payload;
            state.player2.targets = initPlayerTargets(state.player2);
            return {...state};

        case ActionType.HitPlayerTarget:

            const hitTargetState = {...state}

            const newTargets = (action.payload.playerid === 1
                ? [...state.player1!.targets]
                : [...state.player2!.targets]);
              
              const hitTarget = newTargets.find(tg => tg.id === action.payload.targetid);
                      
              if (hitTarget) {
                const newTarget = {
                  ...hitTarget,
                  count: hitTarget.count - 1,
                };
                      
                newTargets.splice(newTargets.indexOf(hitTarget), 1);
                newTargets.push(newTarget);
              }
              /// check if there is a round winner....
              if (newTargets.filter(tg=> {return tg.count > 0}).length === 0) {
                // update the current round counter
                hitTargetState.CurrentRound++;

                // reset Players targetlists
                hitTargetState.player1!.targets = initPlayerTargets(hitTargetState.player1!);
                hitTargetState.player2!.targets = initPlayerTargets(hitTargetState.player2!);

                // Check for the round winner
                hitTargetState.LastRoundWinner = action.payload.playerid === 1 ? state.player1 : state.player2;

                // increase wonsets
                if (action.payload.playerid === 1 ) 
                  hitTargetState.player1!.wonSets++ 
                  else  hitTargetState.player2!.wonSets++ ;
                
                // check if player 1 has won at least half of the total rounds rounds
                if (hitTargetState.player1!.wonSets > hitTargetState.TotalRounds / 2) {
                  hitTargetState.GameStatus = Status.FInished;
                  hitTargetState.winner = hitTargetState.player1!
                }                    
                // check if player 2 has won at least half of the total rounds rounds
                if (hitTargetState.player2!.wonSets > hitTargetState.TotalRounds / 2) {
                    hitTargetState.GameStatus = Status.FInished;
                    hitTargetState.winner = hitTargetState.player2!
                  }
                // return the updated shallow copy state
                  return hitTargetState;
              }

              const newState: IMickeyMouseGameState = { ...state }; // shallow copy
              
              if (action.payload.playerid === 1) {
                newState.player1 = {
                  ...newState.player1!, // shallow copy
                  targets: newTargets,
                };
              }
              
              if (action.payload.playerid === 2) {
                newState.player2 = {
                  ...newState.player2!, // shallow copy
                  targets: newTargets,
                };
              }
              
              return newState;

        case ActionType.RemovePlayerHit:

            const newRemoveHitTargets = (action.payload.playerid === 1
                ? [...state.player1!.targets]
                : [...state.player2!.targets]);
              
              const removeHitTarget = newRemoveHitTargets.find(tg => tg.id === action.payload.targetid);
                      
              if (removeHitTarget) {
                const newTarget = {
                  ...removeHitTarget,
                  count: removeHitTarget.count + 1,
                };
                      
                newRemoveHitTargets.splice(newRemoveHitTargets.indexOf(removeHitTarget), 1);
                newRemoveHitTargets.push(newTarget);
              }
              
              const newRemoveHitState: IMickeyMouseGameState = { ...state }; // shallow copy
              
              if (action.payload.playerid === 1) {
                newRemoveHitState.player1 = {
                  ...newRemoveHitState.player1!, // shallow copy
                  targets: newRemoveHitTargets,
                };
              }
              
              if (action.payload.playerid === 2) {
                newRemoveHitState.player2 = {
                  ...newRemoveHitState.player2!, // shallow copy
                  targets: newRemoveHitTargets,
                };
              }
              
              return newRemoveHitState;

        case ActionType.SetGameSettings:
            return { 
                ...state,
                TotalRounds: action.payload,
            }
        case ActionType.StartGame:
          return {
            ...state,
            TotalRounds:action.payload,
            GameStatus:Status.InProgress
          }
        default:
            return state;
    }
}

// internal Helper functions

const initPlayerTargets = (player: IPlayer): ITarget[] => {

    const { hitRequirement } = player;
    const targets:ITarget[] = [];

    for (let targetCount = 0; targetCount < 8; targetCount++) {
        targets.push({
            count: hitRequirement,
            id: targetCount,
            value: (20 - targetCount).toString()
        });
    };

    targets.push({
        count : hitRequirement,
        id : 10,
        value: "D"
    })

    targets.push({
        count :hitRequirement,
        id :11,
        value:"T"
    });

    targets.push({
        count :hitRequirement,
        id :12,
        value:"B"
    });
    
    return targets;
}

//Public helper functions
export const setPlayer1 = (player: IPlayer): SetPlayer1 => ({
    type: ActionType.SetPlayer1,
    payload: player
});

export const setPlayer2 = (player: IPlayer): SetPlayer2 => ({
    type: ActionType.SetPlayer2,
    payload: player
});

export const targetHit = (hit: IHit): HitPlayerTarget => ({
    type: ActionType.HitPlayerTarget,
    payload: hit
});

export const removeHit = (hit: IHit): RemoveTargetHit => ({
    type: ActionType.RemovePlayerHit,
    payload: hit
});

export const setGameSettings = (noOfRounds:number) : SetGameSettings => ({
    type:ActionType.SetGameSettings,
    payload:noOfRounds
});

export const resetGame = (winner:IPlayer) : ResetGame => ({
    type: ActionType.ResetGame,
    payload:{roundWinner: winner}
});

export const newGame = (noOfRounds:number) : NewGame => ({
    type: ActionType.NewGame,
    payload:noOfRounds
});

export const switchPlayer = (playerid:number) : SwitchPlayer => ({
  type: ActionType.SwitchPlayer,
  payload:playerid
});

export const startGame = (noOfRounds:number) :StartGame => ({
  type:ActionType.StartGame,
  payload:noOfRounds
});