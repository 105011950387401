import React from "react";
import { Five0OneGameActions } from "./actions";
import { IFive0OneGameState, initialFive0OneGameState } from "./state";

export const Five0OneContext = React.createContext<{
    state: IFive0OneGameState,
    dispatch: React.Dispatch<Five0OneGameActions>
}>({
    state:initialFive0OneGameState,
    dispatch: () => undefined
});